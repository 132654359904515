import { Component, OnInit } from '@angular/core';
import { ISmartBooking } from '../../interfaces/ISmartBooking.interface';
import { data, SEGMENT_ID, SLIDER_ID_LEFT, SLIDER_ID_RIGHT } from './smart-booking-data';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import * as _ from 'lodash';
import { BarContainerDirective } from '../../directives/bar-container.directive';
import { distinctUntilChanged, tap, concatMap, delayWhen, filter, distinct } from 'rxjs/operators';
import { SmartBookingService } from '../../services/smart-booking.service';
import { delay, nextTick } from 'q';
import { of, timer } from 'rxjs';
import { IBarsPosition } from '../../interfaces/IBarsPosition.interface';
import { Segment } from '../../enums/segment.enum';
@Component({
  selector: 'app-smart-booking',
  templateUrl: './smart-booking.component.html',
  styleUrls: ['./smart-booking.component.scss']
})
export class SmartBookingComponent implements OnInit {

  public data: ISmartBooking[] = data;
  public sliderIdLeft: string = SLIDER_ID_LEFT;
  public sliderIdRight: string = SLIDER_ID_RIGHT;

  public segmentId: string = SEGMENT_ID;
  private readonly minBarWidth = 10;
  public enabled = [];

  constructor(private readonly smartBookingService: SmartBookingService) { }

  ngOnInit() {
    

    this.smartBookingService.position.pipe(
      distinctUntilChanged((prev: IBarsPosition, curr: IBarsPosition) => prev.width === curr.width && prev.direction === curr.direction),
      tap((data: IBarsPosition) => {
        //console.log(data);
        const row = this.data.find((s) => s.slot === data.slot);
        if (row !== undefined) {
          //const segments = row.segment.filter(a => a.enabled);

         // row.segment = row.segment.filter(s => s.enabled);

          switch (data.direction) {
            case SLIDER_ID_RIGHT :
              if(data.width >= this.minBarWidth) {
                let segmentIndex = this.findSegment(row, data.segmentName);
                row.segment[segmentIndex].percent = data.width;
                const sum = _.sumBy(row.segment, (s) => (s.enabled) ? s.percent : 0)
                const next = row.segment.findIndex((s, i) => s.enabled && segmentIndex < i);
                row.segment[next].percent += 100 - sum;
              } 
            break;
            
            case SLIDER_ID_LEFT :
              if(row.segment[data.index].percent >= this.minBarWidth) {
                let segmentIndex = this.findSegment(row, data.segmentName);

                while (row.segment[segmentIndex - 1].enabled) {
                  const prev = segmentIndex - 1;
                  row.segment[prev].percent = data.width;
                  const sum = _.sumBy(row.segment, (s) => (s.enabled) ? s.percent : 0)
                  row.segment[segmentIndex].percent -= sum - 100;
                  break;
                }

              }
            break;
        }
      }
      })
    ).subscribe()
  }

  findSegment(data: ISmartBooking, name: Segment) {
    return data.segment.findIndex(s => s.name === name);
  }

  enableSegment(row: ISmartBooking, name: string) {
    const segment = row.segment.find(s => s.name === name);
    if (segment.enabled) {
      segment.enabled = false;
    } else {
      segment.percent = this.minBarWidth;
      segment.enabled = true;
    };
    const sum = _.sumBy(row.segment, (s) => (s.enabled) ? s.percent : 0);
    
    if (sum < 100){
      row.segment.find((s) => s.enabled).percent += 100 - sum;
    } else {
      row.segment.find((s) => s.enabled && s.percent > this.minBarWidth).percent -= this.minBarWidth;
    }
    return segment.enabled;
  }

  isNotEnabledSegments(row: ISmartBooking) {
    return row.segment.find(s => s.enabled) === undefined;
  }

  isActiveSegment(row: ISmartBooking, name: string) {
    return !row.segment.find(s => s.name === name).enabled;
  }
}
