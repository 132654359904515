import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AddressComponent } from './address.component';
import { AddressListComponent } from './list/list.component';

const addressRoutes: Routes = [
    {
        path: 'address',
        component: AddressComponent,
        children: [
          { path: '', component: AddressListComponent, outlet: 'content' },
          { path: 'list', component: AddressListComponent, outlet: 'content' },
        ]
      },
];

@NgModule({
    imports: [RouterModule.forChild(addressRoutes)],
    exports: [RouterModule]
})

export class AddressRouting {}


