import { NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { DropdownDirective } from '../directives/dropdown.directive';
import { DropdownMenuDirective } from '../directives/dropdown-menu.directive';
import { DropdownToggleDirective } from '../directives/dropdown-toggle.directive';
import { TranslateLoaderService } from '../services/trans-loader.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './../assets/i18n/', '.json');
}

const directives = [
    DropdownDirective,
    DropdownToggleDirective,
    DropdownMenuDirective,
];

@NgModule({
    declarations: [
        ...directives
    ],
    imports: [
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useClass: TranslateLoaderService,
              deps: [HttpClient]
            }
        })
    ],
    exports: [
        HttpClientModule, TranslateModule, ...directives, BrowserAnimationsModule
    ]
})
export class TranslationModule {}
