import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpErrorResponse, HttpResponse
} from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { AppService } from './app.service';

import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/concat';
import 'rxjs/add/operator/retry';

import Configuration from '../entities/configuration';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private TAG = '[AuthInterceptor]';
    private version: string;
    private configuration: Configuration;

    constructor(private appService: AppService) {
        this.setVersion();
    }

    setVersion() {
        this.appService.configurationData.subscribe(configuration => this.configuration = configuration);
    }

    processRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // todo: retring re quest for specyfic status code (unavaialable) .retry(5)

        return next.handle(request)
            .catch((error: HttpErrorResponse) => {
                return Observable.throw(error);
            });

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request.clone({
            setHeaders: {
                'Content-Type': 'application/json;text/plain;charset=utf-8;',
                'Authorization': (this.configuration) ? `Bearer ${this.configuration.userToken}` : ''
            }
        }));
    }

}
