
import { Entity } from '../helpers/vo/entity';
import { Serializable } from '../helpers/vo/serializable';
import * as _ from 'lodash';

export default class Slot extends Entity implements Serializable<Slot> {

    cutOff: string;
    date: string;
    endTime: string;
    slot: string;
    slotId: string;
    startTime: string;

    deserialize(data: any): Slot {

        this.cutOff = _.get(data, 'cutOff', '');
        this.date = _.get(data, 'date', '');
        this.endTime = _.get(data, 'endTime', '');
        this.slot = _.get(data, 'slot', '');
        this.slotId = _.get(data, 'slotId', '');
        this.startTime = _.get(data, 'startTime', '');

        return this;
    }

    serialize() {
        const serialized = {
            cutOff: this.cutOff,
            date: this.date,
            endTime: this.endTime,
            slot: this.slot,
            slotId: this.slotId,
            startTime: this.startTime
        };

        return serialized;
    }

    get mainSlotStart() {
        return `${this.slot.split('-')[0]}:00`;
    }

    get mainSlotEnd() {
        return `${this.slot.split('-')[1]}:00`;
    }
}
