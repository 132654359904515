import * as _ from 'lodash';
import { Entity } from '../../../helpers/vo/entity';
import { Serializable } from '../../../helpers/vo/serializable';
import { ShiftType } from './interfaces';

export default class Step extends Entity implements Serializable<Step> {
    slotId:             string | null;
    date:               string | null;
    startTime:          string | null;
    endTime:            string | null;
    cutOff:             string | null;
    slot:               string | null;
    availability:       boolean | null;
    isLast:             boolean | null;
    shiftType:          ShiftType | null;
    index:              number | null;
    freeDelivery?:      boolean | null;

    public deserialize(data: any, index?: number, type?: ShiftType) {

        this.slotId = _.get(data, 'slotId', null);
        this.date = _.get(data, 'date', null);
        this.startTime = _.get(data, 'startTime', null);
        this.endTime = _.get(data, 'endTime', null);
        this.cutOff = _.get(data, 'cutOff', null);
        this.slot = _.get(data, 'slot', null);
        this.availability = _.get(data, 'availability');
        this.isLast = _.get(data, 'isLast', null);
        this.index = index % 16;
        this.freeDelivery = _.get(data, 'freeDelivery', null);
        this.shiftType = type || null;

        return this;
    }

    public serialize() {
        const serialized = {};
        return serialized;
    }
}
