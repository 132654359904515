import {Directive,EventEmitter,HostListener,ElementRef} from '@angular/core';
@Directive({
  selector: '[dropdown-toggle], .dropdown-toggle'
})
export class DropdownToggleDirective {

  public onToggle = new EventEmitter();

  constructor(private el: ElementRef) {}

  @HostListener('document:click', ['$event'])
  toggle(event: Event) {
    if (this.el.nativeElement.contains(event.target)) {
      this.onToggle.emit();
    }
  }
}