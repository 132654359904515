import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DemoBookingComponent } from './components/demo-booking/demo-booking.component';
import { DemoBookingAlwaysOnComponent } from './components/demo-booking-always-on/demo-booking-always-on.component';
import { DemoBokingRouting } from './demo-booking.routing';
import { SmartBookingComponent } from './components/smart-booking/smart-booking.component';
import { BarContainerDirective } from './directives/bar-container.directive';
import { SegmentDirective } from './directives/segment.directive';
import { SmartBookingBarComponent } from './components/smart-booking-bar/smart-booking-bar.component';
import { SmartBookingSegmentComponent } from './components/smart-booking-segment/smart-booking-segment.component';
import { LoyaltyComponent } from './components/loyalty/loyalty.component';
import { ColorPipe } from './components/loyalty/color.pipe';
import { FormsModule } from '@angular/forms';
import { WidthLabelPipe } from './pipes/width-label.pipe';
import { IsNotEnabledPipe } from './pipes/is-not-enabled.pipe';
import { CashWithdrawalComponent } from './components/cash-withdrawal/cash-withdrawal.component';
import { QRCodeModule } from 'angularx-qrcode';
import { UUID } from 'angular2-uuid';


@NgModule({
  declarations: [
    DemoBookingComponent,
    DemoBookingAlwaysOnComponent,
    SmartBookingComponent,
    BarContainerDirective,
    SegmentDirective,
    SmartBookingBarComponent,
    SmartBookingSegmentComponent,
    LoyaltyComponent,
    ColorPipe,
    WidthLabelPipe,
    IsNotEnabledPipe,
    CashWithdrawalComponent],
  imports: [
    CommonModule,
    DemoBokingRouting,
    FormsModule,
    QRCodeModule,
    //UUID
  ]
})
export class DemoBookingModule { }
