import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Configuration from '../../../entities/configuration';
import { map } from 'rxjs/operators';

@Injectable()
export class PickerService {

    static GET_AVAILABLE_SLOTS = 'slot/v1/available/${date}/CASH';

    get host() { return environment.host; }
    get prefix() { return environment.prefix; }
    get apiVersion() { return environment.apiUrl; }

    public configuration: Configuration;

    constructor(private http: HttpClient) { }

    public getAvailableStols(configuration: Configuration, selectedDate: string): Observable<any> {

        const endpoint = this.interpolate(`${this.host}${this.prefix}/${PickerService.GET_AVAILABLE_SLOTS}`,
            { date: selectedDate, });

        this.configuration = configuration;
        const body = JSON.stringify(configuration.selectedLocation);

        return this.http.post(endpoint, body)
        .pipe(
            map((response: any) => {
                return response;
            }));
    }


    public interpolate(template: string, params: {}) {
        const names = Object.keys(params);
        const vals = Object.keys(params).map(key => params[key]);
        return new Function(...names, `return \`${template}\`;`)(...vals);
    }

    public httpHeaders(): Object {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${this.configuration.userToken}`
            })
        };
        return httpOptions;
    }

}
