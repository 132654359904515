export const waveAnimations = {
     "v":"5.4.4",
     "fr":29.9700012207031,
     "ip":0,
     "op":44.0000017921567,
     "w": 500,
     "h":106,
     "nm":"bodymovin lines",
     "ddd":0,
     "assets":[
        {
           "id":"comp_0",
           "layers":[
              {
                 "ddd":0,
                 "ind":1,
                 "ty":4,
                 "nm":"Shape Layer 30",
                 "sr":1,
                 "ks":{
                    "o":{
                       "a":1,
                       "k":[
                          {
                             "i":{
                                "x":[
                                   0.833
                                ],
                                "y":[
                                   0.833
                                ]
                             },
                             "o":{
                                "x":[
                                   0.167
                                ],
                                "y":[
                                   0.167
                                ]
                             },
                             "t":0,
                             "s":[
                                90
                             ],
                             "e":[
                                80
                             ]
                          },
                          {
                             "t":43.0000017514259
                          }
                       ],
                       "ix":11
                    },
                    "r":{
                       "a":0,
                       "k":0,
                       "ix":10
                    },
                    "p":{
                       "a":0,
                       "k":[
                          202.523,
                          202.984,
                          0
                       ],
                       "ix":2
                    },
                    "a":{
                       "a":0,
                       "k":[
                          0,
                          0,
                          0
                       ],
                       "ix":1
                    },
                    "s":{
                       "a":0,
                       "k":[
                          121.551,
                          99.981,
                          100
                       ],
                       "ix":6
                    }
                 },
                 "ao":0,
                 "shapes":[
                    {
                       "ty":"gr",
                       "it":[
                          {
                             "ty":"rc",
                             "d":1,
                             "s":{
                                "a":0,
                                "k":[
                                   117.813,
                                   4
                                ],
                                "ix":2
                             },
                             "p":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "nm":"Rectangle Path 1",
                             "mn":"ADBE Vector Shape - Rect",
                             "hd":false
                          },
                          {
                             "ty":"fl",
                             "c":{
                                "a":0,
                                "k":[
                                   0.137254901961,
                                   0.588235294118,
                                   0.172549019608,
                                   1
                                ],
                                "ix":4
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":5
                             },
                             "r":1,
                             "bm":0,
                             "nm":"Fill 1",
                             "mn":"ADBE Vector Graphic - Fill",
                             "hd":false
                          },
                          {
                             "ty":"tr",
                             "p":{
                                "a":0,
                                "k":[
                                   -1.531,
                                   -81
                                ],
                                "ix":2
                             },
                             "a":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":1
                             },
                             "s":{
                                "a":0,
                                "k":[
                                   145.508,
                                   100
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":6
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":7
                             },
                             "sk":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "sa":{
                                "a":0,
                                "k":0,
                                "ix":5
                             },
                             "nm":"Transform"
                          }
                       ],
                       "nm":"Rectangle 1",
                       "np":3,
                       "cix":2,
                       "bm":0,
                       "ix":1,
                       "mn":"ADBE Vector Group",
                       "hd":false
                    }
                 ],
                 "ip":0,
                 "op":44.0000017921567,
                 "st":0,
                 "bm":0
              },
              {
                 "ddd":0,
                 "ind":2,
                 "ty":4,
                 "nm":"Shape Layer 22",
                 "sr":1,
                 "ks":{
                    "o":{
                       "a":1,
                       "k":[
                          {
                             "i":{
                                "x":[
                                   0.833
                                ],
                                "y":[
                                   0.833
                                ]
                             },
                             "o":{
                                "x":[
                                   0.167
                                ],
                                "y":[
                                   0.167
                                ]
                             },
                             "t":0,
                             "s":[
                                80
                             ],
                             "e":[
                                75
                             ]
                          },
                          {
                             "t":43.0000017514259
                          }
                       ],
                       "ix":11
                    },
                    "r":{
                       "a":0,
                       "k":0,
                       "ix":10
                    },
                    "p":{
                       "a":0,
                       "k":[
                          202.523,
                          196.984,
                          0
                       ],
                       "ix":2
                    },
                    "a":{
                       "a":0,
                       "k":[
                          0,
                          0,
                          0
                       ],
                       "ix":1
                    },
                    "s":{
                       "a":0,
                       "k":[
                          121.551,
                          99.981,
                          100
                       ],
                       "ix":6
                    }
                 },
                 "ao":0,
                 "shapes":[
                    {
                       "ty":"gr",
                       "it":[
                          {
                             "ty":"rc",
                             "d":1,
                             "s":{
                                "a":0,
                                "k":[
                                   117.813,
                                   4
                                ],
                                "ix":2
                             },
                             "p":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "nm":"Rectangle Path 1",
                             "mn":"ADBE Vector Shape - Rect",
                             "hd":false
                          },
                          {
                             "ty":"fl",
                             "c":{
                                "a":0,
                                "k":[
                                   0.137254901961,
                                   0.588235294118,
                                   0.172549019608,
                                   1
                                ],
                                "ix":4
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":5
                             },
                             "r":1,
                             "bm":0,
                             "nm":"Fill 1",
                             "mn":"ADBE Vector Graphic - Fill",
                             "hd":false
                          },
                          {
                             "ty":"tr",
                             "p":{
                                "a":0,
                                "k":[
                                   -1.531,
                                   -81
                                ],
                                "ix":2
                             },
                             "a":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":1
                             },
                             "s":{
                                "a":0,
                                "k":[
                                   145.508,
                                   100
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":6
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":7
                             },
                             "sk":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "sa":{
                                "a":0,
                                "k":0,
                                "ix":5
                             },
                             "nm":"Transform"
                          }
                       ],
                       "nm":"Rectangle 1",
                       "np":3,
                       "cix":2,
                       "bm":0,
                       "ix":1,
                       "mn":"ADBE Vector Group",
                       "hd":false
                    }
                 ],
                 "ip":0,
                 "op":44.0000017921567,
                 "st":0,
                 "bm":0
              },
              {
                 "ddd":0,
                 "ind":3,
                 "ty":4,
                 "nm":"Shape Layer 26",
                 "sr":1,
                 "ks":{
                    "o":{
                       "a":1,
                       "k":[
                          {
                             "i":{
                                "x":[
                                   0.833
                                ],
                                "y":[
                                   0.833
                                ]
                             },
                             "o":{
                                "x":[
                                   0.167
                                ],
                                "y":[
                                   0.167
                                ]
                             },
                             "t":0,
                             "s":[
                                75
                             ],
                             "e":[
                                70
                             ]
                          },
                          {
                             "t":43.0000017514259
                          }
                       ],
                       "ix":11
                    },
                    "r":{
                       "a":0,
                       "k":0,
                       "ix":10
                    },
                    "p":{
                       "a":0,
                       "k":[
                          202.523,
                          190.984,
                          0
                       ],
                       "ix":2
                    },
                    "a":{
                       "a":0,
                       "k":[
                          0,
                          0,
                          0
                       ],
                       "ix":1
                    },
                    "s":{
                       "a":0,
                       "k":[
                          121.551,
                          99.981,
                          100
                       ],
                       "ix":6
                    }
                 },
                 "ao":0,
                 "shapes":[
                    {
                       "ty":"gr",
                       "it":[
                          {
                             "ty":"rc",
                             "d":1,
                             "s":{
                                "a":0,
                                "k":[
                                   117.813,
                                   4
                                ],
                                "ix":2
                             },
                             "p":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "nm":"Rectangle Path 1",
                             "mn":"ADBE Vector Shape - Rect",
                             "hd":false
                          },
                          {
                             "ty":"fl",
                             "c":{
                                "a":0,
                                "k":[
                                   0.137254901961,
                                   0.588235294118,
                                   0.172549019608,
                                   1
                                ],
                                "ix":4
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":5
                             },
                             "r":1,
                             "bm":0,
                             "nm":"Fill 1",
                             "mn":"ADBE Vector Graphic - Fill",
                             "hd":false
                          },
                          {
                             "ty":"tr",
                             "p":{
                                "a":0,
                                "k":[
                                   -1.531,
                                   -81
                                ],
                                "ix":2
                             },
                             "a":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":1
                             },
                             "s":{
                                "a":0,
                                "k":[
                                   145.508,
                                   100
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":6
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":7
                             },
                             "sk":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "sa":{
                                "a":0,
                                "k":0,
                                "ix":5
                             },
                             "nm":"Transform"
                          }
                       ],
                       "nm":"Rectangle 1",
                       "np":3,
                       "cix":2,
                       "bm":0,
                       "ix":1,
                       "mn":"ADBE Vector Group",
                       "hd":false
                    }
                 ],
                 "ip":0,
                 "op":44.0000017921567,
                 "st":0,
                 "bm":0
              },
              {
                 "ddd":0,
                 "ind":4,
                 "ty":4,
                 "nm":"Shape Layer 18",
                 "sr":1,
                 "ks":{
                    "o":{
                       "a":1,
                       "k":[
                          {
                             "i":{
                                "x":[
                                   0.833
                                ],
                                "y":[
                                   0.833
                                ]
                             },
                             "o":{
                                "x":[
                                   0.167
                                ],
                                "y":[
                                   0.167
                                ]
                             },
                             "t":0,
                             "s":[
                                70
                             ],
                             "e":[
                                65
                             ]
                          },
                          {
                             "t":43.0000017514259
                          }
                       ],
                       "ix":11
                    },
                    "r":{
                       "a":0,
                       "k":0,
                       "ix":10
                    },
                    "p":{
                       "a":0,
                       "k":[
                          202.523,
                          184.984,
                          0
                       ],
                       "ix":2
                    },
                    "a":{
                       "a":0,
                       "k":[
                          0,
                          0,
                          0
                       ],
                       "ix":1
                    },
                    "s":{
                       "a":0,
                       "k":[
                          121.551,
                          99.981,
                          100
                       ],
                       "ix":6
                    }
                 },
                 "ao":0,
                 "shapes":[
                    {
                       "ty":"gr",
                       "it":[
                          {
                             "ty":"rc",
                             "d":1,
                             "s":{
                                "a":0,
                                "k":[
                                   117.813,
                                   4
                                ],
                                "ix":2
                             },
                             "p":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "nm":"Rectangle Path 1",
                             "mn":"ADBE Vector Shape - Rect",
                             "hd":false
                          },
                          {
                             "ty":"fl",
                             "c":{
                                "a":0,
                                "k":[
                                   0.137254901961,
                                   0.588235294118,
                                   0.172549019608,
                                   1
                                ],
                                "ix":4
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":5
                             },
                             "r":1,
                             "bm":0,
                             "nm":"Fill 1",
                             "mn":"ADBE Vector Graphic - Fill",
                             "hd":false
                          },
                          {
                             "ty":"tr",
                             "p":{
                                "a":0,
                                "k":[
                                   -1.531,
                                   -81
                                ],
                                "ix":2
                             },
                             "a":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":1
                             },
                             "s":{
                                "a":0,
                                "k":[
                                   145.508,
                                   100
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":6
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":7
                             },
                             "sk":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "sa":{
                                "a":0,
                                "k":0,
                                "ix":5
                             },
                             "nm":"Transform"
                          }
                       ],
                       "nm":"Rectangle 1",
                       "np":3,
                       "cix":2,
                       "bm":0,
                       "ix":1,
                       "mn":"ADBE Vector Group",
                       "hd":false
                    }
                 ],
                 "ip":0,
                 "op":44.0000017921567,
                 "st":0,
                 "bm":0
              },
              {
                 "ddd":0,
                 "ind":5,
                 "ty":4,
                 "nm":"Shape Layer 17",
                 "sr":1,
                 "ks":{
                    "o":{
                       "a":1,
                       "k":[
                          {
                             "i":{
                                "x":[
                                   0.833
                                ],
                                "y":[
                                   0.833
                                ]
                             },
                             "o":{
                                "x":[
                                   0.167
                                ],
                                "y":[
                                   0.167
                                ]
                             },
                             "t":0,
                             "s":[
                                65
                             ],
                             "e":[
                                60
                             ]
                          },
                          {
                             "t":43.0000017514259
                          }
                       ],
                       "ix":11
                    },
                    "r":{
                       "a":0,
                       "k":0,
                       "ix":10
                    },
                    "p":{
                       "a":0,
                       "k":[
                          202.523,
                          178.984,
                          0
                       ],
                       "ix":2
                    },
                    "a":{
                       "a":0,
                       "k":[
                          0,
                          0,
                          0
                       ],
                       "ix":1
                    },
                    "s":{
                       "a":0,
                       "k":[
                          121.551,
                          99.981,
                          100
                       ],
                       "ix":6
                    }
                 },
                 "ao":0,
                 "shapes":[
                    {
                       "ty":"gr",
                       "it":[
                          {
                             "ty":"rc",
                             "d":1,
                             "s":{
                                "a":0,
                                "k":[
                                   117.813,
                                   4
                                ],
                                "ix":2
                             },
                             "p":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "nm":"Rectangle Path 1",
                             "mn":"ADBE Vector Shape - Rect",
                             "hd":false
                          },
                          {
                             "ty":"fl",
                             "c":{
                                "a":0,
                                "k":[
                                   0.137254901961,
                                   0.588235294118,
                                   0.172549019608,
                                   1
                                ],
                                "ix":4
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":5
                             },
                             "r":1,
                             "bm":0,
                             "nm":"Fill 1",
                             "mn":"ADBE Vector Graphic - Fill",
                             "hd":false
                          },
                          {
                             "ty":"tr",
                             "p":{
                                "a":0,
                                "k":[
                                   -1.531,
                                   -81
                                ],
                                "ix":2
                             },
                             "a":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":1
                             },
                             "s":{
                                "a":0,
                                "k":[
                                   145.508,
                                   100
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":6
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":7
                             },
                             "sk":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "sa":{
                                "a":0,
                                "k":0,
                                "ix":5
                             },
                             "nm":"Transform"
                          }
                       ],
                       "nm":"Rectangle 1",
                       "np":3,
                       "cix":2,
                       "bm":0,
                       "ix":1,
                       "mn":"ADBE Vector Group",
                       "hd":false
                    }
                 ],
                 "ip":0,
                 "op":44.0000017921567,
                 "st":0,
                 "bm":0
              },
              {
                 "ddd":0,
                 "ind":6,
                 "ty":4,
                 "nm":"Shape Layer 13",
                 "sr":1,
                 "ks":{
                    "o":{
                       "a":1,
                       "k":[
                          {
                             "i":{
                                "x":[
                                   0.833
                                ],
                                "y":[
                                   0.833
                                ]
                             },
                             "o":{
                                "x":[
                                   0.167
                                ],
                                "y":[
                                   0.167
                                ]
                             },
                             "t":0,
                             "s":[
                                60
                             ],
                             "e":[
                                55
                             ]
                          },
                          {
                             "t":43.0000017514259
                          }
                       ],
                       "ix":11
                    },
                    "r":{
                       "a":0,
                       "k":0,
                       "ix":10
                    },
                    "p":{
                       "a":0,
                       "k":[
                          202.523,
                          172.984,
                          0
                       ],
                       "ix":2
                    },
                    "a":{
                       "a":0,
                       "k":[
                          0,
                          0,
                          0
                       ],
                       "ix":1
                    },
                    "s":{
                       "a":0,
                       "k":[
                          121.551,
                          99.981,
                          100
                       ],
                       "ix":6
                    }
                 },
                 "ao":0,
                 "shapes":[
                    {
                       "ty":"gr",
                       "it":[
                          {
                             "ty":"rc",
                             "d":1,
                             "s":{
                                "a":0,
                                "k":[
                                   117.813,
                                   4
                                ],
                                "ix":2
                             },
                             "p":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "nm":"Rectangle Path 1",
                             "mn":"ADBE Vector Shape - Rect",
                             "hd":false
                          },
                          {
                             "ty":"fl",
                             "c":{
                                "a":0,
                                "k":[
                                   0.137254901961,
                                   0.588235294118,
                                   0.172549019608,
                                   1
                                ],
                                "ix":4
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":5
                             },
                             "r":1,
                             "bm":0,
                             "nm":"Fill 1",
                             "mn":"ADBE Vector Graphic - Fill",
                             "hd":false
                          },
                          {
                             "ty":"tr",
                             "p":{
                                "a":0,
                                "k":[
                                   -1.531,
                                   -81
                                ],
                                "ix":2
                             },
                             "a":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":1
                             },
                             "s":{
                                "a":0,
                                "k":[
                                   145.508,
                                   100
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":6
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":7
                             },
                             "sk":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "sa":{
                                "a":0,
                                "k":0,
                                "ix":5
                             },
                             "nm":"Transform"
                          }
                       ],
                       "nm":"Rectangle 1",
                       "np":3,
                       "cix":2,
                       "bm":0,
                       "ix":1,
                       "mn":"ADBE Vector Group",
                       "hd":false
                    }
                 ],
                 "ip":0,
                 "op":44.0000017921567,
                 "st":0,
                 "bm":0
              },
              {
                 "ddd":0,
                 "ind":7,
                 "ty":4,
                 "nm":"Shape Layer 15",
                 "sr":1,
                 "ks":{
                    "o":{
                       "a":1,
                       "k":[
                          {
                             "i":{
                                "x":[
                                   0.833
                                ],
                                "y":[
                                   0.833
                                ]
                             },
                             "o":{
                                "x":[
                                   0.167
                                ],
                                "y":[
                                   0.167
                                ]
                             },
                             "t":0,
                             "s":[
                                55
                             ],
                             "e":[
                                50
                             ]
                          },
                          {
                             "t":43.0000017514259
                          }
                       ],
                       "ix":11
                    },
                    "r":{
                       "a":0,
                       "k":0,
                       "ix":10
                    },
                    "p":{
                       "a":0,
                       "k":[
                          202.523,
                          166.984,
                          0
                       ],
                       "ix":2
                    },
                    "a":{
                       "a":0,
                       "k":[
                          0,
                          0,
                          0
                       ],
                       "ix":1
                    },
                    "s":{
                       "a":0,
                       "k":[
                          121.551,
                          99.981,
                          100
                       ],
                       "ix":6
                    }
                 },
                 "ao":0,
                 "shapes":[
                    {
                       "ty":"gr",
                       "it":[
                          {
                             "ty":"rc",
                             "d":1,
                             "s":{
                                "a":0,
                                "k":[
                                   117.813,
                                   4
                                ],
                                "ix":2
                             },
                             "p":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "nm":"Rectangle Path 1",
                             "mn":"ADBE Vector Shape - Rect",
                             "hd":false
                          },
                          {
                             "ty":"fl",
                             "c":{
                                "a":0,
                                "k":[
                                   0.137254901961,
                                   0.588235294118,
                                   0.172549019608,
                                   1
                                ],
                                "ix":4
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":5
                             },
                             "r":1,
                             "bm":0,
                             "nm":"Fill 1",
                             "mn":"ADBE Vector Graphic - Fill",
                             "hd":false
                          },
                          {
                             "ty":"tr",
                             "p":{
                                "a":0,
                                "k":[
                                   -1.531,
                                   -81
                                ],
                                "ix":2
                             },
                             "a":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":1
                             },
                             "s":{
                                "a":0,
                                "k":[
                                   145.508,
                                   100
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":6
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":7
                             },
                             "sk":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "sa":{
                                "a":0,
                                "k":0,
                                "ix":5
                             },
                             "nm":"Transform"
                          }
                       ],
                       "nm":"Rectangle 1",
                       "np":3,
                       "cix":2,
                       "bm":0,
                       "ix":1,
                       "mn":"ADBE Vector Group",
                       "hd":false
                    }
                 ],
                 "ip":0,
                 "op":44.0000017921567,
                 "st":0,
                 "bm":0
              },
              {
                 "ddd":0,
                 "ind":8,
                 "ty":4,
                 "nm":"Shape Layer 11",
                 "sr":1,
                 "ks":{
                    "o":{
                       "a":1,
                       "k":[
                          {
                             "i":{
                                "x":[
                                   0.833
                                ],
                                "y":[
                                   0.833
                                ]
                             },
                             "o":{
                                "x":[
                                   0.167
                                ],
                                "y":[
                                   0.167
                                ]
                             },
                             "t":0,
                             "s":[
                                50
                             ],
                             "e":[
                                45
                             ]
                          },
                          {
                             "t":43.0000017514259
                          }
                       ],
                       "ix":11
                    },
                    "r":{
                       "a":0,
                       "k":0,
                       "ix":10
                    },
                    "p":{
                       "a":0,
                       "k":[
                          202.523,
                          160.984,
                          0
                       ],
                       "ix":2
                    },
                    "a":{
                       "a":0,
                       "k":[
                          0,
                          0,
                          0
                       ],
                       "ix":1
                    },
                    "s":{
                       "a":0,
                       "k":[
                          121.551,
                          99.981,
                          100
                       ],
                       "ix":6
                    }
                 },
                 "ao":0,
                 "shapes":[
                    {
                       "ty":"gr",
                       "it":[
                          {
                             "ty":"rc",
                             "d":1,
                             "s":{
                                "a":0,
                                "k":[
                                   117.813,
                                   4
                                ],
                                "ix":2
                             },
                             "p":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "nm":"Rectangle Path 1",
                             "mn":"ADBE Vector Shape - Rect",
                             "hd":false
                          },
                          {
                             "ty":"fl",
                             "c":{
                                "a":0,
                                "k":[
                                   0.137254901961,
                                   0.588235294118,
                                   0.172549019608,
                                   1
                                ],
                                "ix":4
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":5
                             },
                             "r":1,
                             "bm":0,
                             "nm":"Fill 1",
                             "mn":"ADBE Vector Graphic - Fill",
                             "hd":false
                          },
                          {
                             "ty":"tr",
                             "p":{
                                "a":0,
                                "k":[
                                   -1.531,
                                   -81
                                ],
                                "ix":2
                             },
                             "a":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":1
                             },
                             "s":{
                                "a":0,
                                "k":[
                                   145.508,
                                   100
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":6
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":7
                             },
                             "sk":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "sa":{
                                "a":0,
                                "k":0,
                                "ix":5
                             },
                             "nm":"Transform"
                          }
                       ],
                       "nm":"Rectangle 1",
                       "np":3,
                       "cix":2,
                       "bm":0,
                       "ix":1,
                       "mn":"ADBE Vector Group",
                       "hd":false
                    }
                 ],
                 "ip":0,
                 "op":44.0000017921567,
                 "st":0,
                 "bm":0
              },
              {
                 "ddd":0,
                 "ind":9,
                 "ty":4,
                 "nm":"Shape Layer 16",
                 "sr":1,
                 "ks":{
                    "o":{
                       "a":1,
                       "k":[
                          {
                             "i":{
                                "x":[
                                   0.833
                                ],
                                "y":[
                                   0.833
                                ]
                             },
                             "o":{
                                "x":[
                                   0.167
                                ],
                                "y":[
                                   0.167
                                ]
                             },
                             "t":0,
                             "s":[
                                45
                             ],
                             "e":[
                                40
                             ]
                          },
                          {
                             "t":43.0000017514259
                          }
                       ],
                       "ix":11
                    },
                    "r":{
                       "a":0,
                       "k":0,
                       "ix":10
                    },
                    "p":{
                       "a":0,
                       "k":[
                          202.523,
                          154.984,
                          0
                       ],
                       "ix":2
                    },
                    "a":{
                       "a":0,
                       "k":[
                          0,
                          0,
                          0
                       ],
                       "ix":1
                    },
                    "s":{
                       "a":0,
                       "k":[
                          121.551,
                          99.981,
                          100
                       ],
                       "ix":6
                    }
                 },
                 "ao":0,
                 "shapes":[
                    {
                       "ty":"gr",
                       "it":[
                          {
                             "ty":"rc",
                             "d":1,
                             "s":{
                                "a":0,
                                "k":[
                                   117.813,
                                   4
                                ],
                                "ix":2
                             },
                             "p":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "nm":"Rectangle Path 1",
                             "mn":"ADBE Vector Shape - Rect",
                             "hd":false
                          },
                          {
                             "ty":"fl",
                             "c":{
                                "a":0,
                                "k":[
                                   0.137254901961,
                                   0.588235294118,
                                   0.172549019608,
                                   1
                                ],
                                "ix":4
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":5
                             },
                             "r":1,
                             "bm":0,
                             "nm":"Fill 1",
                             "mn":"ADBE Vector Graphic - Fill",
                             "hd":false
                          },
                          {
                             "ty":"tr",
                             "p":{
                                "a":0,
                                "k":[
                                   -1.531,
                                   -81
                                ],
                                "ix":2
                             },
                             "a":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":1
                             },
                             "s":{
                                "a":0,
                                "k":[
                                   145.508,
                                   100
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":6
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":7
                             },
                             "sk":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "sa":{
                                "a":0,
                                "k":0,
                                "ix":5
                             },
                             "nm":"Transform"
                          }
                       ],
                       "nm":"Rectangle 1",
                       "np":3,
                       "cix":2,
                       "bm":0,
                       "ix":1,
                       "mn":"ADBE Vector Group",
                       "hd":false
                    }
                 ],
                 "ip":0,
                 "op":44.0000017921567,
                 "st":0,
                 "bm":0
              },
              {
                 "ddd":0,
                 "ind":10,
                 "ty":4,
                 "nm":"Shape Layer 12",
                 "sr":1,
                 "ks":{
                    "o":{
                       "a":1,
                       "k":[
                          {
                             "i":{
                                "x":[
                                   0.833
                                ],
                                "y":[
                                   0.833
                                ]
                             },
                             "o":{
                                "x":[
                                   0.167
                                ],
                                "y":[
                                   0.167
                                ]
                             },
                             "t":0,
                             "s":[
                                40
                             ],
                             "e":[
                                35
                             ]
                          },
                          {
                             "t":43.0000017514259
                          }
                       ],
                       "ix":11
                    },
                    "r":{
                       "a":0,
                       "k":0,
                       "ix":10
                    },
                    "p":{
                       "a":0,
                       "k":[
                          202.523,
                          148.984,
                          0
                       ],
                       "ix":2
                    },
                    "a":{
                       "a":0,
                       "k":[
                          0,
                          0,
                          0
                       ],
                       "ix":1
                    },
                    "s":{
                       "a":0,
                       "k":[
                          121.551,
                          99.981,
                          100
                       ],
                       "ix":6
                    }
                 },
                 "ao":0,
                 "shapes":[
                    {
                       "ty":"gr",
                       "it":[
                          {
                             "ty":"rc",
                             "d":1,
                             "s":{
                                "a":0,
                                "k":[
                                   117.813,
                                   4
                                ],
                                "ix":2
                             },
                             "p":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "nm":"Rectangle Path 1",
                             "mn":"ADBE Vector Shape - Rect",
                             "hd":false
                          },
                          {
                             "ty":"fl",
                             "c":{
                                "a":0,
                                "k":[
                                   0.137254901961,
                                   0.588235294118,
                                   0.172549019608,
                                   1
                                ],
                                "ix":4
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":5
                             },
                             "r":1,
                             "bm":0,
                             "nm":"Fill 1",
                             "mn":"ADBE Vector Graphic - Fill",
                             "hd":false
                          },
                          {
                             "ty":"tr",
                             "p":{
                                "a":0,
                                "k":[
                                   -1.531,
                                   -81
                                ],
                                "ix":2
                             },
                             "a":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":1
                             },
                             "s":{
                                "a":0,
                                "k":[
                                   145.508,
                                   100
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":6
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":7
                             },
                             "sk":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "sa":{
                                "a":0,
                                "k":0,
                                "ix":5
                             },
                             "nm":"Transform"
                          }
                       ],
                       "nm":"Rectangle 1",
                       "np":3,
                       "cix":2,
                       "bm":0,
                       "ix":1,
                       "mn":"ADBE Vector Group",
                       "hd":false
                    }
                 ],
                 "ip":0,
                 "op":44.0000017921567,
                 "st":0,
                 "bm":0
              },
              {
                 "ddd":0,
                 "ind":11,
                 "ty":4,
                 "nm":"Shape Layer 14",
                 "sr":1,
                 "ks":{
                    "o":{
                       "a":1,
                       "k":[
                          {
                             "i":{
                                "x":[
                                   0.833
                                ],
                                "y":[
                                   0.833
                                ]
                             },
                             "o":{
                                "x":[
                                   0.167
                                ],
                                "y":[
                                   0.167
                                ]
                             },
                             "t":0,
                             "s":[
                                35
                             ],
                             "e":[
                                30
                             ]
                          },
                          {
                             "t":43.0000017514259
                          }
                       ],
                       "ix":11
                    },
                    "r":{
                       "a":0,
                       "k":0,
                       "ix":10
                    },
                    "p":{
                       "a":0,
                       "k":[
                          202.523,
                          142.984,
                          0
                       ],
                       "ix":2
                    },
                    "a":{
                       "a":0,
                       "k":[
                          0,
                          0,
                          0
                       ],
                       "ix":1
                    },
                    "s":{
                       "a":0,
                       "k":[
                          121.551,
                          99.981,
                          100
                       ],
                       "ix":6
                    }
                 },
                 "ao":0,
                 "shapes":[
                    {
                       "ty":"gr",
                       "it":[
                          {
                             "ty":"rc",
                             "d":1,
                             "s":{
                                "a":0,
                                "k":[
                                   117.813,
                                   4
                                ],
                                "ix":2
                             },
                             "p":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "nm":"Rectangle Path 1",
                             "mn":"ADBE Vector Shape - Rect",
                             "hd":false
                          },
                          {
                             "ty":"fl",
                             "c":{
                                "a":0,
                                "k":[
                                   0.137254901961,
                                   0.588235294118,
                                   0.172549019608,
                                   1
                                ],
                                "ix":4
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":5
                             },
                             "r":1,
                             "bm":0,
                             "nm":"Fill 1",
                             "mn":"ADBE Vector Graphic - Fill",
                             "hd":false
                          },
                          {
                             "ty":"tr",
                             "p":{
                                "a":0,
                                "k":[
                                   -1.531,
                                   -81
                                ],
                                "ix":2
                             },
                             "a":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":1
                             },
                             "s":{
                                "a":0,
                                "k":[
                                   145.508,
                                   100
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":6
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":7
                             },
                             "sk":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "sa":{
                                "a":0,
                                "k":0,
                                "ix":5
                             },
                             "nm":"Transform"
                          }
                       ],
                       "nm":"Rectangle 1",
                       "np":3,
                       "cix":2,
                       "bm":0,
                       "ix":1,
                       "mn":"ADBE Vector Group",
                       "hd":false
                    }
                 ],
                 "ip":0,
                 "op":44.0000017921567,
                 "st":0,
                 "bm":0
              },
              {
                 "ddd":0,
                 "ind":12,
                 "ty":4,
                 "nm":"Shape Layer 10",
                 "sr":1,
                 "ks":{
                    "o":{
                       "a":1,
                       "k":[
                          {
                             "i":{
                                "x":[
                                   0.833
                                ],
                                "y":[
                                   0.833
                                ]
                             },
                             "o":{
                                "x":[
                                   0.167
                                ],
                                "y":[
                                   0.167
                                ]
                             },
                             "t":0,
                             "s":[
                                30
                             ],
                             "e":[
                                25
                             ]
                          },
                          {
                             "t":43.0000017514259
                          }
                       ],
                       "ix":11
                    },
                    "r":{
                       "a":0,
                       "k":0,
                       "ix":10
                    },
                    "p":{
                       "a":0,
                       "k":[
                          202.523,
                          136.984,
                          0
                       ],
                       "ix":2
                    },
                    "a":{
                       "a":0,
                       "k":[
                          0,
                          0,
                          0
                       ],
                       "ix":1
                    },
                    "s":{
                       "a":0,
                       "k":[
                          121.551,
                          99.981,
                          100
                       ],
                       "ix":6
                    }
                 },
                 "ao":0,
                 "shapes":[
                    {
                       "ty":"gr",
                       "it":[
                          {
                             "ty":"rc",
                             "d":1,
                             "s":{
                                "a":0,
                                "k":[
                                   117.813,
                                   4
                                ],
                                "ix":2
                             },
                             "p":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "nm":"Rectangle Path 1",
                             "mn":"ADBE Vector Shape - Rect",
                             "hd":false
                          },
                          {
                             "ty":"fl",
                             "c":{
                                "a":0,
                                "k":[
                                   0.137254901961,
                                   0.588235294118,
                                   0.172549019608,
                                   1
                                ],
                                "ix":4
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":5
                             },
                             "r":1,
                             "bm":0,
                             "nm":"Fill 1",
                             "mn":"ADBE Vector Graphic - Fill",
                             "hd":false
                          },
                          {
                             "ty":"tr",
                             "p":{
                                "a":0,
                                "k":[
                                   -1.531,
                                   -81
                                ],
                                "ix":2
                             },
                             "a":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":1
                             },
                             "s":{
                                "a":0,
                                "k":[
                                   145.508,
                                   100
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":6
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":7
                             },
                             "sk":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "sa":{
                                "a":0,
                                "k":0,
                                "ix":5
                             },
                             "nm":"Transform"
                          }
                       ],
                       "nm":"Rectangle 1",
                       "np":3,
                       "cix":2,
                       "bm":0,
                       "ix":1,
                       "mn":"ADBE Vector Group",
                       "hd":false
                    }
                 ],
                 "ip":0,
                 "op":44.0000017921567,
                 "st":0,
                 "bm":0
              },
              {
                 "ddd":0,
                 "ind":13,
                 "ty":4,
                 "nm":"Shape Layer 9",
                 "sr":1,
                 "ks":{
                    "o":{
                       "a":1,
                       "k":[
                          {
                             "i":{
                                "x":[
                                   0.833
                                ],
                                "y":[
                                   0.833
                                ]
                             },
                             "o":{
                                "x":[
                                   0.167
                                ],
                                "y":[
                                   0.167
                                ]
                             },
                             "t":0,
                             "s":[
                                25
                             ],
                             "e":[
                                20
                             ]
                          },
                          {
                             "t":43.0000017514259
                          }
                       ],
                       "ix":11
                    },
                    "r":{
                       "a":0,
                       "k":0,
                       "ix":10
                    },
                    "p":{
                       "a":0,
                       "k":[
                          202.523,
                          130.984,
                          0
                       ],
                       "ix":2
                    },
                    "a":{
                       "a":0,
                       "k":[
                          0,
                          0,
                          0
                       ],
                       "ix":1
                    },
                    "s":{
                       "a":0,
                       "k":[
                          121.551,
                          99.981,
                          100
                       ],
                       "ix":6
                    }
                 },
                 "ao":0,
                 "shapes":[
                    {
                       "ty":"gr",
                       "it":[
                          {
                             "ty":"rc",
                             "d":1,
                             "s":{
                                "a":0,
                                "k":[
                                   117.813,
                                   4
                                ],
                                "ix":2
                             },
                             "p":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "nm":"Rectangle Path 1",
                             "mn":"ADBE Vector Shape - Rect",
                             "hd":false
                          },
                          {
                             "ty":"fl",
                             "c":{
                                "a":0,
                                "k":[
                                   0.137254901961,
                                   0.588235294118,
                                   0.172549019608,
                                   1
                                ],
                                "ix":4
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":5
                             },
                             "r":1,
                             "bm":0,
                             "nm":"Fill 1",
                             "mn":"ADBE Vector Graphic - Fill",
                             "hd":false
                          },
                          {
                             "ty":"tr",
                             "p":{
                                "a":0,
                                "k":[
                                   -1.531,
                                   -81
                                ],
                                "ix":2
                             },
                             "a":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":1
                             },
                             "s":{
                                "a":0,
                                "k":[
                                   145.508,
                                   100
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":6
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":7
                             },
                             "sk":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "sa":{
                                "a":0,
                                "k":0,
                                "ix":5
                             },
                             "nm":"Transform"
                          }
                       ],
                       "nm":"Rectangle 1",
                       "np":3,
                       "cix":2,
                       "bm":0,
                       "ix":1,
                       "mn":"ADBE Vector Group",
                       "hd":false
                    }
                 ],
                 "ip":0,
                 "op":44.0000017921567,
                 "st":0,
                 "bm":0
              },
              {
                 "ddd":0,
                 "ind":14,
                 "ty":4,
                 "nm":"Shape Layer 7",
                 "sr":1,
                 "ks":{
                    "o":{
                       "a":1,
                       "k":[
                          {
                             "i":{
                                "x":[
                                   0.833
                                ],
                                "y":[
                                   0.833
                                ]
                             },
                             "o":{
                                "x":[
                                   0.167
                                ],
                                "y":[
                                   0.167
                                ]
                             },
                             "t":0,
                             "s":[
                                20
                             ],
                             "e":[
                                15
                             ]
                          },
                          {
                             "t":43.0000017514259
                          }
                       ],
                       "ix":11
                    },
                    "r":{
                       "a":0,
                       "k":0,
                       "ix":10
                    },
                    "p":{
                       "a":0,
                       "k":[
                          202.523,
                          124.984,
                          0
                       ],
                       "ix":2
                    },
                    "a":{
                       "a":0,
                       "k":[
                          0,
                          0,
                          0
                       ],
                       "ix":1
                    },
                    "s":{
                       "a":0,
                       "k":[
                          121.551,
                          99.981,
                          100
                       ],
                       "ix":6
                    }
                 },
                 "ao":0,
                 "shapes":[
                    {
                       "ty":"gr",
                       "it":[
                          {
                             "ty":"rc",
                             "d":1,
                             "s":{
                                "a":0,
                                "k":[
                                   117.813,
                                   4
                                ],
                                "ix":2
                             },
                             "p":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "nm":"Rectangle Path 1",
                             "mn":"ADBE Vector Shape - Rect",
                             "hd":false
                          },
                          {
                             "ty":"fl",
                             "c":{
                                "a":0,
                                "k":[
                                   0.137254901961,
                                   0.588235294118,
                                   0.172549019608,
                                   1
                                ],
                                "ix":4
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":5
                             },
                             "r":1,
                             "bm":0,
                             "nm":"Fill 1",
                             "mn":"ADBE Vector Graphic - Fill",
                             "hd":false
                          },
                          {
                             "ty":"tr",
                             "p":{
                                "a":0,
                                "k":[
                                   -1.531,
                                   -81
                                ],
                                "ix":2
                             },
                             "a":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":1
                             },
                             "s":{
                                "a":0,
                                "k":[
                                   145.508,
                                   100
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":6
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":7
                             },
                             "sk":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "sa":{
                                "a":0,
                                "k":0,
                                "ix":5
                             },
                             "nm":"Transform"
                          }
                       ],
                       "nm":"Rectangle 1",
                       "np":3,
                       "cix":2,
                       "bm":0,
                       "ix":1,
                       "mn":"ADBE Vector Group",
                       "hd":false
                    }
                 ],
                 "ip":0,
                 "op":44.0000017921567,
                 "st":0,
                 "bm":0
              },
              {
                 "ddd":0,
                 "ind":15,
                 "ty":4,
                 "nm":"Shape Layer 8",
                 "sr":1,
                 "ks":{
                    "o":{
                       "a":1,
                       "k":[
                          {
                             "i":{
                                "x":[
                                   0.833
                                ],
                                "y":[
                                   0.833
                                ]
                             },
                             "o":{
                                "x":[
                                   0.167
                                ],
                                "y":[
                                   0.167
                                ]
                             },
                             "t":0,
                             "s":[
                                15
                             ],
                             "e":[
                                10
                             ]
                          },
                          {
                             "t":43.0000017514259
                          }
                       ],
                       "ix":11
                    },
                    "r":{
                       "a":0,
                       "k":0,
                       "ix":10
                    },
                    "p":{
                       "a":0,
                       "k":[
                          202.523,
                          118.984,
                          0
                       ],
                       "ix":2
                    },
                    "a":{
                       "a":0,
                       "k":[
                          0,
                          0,
                          0
                       ],
                       "ix":1
                    },
                    "s":{
                       "a":0,
                       "k":[
                          121.551,
                          99.981,
                          100
                       ],
                       "ix":6
                    }
                 },
                 "ao":0,
                 "shapes":[
                    {
                       "ty":"gr",
                       "it":[
                          {
                             "ty":"rc",
                             "d":1,
                             "s":{
                                "a":0,
                                "k":[
                                   117.813,
                                   4
                                ],
                                "ix":2
                             },
                             "p":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "nm":"Rectangle Path 1",
                             "mn":"ADBE Vector Shape - Rect",
                             "hd":false
                          },
                          {
                             "ty":"fl",
                             "c":{
                                "a":0,
                                "k":[
                                   0.137254901961,
                                   0.588235294118,
                                   0.172549019608,
                                   1
                                ],
                                "ix":4
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":5
                             },
                             "r":1,
                             "bm":0,
                             "nm":"Fill 1",
                             "mn":"ADBE Vector Graphic - Fill",
                             "hd":false
                          },
                          {
                             "ty":"tr",
                             "p":{
                                "a":0,
                                "k":[
                                   -1.531,
                                   -81
                                ],
                                "ix":2
                             },
                             "a":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":1
                             },
                             "s":{
                                "a":0,
                                "k":[
                                   145.508,
                                   100
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":6
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":7
                             },
                             "sk":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "sa":{
                                "a":0,
                                "k":0,
                                "ix":5
                             },
                             "nm":"Transform"
                          }
                       ],
                       "nm":"Rectangle 1",
                       "np":3,
                       "cix":2,
                       "bm":0,
                       "ix":1,
                       "mn":"ADBE Vector Group",
                       "hd":false
                    }
                 ],
                 "ip":0,
                 "op":44.0000017921567,
                 "st":0,
                 "bm":0
              },
              {
                 "ddd":0,
                 "ind":16,
                 "ty":4,
                 "nm":"Shape Layer 6",
                 "sr":1,
                 "ks":{
                    "o":{
                       "a":1,
                       "k":[
                          {
                             "i":{
                                "x":[
                                   0.833
                                ],
                                "y":[
                                   0.833
                                ]
                             },
                             "o":{
                                "x":[
                                   0.167
                                ],
                                "y":[
                                   0.167
                                ]
                             },
                             "t":0,
                             "s":[
                                10
                             ],
                             "e":[
                                5
                             ]
                          },
                          {
                             "t":43.0000017514259
                          }
                       ],
                       "ix":11
                    },
                    "r":{
                       "a":0,
                       "k":0,
                       "ix":10
                    },
                    "p":{
                       "a":0,
                       "k":[
                          202.523,
                          112.984,
                          0
                       ],
                       "ix":2
                    },
                    "a":{
                       "a":0,
                       "k":[
                          0,
                          0,
                          0
                       ],
                       "ix":1
                    },
                    "s":{
                       "a":0,
                       "k":[
                          121.551,
                          99.981,
                          100
                       ],
                       "ix":6
                    }
                 },
                 "ao":0,
                 "shapes":[
                    {
                       "ty":"gr",
                       "it":[
                          {
                             "ty":"rc",
                             "d":1,
                             "s":{
                                "a":0,
                                "k":[
                                   117.813,
                                   4
                                ],
                                "ix":2
                             },
                             "p":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "nm":"Rectangle Path 1",
                             "mn":"ADBE Vector Shape - Rect",
                             "hd":false
                          },
                          {
                             "ty":"fl",
                             "c":{
                                "a":0,
                                "k":[
                                   0.137254901961,
                                   0.588235294118,
                                   0.172549019608,
                                   1
                                ],
                                "ix":4
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":5
                             },
                             "r":1,
                             "bm":0,
                             "nm":"Fill 1",
                             "mn":"ADBE Vector Graphic - Fill",
                             "hd":false
                          },
                          {
                             "ty":"tr",
                             "p":{
                                "a":0,
                                "k":[
                                   -1.531,
                                   -81
                                ],
                                "ix":2
                             },
                             "a":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":1
                             },
                             "s":{
                                "a":0,
                                "k":[
                                   145.508,
                                   100
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":6
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":7
                             },
                             "sk":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "sa":{
                                "a":0,
                                "k":0,
                                "ix":5
                             },
                             "nm":"Transform"
                          }
                       ],
                       "nm":"Rectangle 1",
                       "np":3,
                       "cix":2,
                       "bm":0,
                       "ix":1,
                       "mn":"ADBE Vector Group",
                       "hd":false
                    }
                 ],
                 "ip":0,
                 "op":44.0000017921567,
                 "st":0,
                 "bm":0
              },
              {
                 "ddd":0,
                 "ind":17,
                 "ty":4,
                 "nm":"Shape Layer 5",
                 "sr":1,
                 "ks":{
                    "o":{
                       "a":1,
                       "k":[
                          {
                             "i":{
                                "x":[
                                   0.833
                                ],
                                "y":[
                                   0.833
                                ]
                             },
                             "o":{
                                "x":[
                                   0.167
                                ],
                                "y":[
                                   0.167
                                ]
                             },
                             "t":0,
                             "s":[
                                5
                             ],
                             "e":[
                                0
                             ]
                          },
                          {
                             "t":43.0000017514259
                          }
                       ],
                       "ix":11
                    },
                    "r":{
                       "a":0,
                       "k":0,
                       "ix":10
                    },
                    "p":{
                       "a":0,
                       "k":[
                          202.523,
                          106.984,
                          0
                       ],
                       "ix":2
                    },
                    "a":{
                       "a":0,
                       "k":[
                          0,
                          0,
                          0
                       ],
                       "ix":1
                    },
                    "s":{
                       "a":0,
                       "k":[
                          121.551,
                          99.981,
                          100
                       ],
                       "ix":6
                    }
                 },
                 "ao":0,
                 "shapes":[
                    {
                       "ty":"gr",
                       "it":[
                          {
                             "ty":"rc",
                             "d":1,
                             "s":{
                                "a":0,
                                "k":[
                                   117.813,
                                   4
                                ],
                                "ix":2
                             },
                             "p":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "nm":"Rectangle Path 1",
                             "mn":"ADBE Vector Shape - Rect",
                             "hd":false
                          },
                          {
                             "ty":"fl",
                             "c":{
                                "a":0,
                                "k":[
                                   0.137254901961,
                                   0.588235294118,
                                   0.172549019608,
                                   1
                                ],
                                "ix":4
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":5
                             },
                             "r":1,
                             "bm":0,
                             "nm":"Fill 1",
                             "mn":"ADBE Vector Graphic - Fill",
                             "hd":false
                          },
                          {
                             "ty":"tr",
                             "p":{
                                "a":0,
                                "k":[
                                   -1.531,
                                   -81
                                ],
                                "ix":2
                             },
                             "a":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":1
                             },
                             "s":{
                                "a":0,
                                "k":[
                                   145.508,
                                   100
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":6
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":7
                             },
                             "sk":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "sa":{
                                "a":0,
                                "k":0,
                                "ix":5
                             },
                             "nm":"Transform"
                          }
                       ],
                       "nm":"Rectangle 1",
                       "np":3,
                       "cix":2,
                       "bm":0,
                       "ix":1,
                       "mn":"ADBE Vector Group",
                       "hd":false
                    }
                 ],
                 "ip":0,
                 "op":44.0000017921567,
                 "st":0,
                 "bm":0
              },
              {
                 "ddd":0,
                 "ind":18,
                 "ty":4,
                 "nm":"Shape Layer 4",
                 "sr":1,
                 "ks":{
                    "o":{
                       "a":1,
                       "k":[
                          {
                             "i":{
                                "x":[
                                   0.833
                                ],
                                "y":[
                                   0.833
                                ]
                             },
                             "o":{
                                "x":[
                                   0.167
                                ],
                                "y":[
                                   0.167
                                ]
                             },
                             "t":0,
                             "s":[
                                0
                             ],
                             "e":[
                                0
                             ]
                          },
                          {
                             "t":43.0000017514259
                          }
                       ],
                       "ix":11
                    },
                    "r":{
                       "a":0,
                       "k":0,
                       "ix":10
                    },
                    "p":{
                       "a":0,
                       "k":[
                          202.523,
                          100.984,
                          0
                       ],
                       "ix":2
                    },
                    "a":{
                       "a":0,
                       "k":[
                          0,
                          0,
                          0
                       ],
                       "ix":1
                    },
                    "s":{
                       "a":0,
                       "k":[
                          121.551,
                          99.981,
                          100
                       ],
                       "ix":6
                    }
                 },
                 "ao":0,
                 "shapes":[
                    {
                       "ty":"gr",
                       "it":[
                          {
                             "ty":"rc",
                             "d":1,
                             "s":{
                                "a":0,
                                "k":[
                                   117.813,
                                   4
                                ],
                                "ix":2
                             },
                             "p":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "nm":"Rectangle Path 1",
                             "mn":"ADBE Vector Shape - Rect",
                             "hd":false
                          },
                          {
                             "ty":"fl",
                             "c":{
                                "a":0,
                                "k":[
                                   0.137254901961,
                                   0.588235294118,
                                   0.172549019608,
                                   1
                                ],
                                "ix":4
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":5
                             },
                             "r":1,
                             "bm":0,
                             "nm":"Fill 1",
                             "mn":"ADBE Vector Graphic - Fill",
                             "hd":false
                          },
                          {
                             "ty":"tr",
                             "p":{
                                "a":0,
                                "k":[
                                   -1.531,
                                   -81
                                ],
                                "ix":2
                             },
                             "a":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":1
                             },
                             "s":{
                                "a":0,
                                "k":[
                                   145.508,
                                   100
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":6
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":7
                             },
                             "sk":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "sa":{
                                "a":0,
                                "k":0,
                                "ix":5
                             },
                             "nm":"Transform"
                          }
                       ],
                       "nm":"Rectangle 1",
                       "np":3,
                       "cix":2,
                       "bm":0,
                       "ix":1,
                       "mn":"ADBE Vector Group",
                       "hd":false
                    }
                 ],
                 "ip":0,
                 "op":44.0000017921567,
                 "st":0,
                 "bm":0
              },
              {
                 "ddd":0,
                 "ind":19,
                 "ty":4,
                 "nm":"Shape Layer 3",
                 "sr":1,
                 "ks":{
                    "o":{
                       "a":0,
                       "k":0,
                       "ix":11
                    },
                    "r":{
                       "a":0,
                       "k":0,
                       "ix":10
                    },
                    "p":{
                       "a":0,
                       "k":[
                          202.523,
                          94.984,
                          0
                       ],
                       "ix":2
                    },
                    "a":{
                       "a":0,
                       "k":[
                          0,
                          0,
                          0
                       ],
                       "ix":1
                    },
                    "s":{
                       "a":0,
                       "k":[
                          121.551,
                          99.981,
                          100
                       ],
                       "ix":6
                    }
                 },
                 "ao":0,
                 "shapes":[
                    {
                       "ty":"gr",
                       "it":[
                          {
                             "ty":"rc",
                             "d":1,
                             "s":{
                                "a":0,
                                "k":[
                                   117.813,
                                   4
                                ],
                                "ix":2
                             },
                             "p":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "nm":"Rectangle Path 1",
                             "mn":"ADBE Vector Shape - Rect",
                             "hd":false
                          },
                          {
                             "ty":"fl",
                             "c":{
                                "a":0,
                                "k":[
                                   0.137254901961,
                                   0.588235294118,
                                   0.172549019608,
                                   1
                                ],
                                "ix":4
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":5
                             },
                             "r":1,
                             "bm":0,
                             "nm":"Fill 1",
                             "mn":"ADBE Vector Graphic - Fill",
                             "hd":false
                          },
                          {
                             "ty":"tr",
                             "p":{
                                "a":0,
                                "k":[
                                   -1.531,
                                   -81
                                ],
                                "ix":2
                             },
                             "a":{
                                "a":0,
                                "k":[
                                   0,
                                   0
                                ],
                                "ix":1
                             },
                             "s":{
                                "a":0,
                                "k":[
                                   145.508,
                                   100
                                ],
                                "ix":3
                             },
                             "r":{
                                "a":0,
                                "k":0,
                                "ix":6
                             },
                             "o":{
                                "a":0,
                                "k":100,
                                "ix":7
                             },
                             "sk":{
                                "a":0,
                                "k":0,
                                "ix":4
                             },
                             "sa":{
                                "a":0,
                                "k":0,
                                "ix":5
                             },
                             "nm":"Transform"
                          }
                       ],
                       "nm":"Rectangle 1",
                       "np":3,
                       "cix":2,
                       "bm":0,
                       "ix":1,
                       "mn":"ADBE Vector Group",
                       "hd":false
                    }
                 ],
                 "ip":0,
                 "op":44.0000017921567,
                 "st":0,
                 "bm":0
              }
           ]
        }
     ],
     "layers":[
        {
           "ddd":0,
           "ind":1,
           "ty":0,
           "nm":"Pre-comp 7",
           "refId":"comp_0",
           "sr":1,
           "ks":{
              "o":{
                 "a":0,
                 "k":100,
                 "ix":11
              },
              "r":{
                 "a":0,
                 "k":0,
                 "ix":10
              },
              "p":{
                 "a":1,
                 "k":[
                    {
                       "i":{
                          "x":0.833,
                          "y":0.833
                       },
                       "o":{
                          "x":0.167,
                          "y":0.167
                       },
                       "t":0,
                       "s":[
                          92,
                          69,
                          0
                       ],
                       "e":[
                          92,
                          63,
                          0
                       ],
                       "to":[
                          0,
                          -1,
                          0
                       ],
                       "ti":[
                          0,
                          1,
                          0
                       ]
                    },
                    {
                       "t":43.0000017514259
                    }
                 ],
                 "ix":2
              },
              "a":{
                 "a":0,
                 "k":[
                    201,
                    83,
                    0
                 ],
                 "ix":1
              },
              "s":{
                 "a":0,
                 "k":[
                    100,
                    100,
                    100
                 ],
                 "ix":6
              }
           },
           "ao":0,
           "w":402,
           "h":166,
           "ip":0,
           "op":44.0000017921567,
           "st":0,
           "bm":0
        }
     ],
     "markers":[
  
     ]
  }
 