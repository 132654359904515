import { Entity } from '../helpers/vo/entity';
import { Serializable } from '../helpers/vo/serializable';
import * as _ from 'lodash';

export default class Additional extends Entity implements Serializable<Additional> {

    companyName: string;
    contactName: string;
    contactPhoneNO: string;
    domofonCode: string;
    flatNO: string;
    floor: string;
    lift: boolean;
    staircaseNO: string;

    deserialize(data: any): Additional {

        this.companyName = _.get(data, 'companyName', '');
        this.contactName = _.get(data, 'contactName', '');
        this.contactPhoneNO = _.get(data, 'contactPhoneNO', '');
        this.domofonCode = _.get(data, 'domofonCode', '');
        this.flatNO = _.get(data, 'flatNO', '');
        this.floor = _.get(data, 'floor', '');
        this.lift = _.get(data, 'lift', '');
        this.staircaseNO = _.get(data, 'staircaseNO', '');

        return this;
    }

    serialize() {
        const serialized = {
            companyName: this.companyName,
            contactName: this.contactName,
            contactPhoneNO: this.contactPhoneNO,
            domofonCode: this.domofonCode,
            flatNO: this.flatNO,
            floor: this.floor,
            lift: this.lift,
            staircaseNO: this.staircaseNO,
        };
        return serialized;
    }
}
