export const stepsConfigurations = {

     "numberOfStepsInHour": 2,
     "stepHeight": 30,
     "stepWidth": 200,
     "stepsInShift": 16,
     "overlapingStep": 30,
     "minSize": 1,
     "maxSize": 6,
     "defaultSize": 2,
     "preBookedSlots": false,

     "shifts": [
          {

               "type": "MORNING",
               "date": '2020-03-20',
               "steps": [
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "06:00:00",
                         "endTime": "06:30:00",
                         "cutOff": null,
                         "slot": "06:00-07:00",
                         "availability": true,
                         "isLast": false,
                         "index": 0,
                         "freeDelivery": null,
                         "shiftType": "MORNING"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "06:30:00",
                         "endTime": "07:00:00",
                         "cutOff": null,
                         "slot": "06:30-07:30",
                         "availability": true,
                         "isLast": false,
                         "index": 1,
                         "freeDelivery": null,
                         "shiftType": "MORNING"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "07:00:00",
                         "endTime": "08:00:00",
                         "cutOff": null,
                         "slot": "07:00-08:00",
                         "availability": true,
                         "isLast": false,
                         "index": 2,
                         "freeDelivery": null,
                         "shiftType": "MORNING"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "07:30:00",
                         "endTime": "08:30:00",
                         "cutOff": null,
                         "slot": "07:30-08:30",
                         "availability": true,
                         "isLast": false,
                         "index": 3,
                         "freeDelivery": null,
                         "shiftType": "MORNING"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "08:00:00",
                         "endTime": "09:00:00",
                         "cutOff": null,
                         "slot": "08:00-09:00",
                         "availability": true,
                         "isLast": false,
                         "index": 4,
                         "freeDelivery": null,
                         "shiftType": "MORNING"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "08:30:00",
                         "endTime": "09:30:00",
                         "cutOff": null,
                         "slot": "08:30-09:30",
                         "availability": true,
                         "isLast": false,
                         "index": 5,
                         "freeDelivery": null,
                         "shiftType": "MORNING"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "09:00:00",
                         "endTime": "10:00:00",
                         "cutOff": null,
                         "slot": "09:00-10:00",
                         "availability": true,
                         "isLast": false,
                         "index": 6,
                         "freeDelivery": null,
                         "shiftType": "MORNING"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "09:30:00",
                         "endTime": "10:30:00",
                         "cutOff": null,
                         "slot": "09:30-10:30",
                         "availability": true,
                         "isLast": false,
                         "index": 7,
                         "freeDelivery": null,
                         "shiftType": "MORNING"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "10:00:00",
                         "endTime": "11:00:00",
                         "cutOff": null,
                         "slot": "10:00-11:00",
                         "availability": true,
                         "isLast": false,
                         "index": 8,
                         "freeDelivery": null,
                         "shiftType": "MORNING"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "10:30:00",
                         "endTime": "11:30:00",
                         "cutOff": null,
                         "slot": "10:30-11:30",
                         "availability": true,
                         "isLast": false,
                         "index": 9,
                         "freeDelivery": null,
                         "shiftType": "MORNING"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "11:00:00",
                         "endTime": "12:00:00",
                         "cutOff": null,
                         "slot": "11:00-12:00",
                         "availability": true,
                         "isLast": false,
                         "index": 10,
                         "freeDelivery": null,
                         "shiftType": "MORNING"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "11:30:00",
                         "endTime": "12:30:00",
                         "cutOff": null,
                         "slot": "11:30-12:30",
                         "availability": true,
                         "isLast": false,
                         "index": 11,
                         "freeDelivery": null,
                         "shiftType": "MORNING"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "12:00:00",
                         "endTime": "13:00:00",
                         "cutOff": null,
                         "slot": "12:00-13:00",
                         "availability": true,
                         "isLast": false,
                         "index": 12,
                         "freeDelivery": null,
                         "shiftType": "MORNING"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "12:30:00",
                         "endTime": "13:30:00",
                         "cutOff": null,
                         "slot": "12:30-13:30",
                         "availability": true,
                         "isLast": false,
                         "index": 13,
                         "freeDelivery": null,
                         "shiftType": "MORNING"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "13:00:00",
                         "endTime": "14:00:00",
                         "cutOff": null,
                         "slot": "13:00-14:00",
                         "availability": true,
                         "isLast": true,
                         "index": 14,
                         "freeDelivery": null,
                         "shiftType": "MORNING"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "13:30:00",
                         "endTime": "14:30:00",
                         "cutOff": null,
                         "slot": "13:30-14:30",
                         "availability": true,
                         "isLast": true,
                         "index": 15,
                         "freeDelivery": null,
                         "shiftType": "MORNING"
                    },
                    // {
                    //      "slotId": null,
                    //      "date": null,
                    //      "startTime": "14:00:00",
                    //      "endTime": "15:00:00",
                    //      "cutOff": null,
                    //      "slot": "14:00-15:00",
                    //      "availability": true,
                    //      "isLast": true,
                    //      "index": 16,
                    //      "freeDelivery": null,
                    //      "shiftType": "MORNING"
                    // }
               ]
          },
          {
               "type": "AFTERNOON",
               "steps": [
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "15:00:00",
                         "endTime": "16:00:00",
                         "cutOff": null,
                         "slot": "15:00-16:00",
                         "availability": true,
                         "isLast": false,
                         "index": 0,
                         "freeDelivery": null,
                         "shiftType": "AFTERNOON"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "15:30:00",
                         "endTime": "16:30:00",
                         "cutOff": null,
                         "slot": "15:30-16:30",
                         "availability": true,
                         "isLast": false,
                         "index": 1,
                         "freeDelivery": null,
                         "shiftType": "AFTERNOON"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "16:00:00",
                         "endTime": "17:00:00",
                         "cutOff": null,
                         "slot": "16:00-17:00",
                         "availability": true,
                         "isLast": false,
                         "index": 2,
                         "freeDelivery": null,
                         "shiftType": "AFTERNOON"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "16:30:00",
                         "endTime": "17:30:00",
                         "cutOff": null,
                         "slot": "16:30-17:30",
                         "availability": true,
                         "isLast": false,
                         "index": 3,
                         "freeDelivery": null,
                         "shiftType": "AFTERNOON"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "17:00:00",
                         "endTime": "18:00:00",
                         "cutOff": null,
                         "slot": "17:00-18:00",
                         "availability": false,
                         "isLast": false,
                         "index": 4,
                         "freeDelivery": null,
                         "shiftType": "AFTERNOON"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "17:30:00",
                         "endTime": "18:30:00",
                         "cutOff": null,
                         "slot": "17:30-18:30",
                         "availability": false,
                         "isLast": false,
                         "index": 5,
                         "freeDelivery": null,
                         "shiftType": "AFTERNOON"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "18:00:00",
                         "endTime": "19:00:00",
                         "cutOff": null,
                         "slot": "18:00-19:00",
                         "availability": false,
                         "isLast": false,
                         "index": 6,
                         "freeDelivery": null,
                         "shiftType": "AFTERNOON"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "18:30:00",
                         "endTime": "19:30:00",
                         "cutOff": null,
                         "slot": "18:30-19:30",
                         "availability": false,
                         "isLast": false,
                         "index": 7,
                         "freeDelivery": null,
                         "shiftType": "AFTERNOON"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "19:00:00",
                         "endTime": "20:00:00",
                         "cutOff": null,
                         "slot": "19:00-20:00",
                         "availability": true,
                         "isLast": false,
                         "index": 8,
                         "freeDelivery": null,
                         "shiftType": "AFTERNOON"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "19:30:00",
                         "endTime": "20:30:00",
                         "cutOff": null,
                         "slot": "19:30-20:30",
                         "availability": true,
                         "isLast": false,
                         "index": 9,
                         "freeDelivery": null,
                         "shiftType": "AFTERNOON"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "20:00:00",
                         "endTime": "21:00:00",
                         "cutOff": null,
                         "slot": "20:00-21:00",
                         "availability": true,
                         "isLast": false,
                         "index": 10,
                         "freeDelivery": null,
                         "shiftType": "AFTERNOON"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "20:30:00",
                         "endTime": "21:30:00",
                         "cutOff": null,
                         "slot": "20:30-21:30",
                         "availability": true,
                         "isLast": false,
                         "index": 11,
                         "freeDelivery": null,
                         "shiftType": "AFTERNOON"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "21:00:00",
                         "endTime": "22:00:00",
                         "cutOff": null,
                         "slot": "21:00-22:00",
                         "availability": true,
                         "isLast": false,
                         "index": 12,
                         "freeDelivery": null,
                         "shiftType": "AFTERNOON"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "21:30:00",
                         "endTime": "22:30:00",
                         "cutOff": null,
                         "slot": "21:30-22:30",
                         "availability": true,
                         "isLast": false,
                         "index": 13,
                         "freeDelivery": null,
                         "shiftType": "AFTERNOON"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "22:00:00",
                         "endTime": "23:00:00",
                         "cutOff": null,
                         "slot": "22:00-23:00",
                         "availability": true,
                         "isLast": true,
                         "index": 14,
                         "freeDelivery": null,
                         "shiftType": "AFTERNOON"
                    },
                    {
                         "slotId": null,
                         "date": null,
                         "startTime": "22:30:00",
                         "endTime": "23:30:00",
                         "cutOff": null,
                         "slot": "22:30-23:30",
                         "availability": true,
                         "isLast": null,
                         "index": 15,
                         "freeDelivery": null,
                         "shiftType": "AFTERNOON"
                    },
                    // {
                    //      "slotId": null,
                    //      "date": null,
                    //      "startTime": "22:30:00",
                    //      "endTime": "23:30:00",
                    //      "cutOff": null,
                    //      "slot": "22:30-23:30",
                    //      "availability": true,
                    //      "isLast": null,
                    //      "index": 1,
                    //      "freeDelivery": null,
                    //      "shiftType": "AFTERNOON"
                    // }
               ]
          }
     ]
};

