import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { waveAnimations } from '../../../../slot-selection/animations/wave.animation';
import { SlotSelectionService } from '../../../../slot-selection/services/slot-selection.service';
import { ReservationsWizardService } from '../../../../shared/services/reservation-wizard.service';
import { ReservationInterface } from '../../../../shared/interfaces/reservation-interface';
import { WidgetMode } from '../../../../shared/enums/widget-mode.enum';
import { ShowState } from '../../../../slot-selection/animations/slider.animation';
import { ShowStateEcoSlots } from '../../../../slot-selection/animations/state.animation';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-eco-slots',
  templateUrl: './eco.component.html',
  styleUrls: ['./eco.component.scss'],
  animations: [ShowStateEcoSlots]
})
export class EcoComponent implements OnInit, OnDestroy {

  static shiftHeight = 480;

  @Input() sliderPostionTop;

  lottieConfig: Object;
  reservation: ReservationInterface;
  currentMousePosition = 0;

  wizardServiceSubscription$: Subscription;
  slotSelectionServiceSubscription$: Subscription;

  get isReserved(): boolean {
    return this.reservation.mode === WidgetMode.RESERVED;
  }

  get isActive(): boolean {
      return this.reservation.mode === WidgetMode.ACTIVE || this.reservation.mode === undefined;
  }

  get isEcoSlots(): boolean {
    return this.reservation.mode === WidgetMode.ECO_SLOTS;
  }

  get ecoZoneTopHeight(): number {
    return this.sliderPostionTop - this.currentMousePosition + 30;
  }

  constructor(
    private readonly slotSelectionService: SlotSelectionService,
    private readonly wizardService: ReservationsWizardService
  ) { }

  ngOnInit() {

    this.wizardServiceSubscription$ = this.wizardService.getModel().subscribe((reservation: ReservationInterface) => {
      this.reservation = reservation;
      this.currentMousePosition = this.reservation.sliderCurrentPosition;
    });

    this.slotSelectionServiceSubscription$ = this.slotSelectionService.sliderPositionTop$.subscribe(
      (position: number) => {
        this.currentMousePosition = (position !== -1) ? position : this.reservation.sliderCurrentPosition;
      }
    );

    this.lottieConfig = {
      animationData: waveAnimations,
      autoplay: true,
      loop: true,
      renderer: 'svg',
  };

  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy: EcoComponent');
    if (this.wizardServiceSubscription$) {
        this.wizardServiceSubscription$.unsubscribe();
    }

    if (this.slotSelectionServiceSubscription$) {
        this.slotSelectionServiceSubscription$.unsubscribe();
    }
  }

}
