import { Entity } from '../helpers/vo/entity';
import { Serializable } from '../helpers/vo/serializable';
import * as _ from 'lodash';

export default class Address extends Entity implements Serializable<Address> {

    country: string;
    district: string;
    houseNO: string;
    street: string;
    town: string;
    voivoidship: string;
    zip: string;

    deserialize(data: any): Address {

        this.country = _.get(data, 'country', '');
        this.district = _.get(data, 'district', '');
        this.houseNO = _.get(data, 'houseNO', '');
        this.street = _.get(data, 'street', '');
        this.town = _.get(data, 'town', '');
        this.voivoidship = _.get(data, 'voivoidship', '');
        this.zip = _.get(data, 'zip', '');

        return this;
    }

    serialize() {
        const serialized = {
            country: this.country,
            district: this.district,
            houseNO: this.houseNO,
            street: this.street,
            town: this.town,
            voivoidship: this.voivoidship,
            zip: this.zip
        };
        return serialized;
    }

    get label(): string {
        return `${this.street} ${this.houseNO}, ${this.town}`;
    }
}
