import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-demo-booking-always-on',
  templateUrl: './demo-booking-always-on.component.html',
  styleUrls: ['./demo-booking-always-on.component.scss']
})
export class DemoBookingAlwaysOnComponent implements OnInit {

  segmentSize = 1;
  totalCustomers = 1234222;
  totalCustomersWeekly = this.totalCustomers / 4;
  totalCustomersDaily = this.totalCustomers / 7;

  constructor() { }

  ngOnInit() {
  }

  get weeklyOrders() {
    switch (this.segmentSize) {
      case 0:
        return 0;
      case 0.01:
        return 4.81;
      case 0.3:
        return 58.01;
      case 0.5:
        return 79.79;
      case 1:
        return 100;
      default:
        if (this.segmentSize <= 0.29) {
          return (4.81 + (this.segmentSize * 180.25)).toFixed(2);
        } else if (this.segmentSize > 0.3 && this.segmentSize < 0.5) {
          return (58.01 + (this.segmentSize * 34.25)).toFixed(2);
        } else if (this.segmentSize > 0.5 && this.segmentSize < 1) {
          return (79.79 + (this.segmentSize * 18.25)).toFixed(2);
        } else {
          return (100 + (this.segmentSize * 3.25)).toFixed(2);
        }
    }
  }

  get netRevenue() {
    switch (this.segmentSize) {
      case 0:
        return 0;
      case 0.01:
        return 4.76;
      case 1:
        return 30;
      default:
        if (this.segmentSize < 100) {
          return (4.76 + (this.segmentSize * 18.25)).toFixed(2);
        } else {
          return (30 + (this.segmentSize * 18.25)).toFixed(2);
        }
    }
  }

  get netMargin() {
    switch (this.segmentSize) {
      case 0:
        return 0;
      case 0.01:
        return 1;
      case 1:
        return 15;
      default:
        if (this.segmentSize < 100) {
          return (1 + (this.segmentSize * 13.25)).toFixed(2);
        } else {
          return (15 + (this.segmentSize * 13.25)).toFixed(2);

        }
    }
  }

}
