import { Component, OnInit, Input } from '@angular/core';
import { StepInterface } from './../../../../slot-selection/interfaces/step-interface';
import { ReservationsWizardService } from '../../../../shared/services/reservation-wizard.service';
import { ReservationInterface } from '../../../../shared/interfaces/reservation-interface';
import { filter } from 'rxjs/internal/operators/filter';
import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';

@Component({
  selector: 'app-slots-hours',
  templateUrl: './slots-hours.component.html',
  styleUrls: ['./slots-hours.component.scss']
})
export class SlotsHoursComponent implements OnInit {
  @Input() step: StepInterface;
  @Input() index: number;
  @Input() isEcoMode: boolean;

  reservation$: Observable<ReservationInterface>;

  constructor(
    private readonly wizardService: ReservationsWizardService,
  ) { }

  ngOnInit() {
    this.reservation$ = this.wizardService.getModel().pipe(
      filter(reservation => reservation !== undefined)
    );
  }

  lastItem(time: string) {
    return moment(`2020-01-01 ${time}`).add(30, 'minutes').format('HH:mm');
  }

}
