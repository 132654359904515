import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { LocationInterface } from '../interfaces/location-interface';
import { Observable } from 'rxjs/Observable';
import { shareReplay } from 'rxjs/operators';
import { ReservationsWizardService } from './../../shared/services/reservation-wizard.service';
import { ReservationInterface } from '../../shared/interfaces/reservation-interface';

@Injectable({
  providedIn: 'root'
})
export class AddressSelectionService {

private static CACHE_SIZE = 1;
private cache$: Observable<LocationInterface[]>;

  private itemsCollection$: AngularFirestoreCollection<LocationInterface>;
  public locations$: Observable<LocationInterface[]>;

  constructor(
    private readonly afs: AngularFirestore,
    private readonly wizardService: ReservationsWizardService
  ) {
    this.wizardService.getModel().subscribe((a) => console.log(a));
  }

  getLocations(): Observable<LocationInterface[]> {
    this.cache$ = this.requestLocations().pipe(
      shareReplay(AddressSelectionService.CACHE_SIZE)
    );
    return this.cache$;
  }

  requestLocations() {
    this.itemsCollection$ = this.afs.collection<LocationInterface>('locations');
    return this.itemsCollection$.valueChanges();
  }

  changedLocation(location: LocationInterface) {
    console.log('Model updated: AddressSelectionService');
    this.wizardService.updateModel({
      location
    } as Partial<ReservationInterface>);
  }
}
