import {Component,OnInit} from '@angular/core';

@Component({
    selector: 'slot-picker-main',
    template: '<address-list></address-list><app-picker></app-picker>',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {


    constructor(

    ) { }

    ngOnInit() {
        
    }

}