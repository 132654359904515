import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'demo-booking',
    loadChildren: () => import('./demo-booking/demo-booking.module').then(m => m.DemoBookingModule)
  },

  { path: '', redirectTo: '/picker', pathMatch: 'full' },

];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {enableTracing: false})],
  exports: [RouterModule],
  providers: []
})

export class Routing { }
