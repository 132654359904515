import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Settings } from './settings.interface';

@Component({
  selector: 'app-picker-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  @Output() slotpickerSettingsChanged = new EventEmitter<Settings>();

  form: FormGroup;
  loader = true;
  submitted = false;

  constructor(private readonly fb: FormBuilder) { }

  ngOnInit() {
    this.form = this.fb.group({
      defaultSize: [60, [Validators.required]],
      minSize: [1, [Validators.required]],
      maxSize: [6, [Validators.required]],
      overlapingStep: [30, [Validators.required]],
      preBookedSlot: [false, [Validators.required]],
    });

    this.loader = false;

    this.onSubmit();
  }

  onSubmit() {

    if (this.form.invalid) {
      return;
    }
    console.log(this.form.getRawValue() as Settings);
    this.slotpickerSettingsChanged.emit(this.form.getRawValue() as Settings);
  }
}
