import {RouterModule,Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {MainComponent} from './main/main.component';
import { PickerComponent } from './picker/picker.component';
import { LayoutComponent } from '../layout/layout.component';
import { AuthGuardService } from '../../services/auth-guard.service';


const pickerRoutes: Routes = [
    {
        path: 'picker',
        component: LayoutComponent,
        canActivate: [AuthGuardService],
        children: [
            { path: '', component: MainComponent },
            { path: 'choose-available-slot', component: PickerComponent, outlet: 'content' },
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(pickerRoutes)],
    exports: [RouterModule]
})

export class PickerRouting {
}


