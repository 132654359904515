export enum ShiftType {
    MORNING = 'MORNING',
    AFTERNOON = 'AFTERNOON',
    EVENING = 'EVENING'
}

export interface ShiftElement {
    type: ShiftType;
    steps: StepElement[];
}

export interface StepElement {
    hour:          string;
    minute:        string;
    available:     boolean;
    isLast:        boolean;
    freeDelivery?: boolean;
}