import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routing } from '../app.routing';
import { TranslationModule } from '../modules/translate.module';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddressListComponent } from '../components/choose-address/list/list.component';
import { AddressRouting } from '../components/choose-address/address.routing';
import { AddressComponent } from '../components/choose-address/address.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AddressSelectionComponent } from '../address-selection/components/address-selection/address-selection.component';
import { LocationLabelPipe } from '../address-selection/pipes/location-label.pipe';

const importPackages = [
  BrowserModule,
  TranslationModule,
  HttpModule,
  FormsModule,
  ReactiveFormsModule,
  BrowserAnimationsModule,
];

const routings = [
  Routing,
  AddressRouting
];

const components = [
  AddressListComponent,
  AddressComponent,
  AddressSelectionComponent,
  LocationLabelPipe
];

const providers = [

];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    ...importPackages,
    ...routings
  ],
  providers: [
    ...providers
  ],
  exports: [
    ...components,
    ...routings
  ],
  bootstrap: [AddressComponent]
})
export class AddressModule { }
