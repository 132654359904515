import { Component, OnInit, Input } from '@angular/core';
import { StepInterface } from '../../../../slot-selection/interfaces/step-interface';

@Component({
  selector: 'app-picker-ui-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepPickerUIComponent implements OnInit {

  @Input() step: StepInterface;
  @Input() index: number;


  constructor() { }

  ngOnInit() {
  }

}
