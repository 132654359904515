import { Directive, HostListener, HostBinding, ElementRef } from '@angular/core';

@Directive({
  selector: '[appStepListener]'
})
export class StepListenerDirective {

  constructor() { }


  @HostListener('mousemove', ['$event'])
  onClick(event) {

    //console.log(event.layerY);
  //   const selectedStep = (event.path.find((e: ElementRef, index: number) => e && e['id'] === 'WIDGET_SHIFT'));

  //   //console.log(selectedStep);

  //   console.log(selectedStep['offsetTop']);
   }

}
