import { Entity } from '../helpers/vo/entity';
import { Serializable } from '../helpers/vo/serializable';
import Additional from './additional';
import Address from './address';
import * as _ from 'lodash';
import Coordinates from './coordinates';

export default class Location extends Entity implements Serializable<Location> {

    additional: Additional;
    address: Address;
    coordinates: Coordinates;
    label: string;
    locationId: string;
    locationRef: string;

    public deserialize(data: any) {

        this.additional = new Additional().deserialize(_.get(data, 'additional', ''));
        this.address = new Address().deserialize(_.get(data, 'address', ''));
        this.coordinates = new Coordinates().deserialize(_.get(data, 'coordinates', ''));
        this.label = _.get(data, 'label', '');
        this.locationId = _.get(data, 'locationId', '');
        this.locationRef = _.get(data, 'locationRef', '');

        return this;
    }
}
