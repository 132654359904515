import { Component, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { FirebaseError } from 'firebase';
// import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [ './login.component.scss' ]
})
export class LoginComponent implements OnDestroy {
  readonly formGroup: FormGroup;
  readonly email: AbstractControl;
  readonly password: AbstractControl;
  readonly subscriptions: Subscription[] = [];
  // readonly version = environment.version;
  submitted = false;
  loading = false;
  error: string | undefined;

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly fb: FormBuilder) {
    this.formGroup = this.fb.group({
      email: [ '', [ Validators.required, Validators.email ] ],
      password: [ '', [ Validators.required ] ]
    });

    this.email = this.formGroup.controls[ 'email' ];
    this.password = this.formGroup.controls[ 'password' ];
  }

  login(event: Event): void {
    event.preventDefault();
    this.submitted = true;

    if (this.formGroup.invalid) {
      return;
    }
    console.log('work');
    this.loading = true;

    this.authService.SignIn(this.email.value, this.password.value)
      .then((test) => {
        this.loading = false;
        console.log('successs', test);
        this.router.navigate(['/picker'], {skipLocationChange: true});

      })
      .catch((error: FirebaseError) => {
        this.error = error.message;
        console.log('error', error);

        this.loading = false;
      });

    // this.subscriptions.push(
    //   this.authService.login(this.formGroup.value)
    //     .subscribe(
    //       () => {
    //         this.router.navigate([ '/' ]);
    //       },
    //       error => this.error = error,
    //       () => this.loading = false
    //     )
    // );
  }

  clearError(): void {
    this.error = undefined;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
