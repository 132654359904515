import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { DemoBookingComponent } from './components/demo-booking/demo-booking.component';
import { DemoBookingAlwaysOnComponent } from './components/demo-booking-always-on/demo-booking-always-on.component';
import { SmartBookingComponent } from './components/smart-booking/smart-booking.component';
import { LoyaltyComponent } from './components/loyalty/loyalty.component';
import { CashWithdrawalComponent } from './components/cash-withdrawal/cash-withdrawal.component';

const routes: Routes = [
    {
        path: '',
        component: DemoBookingComponent,
        children: [
            {
                path: 'always-on',
                component: DemoBookingAlwaysOnComponent
            },
            {
                path: 'smart-booking',
                component: SmartBookingComponent
            },
            {
                path: 'loyalty',
                component: LoyaltyComponent
            }
        ]

    },
    {
        path: 'cash-withdrawal',
        component: CashWithdrawalComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DemoBokingRouting { }
