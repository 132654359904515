export const slots = [
   //  {
   //     "date":"2019-03-14",
   //     "startTime":"06:00:00",
   //     "endTime":"07:00:00",
   //     "cutOff":"2019-03-04T14:00:00.000+0000",
   //     "slot":"06:00-07:00"
   //  },
   //  {
   //     "date":"2019-03-14",
   //     "startTime":"06:30:00",
   //     "endTime":"07:30:00",
   //     "cutOff":"2019-03-04T14:00:00.000+0000",
   //     "slot":"06:30-07:30"
   //  },
    // {
    //    "date":"2019-03-14",
    //    "startTime":"07:00:00",
    //    "endTime":"08:00:00",
    //    "cutOff":"2019-03-04T14:00:00.000+0000",
    //    "slot":"07:00-08:00"
    // },
    // {
    //    "date":"2019-03-14",
    //    "startTime":"07:30:00",
    //    "endTime":"08:30:00",
    //    "cutOff":"2019-03-04T14:00:00.000+0000",
    //    "slot":"07:30-08:30"
    // },
    // {
    //    "date":"2019-03-14",
    //    "startTime":"08:00:00",
    //    "endTime":"09:00:00",
    //    "cutOff":"2019-03-04T14:00:00.000+0000",
    //    "slot":"08:00-09:00"
    // },
    // {
    //    "date":"2019-03-14",
    //    "startTime":"08:30:00",
    //    "endTime":"09:30:00",
    //    "cutOff":"2019-03-04T14:00:00.000+0000",
    //    "slot":"08:30-09:30"
    // },
    // {
    //    "date":"2019-03-14",
    //    "startTime":"09:00:00",
    //    "endTime":"10:00:00",
    //    "cutOff":"2019-03-04T14:00:00.000+0000",
    //    "slot":"09:00-10:00"
    // },
    // {
    //    "date":"2019-03-14",
    //    "startTime":"09:30:00",
    //    "endTime":"10:30:00",
    //    "cutOff":"2019-03-04T14:00:00.000+0000",
    //    "slot":"09:30-10:30"
    // },
    {
       "date":"2019-03-14",
       "startTime":"10:00:00",
       "endTime":"11:00:00",
       "cutOff":"2019-03-04T14:00:00.000+0000",
       "slot":"10:00-11:00"
    },
    {
       "date":"2019-03-14",
       "startTime":"10:30:00",
       "endTime":"11:30:00",
       "cutOff":"2019-03-04T14:00:00.000+0000",
       "slot":"10:30-11:30"
    },
    {
       "date":"2019-03-14",
       "startTime":"11:00:00",
       "endTime":"12:00:00",
       "cutOff":"2019-03-04T14:00:00.000+0000",
       "slot":"11:00-12:00"
    },
    {
       "date":"2019-03-14",
       "startTime":"11:30:00",
       "endTime":"12:30:00",
       "cutOff":"2019-03-04T14:00:00.000+0000",
       "slot":"11:30-12:30"
    },
    {
       "date":"2019-03-14",
       "startTime":"12:00:00",
       "endTime":"13:00:00",
       "cutOff":"2019-03-04T14:00:00.000+0000",
       "slot":"12:00-13:00"
    },
    // {
    //    "date":"2019-03-14",
    //    "startTime":"12:30:00",
    //    "endTime":"13:30:00",
    //    "cutOff":"2019-03-04T14:00:00.000+0000",
    //    "slot":"12:30-13:30"
    // },
    // {
    //    "date":"2019-03-14",
    //    "startTime":"13:00:00",
    //    "endTime":"14:00:00",
    //    "cutOff":"2019-03-04T14:00:00.000+0000",
    //    "slot":"13:00-14:00"
    // },
    {
       "date":"2019-03-14",
       "startTime":"15:00:00",
       "endTime":"16:00:00",
       "cutOff":"2019-03-04T22:00:00.000+0000",
       "slot":"15:00-16:00"
    },
    {
       "date":"2019-03-14",
       "startTime":"15:30:00",
       "endTime":"16:30:00",
       "cutOff":"2019-03-04T22:00:00.000+0000",
       "slot":"15:30-16:30"
    },
    // {
    //    "date":"2019-03-14",
    //    "startTime":"16:00:00",
    //    "endTime":"17:00:00",
    //    "cutOff":"2019-03-04T22:00:00.000+0000",
    //    "slot":"16:00-17:00"
    // },
    // {
    //    "date":"2019-03-14",
    //    "startTime":"16:30:00",
    //    "endTime":"17:30:00",
    //    "cutOff":"2019-03-04T22:00:00.000+0000",
    //    "slot":"16:30-17:30"
    // },
    // {
    //    "date":"2019-03-14",
    //    "startTime":"17:00:00",
    //    "endTime":"18:00:00",
    //    "cutOff":"2019-03-04T22:00:00.000+0000",
    //    "slot":"17:00-18:00"
    // },
    // {
    //    "date":"2019-03-14",
    //    "startTime":"17:30:00",
    //    "endTime":"18:30:00",
    //    "cutOff":"2019-03-04T22:00:00.000+0000",
    //    "slot":"17:30-18:30"
    // },
    {
       "date":"2019-03-14",
       "startTime":"18:00:00",
       "endTime":"19:00:00",
       "cutOff":"2019-03-04T22:00:00.000+0000",
       "slot":"18:00-19:00"
    },
    {
       "date":"2019-03-14",
       "startTime":"18:30:00",
       "endTime":"19:30:00",
       "cutOff":"2019-03-04T22:00:00.000+0000",
       "slot":"18:30-19:30"
    },
    {
       "date":"2019-03-14",
       "startTime":"19:00:00",
       "endTime":"20:00:00",
       "cutOff":"2019-03-04T22:00:00.000+0000",
       "slot":"19:00-20:00"
    },
    {
       "date":"2019-03-14",
       "startTime":"19:30:00",
       "endTime":"20:30:00",
       "cutOff":"2019-03-04T22:00:00.000+0000",
       "slot":"19:30-20:30"
    },
    // {
    //    "date":"2019-03-14",
    //    "startTime":"20:00:00",
    //    "endTime":"21:00:00",
    //    "cutOff":"2019-03-04T22:00:00.000+0000",
    //    "slot":"20:00-21:00"
    // },
    // {
    //    "date":"2019-03-14",
    //    "startTime":"20:30:00",
    //    "endTime":"21:30:00",
    //    "cutOff":"2019-03-04T22:00:00.000+0000",
    //    "slot":"20:30-21:30"
    // },
    {
       "date":"2019-03-14",
       "startTime":"21:00:00",
       "endTime":"22:00:00",
       "cutOff":"2019-03-04T22:00:00.000+0000",
       "slot":"21:00-22:00"
    },
    {
       "date":"2019-03-14",
       "startTime":"21:30:00",
       "endTime":"22:30:00",
       "cutOff":"2019-03-04T22:00:00.000+0000",
       "slot":"21:30-22:30"
    },
    {
       "date":"2019-03-14",
       "startTime":"22:00:00",
       "endTime":"23:00:00",
       "cutOff":"2019-03-04T22:00:00.000+0000",
       "slot":"22:00-23:00"
    }
 ];

// export const slots = [

//             {
//                 'date': '12-12-2019',
//                 'start': '06:00',
//                 'end': '06:30',
//                 'cutOff': '',
//                 'slot': '06:00 - 06:30',
//                 'available': false,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '06:30',
//                 'end': '07:00',
//                 'cutOff': '',
//                 'slot': '06:30 - 07:00',
//                 'available': false,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '07:00',
//                 'end': '07:30',
//                 'cutOff': '',
//                 'slot': '07:00 - 07:30',
//                 'available': true,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '07:30',
//                 'end': '08:00',
//                 'cutOff': '',
//                 'slot': '07:30 - 08:00',
//                 'available': true,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '08:00',
//                 'end': '08:30',
//                 'cutOff': '',
//                 'slot': '08:00 - 08:30',
//                 'available': true,
//                 'isLast': true,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '08:30',
//                 'end': '09:00',
//                 'cutOff': '',
//                 'slot': '08:30 - 09:00',
//                 'available': false,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '09:00',
//                 'end': '09:30',
//                 'cutOff': '',
//                 'slot': '09:00 - 09:30',
//                 'available': false,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '09:30',
//                 'end': '10:00',
//                 'cutOff': '',
//                 'slot': '09:30 - 10:00',
//                 'available': false,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '10:00',
//                 'end': '10:30',
//                 'cutOff': '',
//                 'slot': '10:00 - 10:30',
//                 'available': false,
//                 'isLast': true,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '10:30',
//                 'end': '11:00',
//                 'cutOff': '',
//                 'slot': '10:30 - 11:00',
//                 'available': true,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '11:00',
//                 'end': '11:30',
//                 'cutOff': '',
//                 'slot': '11:00 - 11:30',
//                 'available': true,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '11:30',
//                 'end': '12:00',
//                 'cutOff': '',
//                 'slot': '11:30 - 12:00',
//                 'available': true,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '12:00',
//                 'end': '12:30',
//                 'cutOff': '',
//                 'slot': '12:00 - 12:30',
//                 'available': true,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '12:30',
//                 'end': '13:00',
//                 'cutOff': '',
//                 'slot': '12:30 - 13:00',
//                 'available': true,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '13:00',
//                 'end': '13:30',
//                 'cutOff': '',
//                 'slot': '13:00 - 13:30',
//                 'available': true,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '13:30',
//                 'end': '14:00',
//                 'cutOff': '',
//                 'slot': '13:30 - 14:00',
//                 'available': true,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '14:00',
//                 'end': '14:30',
//                 'cutOff': '',
//                 'slot': '14:00 - 14:30',
//                 'available': true,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '14:30',
//                 'end': '15:00',
//                 'cutOff': '',
//                 'slot': '14:30 - 15:00',
//                 'available': true,
//                 'isLast': true,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '06:00',
//                 'end': '06:30',
//                 'cutOff': '',
//                 'slot': '15:00 - 15:30',
//                 'available': true,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '06:30',
//                 'end': '07:00',
//                 'cutOff': '',
//                 'slot': '15:30 - 16:00',
//                 'available': true,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '07:00',
//                 'end': '07:30',
//                 'cutOff': '',
//                 'slot': '16:00 - 16:30',
//                 'available': true,
//                 'isLast': true,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '07:30',
//                 'end': '08:00',
//                 'cutOff': '',
//                 'slot': '16:30 - 17:00',
//                 'available': false,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '08:00',
//                 'end': '08:30',
//                 'cutOff': '',
//                 'slot': '08:00 - 08:30',
//                 'available': false,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '08:30',
//                 'end': '09:00',
//                 'cutOff': '',
//                 'slot': '08:30 - 09:00',
//                 'available': false,
//                 'isLast': true,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '09:00',
//                 'end': '09:30',
//                 'cutOff': '',
//                 'slot': '09:00 - 09:30',
//                 'available': true,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '09:30',
//                 'end': '10:00',
//                 'cutOff': '',
//                 'slot': '09:30 - 10:00',
//                 'available': true,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '10:00',
//                 'end': '10:30',
//                 'cutOff': '',
//                 'slot': '10:00 - 10:30',
//                 'available': true,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '10:30',
//                 'end': '11:00',
//                 'cutOff': '',
//                 'slot': '10:30 - 11:00',
//                 'available': true,
//                 'isLast': true,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '11:00',
//                 'end': '11:30',
//                 'cutOff': '',
//                 'slot': '11:00 - 11:30',
//                 'available': false,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '11:30',
//                 'end': '12:00',
//                 'cutOff': '',
//                 'slot': '11:30 - 12:00',
//                 'available': false,
//                 'isLast': true,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '12:00',
//                 'end': '12:30',
//                 'cutOff': '',
//                 'slot': '12:00 - 12:30',
//                 'available': true,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '12:30',
//                 'end': '13:00',
//                 'cutOff': '',
//                 'slot': '12:30 - 13:00',
//                 'available': true,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '13:00',
//                 'end': '13:30',
//                 'cutOff': '',
//                 'slot': '13:00 - 13:30',
//                 'available': true,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '13:30',
//                 'end': '14:00',
//                 'cutOff': '',
//                 'slot': '13:30 - 14:00',
//                 'available': true,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '14:00',
//                 'end': '14:30',
//                 'cutOff': '',
//                 'slot': '14:00 - 14:30',
//                 'available': true,
//                 'isLast': false,
//                 'freeDelivery': false
//             },
//             {
//                 'date': '12-12-2019',
//                 'start': '14:30',
//                 'end': '15:00',
//                 'cutOff': '',
//                 'slot': '14:30 - 15:00',
//                 'available': true,
//                 'isLast': true,
//                 'freeDelivery': false
//             },

//         ];



/*
{"userToken":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjdXN0b21lclJlZiI6Ijc4MzY5OCIsImZpcnN0TmFtZSI6IkphcmVrIiwibGFzdE5hbWUiOiJLcnVwYSIsInBob25lTnVtYmVyIjoiNTEyMzIwNDk5Iiwic3RhdHVzIjoiTkVXIiwiZXhwIjoxNTUxOTU3ODAzfQ.StJM6yx9lSUTPIu6sAJQHa_qsgm1M9hISswL0VtGWgs","locationList":[{"additional":{"contactName":"Jarek Krupa","contactPhoneNO":"512320499","flatNO":"32"},"address":{"country":"Poland","houseNO":"12","street":"Grażyny","town":"Warszawa (Mokotów)","zip":"02-548"},"coordinates":{"lat":52.24217,"lng":21.09527},"label":"Jarek Krupa","locationRef":"783698/001"}],"selectedLocation":{"additional":{"contactName":"Jarek Krupa","contactPhoneNO":"512320499","flatNO":"32"},"address":{"country":"Poland","houseNO":"12","street":"Grażyny","town":"Warszawa (Mokotów)","zip":"02-548"},"coordinates":{"lat":52.24217,"lng":21.09527},"label":"Jarek Krupa","locationRef":"783698/001"},"basketAmount":0,"lang":"pl-PL","mode":"NEW"}
*/