import { ISmartBooking } from '../../interfaces/ISmartBooking.interface';
import { Segment } from '../../enums/segment.enum';

export const SLIDER_ID_LEFT = 'SLIDER_ID_LEFT';
export const SLIDER_ID_RIGHT = 'SLIDER_ID_RIGHT';

export const SEGMENT_ID = 'SEGMENT_ID';

export const data: ISmartBooking[] = [
    {
        slot: '08:00 - 09:00',
        segment: [
            {
                name: Segment.VIP,
                percent: 40,
                enabled: true
            },
            {
                name: Segment.OK,
                percent: 20,
                enabled: true
            },
            {
                name: Segment.LOW,
                percent: 30,
                enabled: true
            },
            {
                name: Segment.NEW,
                percent: 10,
                enabled: true
            }
        ]
    },
    {
        slot: '09:00 - 10:00',
        segment: [
            {
                name: Segment.VIP,
                percent: 20,
                enabled: true
            },
            {
                name: Segment.OK,
                percent: 10,
                enabled: true
            },
            {
                name: Segment.LOW,
                percent: 50,
                enabled: true
            },
            {
                name: Segment.NEW,
                percent: 20,
                enabled: true
            }
        ]
    },
    {
        slot: '10:00 - 11:00',
        segment: [
            {
                name: Segment.VIP,
                percent: 80,
                enabled: true
            },
            {
                name: Segment.OK,
                percent: 10,
                enabled: true
            },
            {
                name: Segment.LOW,
                percent: 10,
                enabled: true
            },
            {
                name: Segment.NEW,
                percent: 10,
                enabled: true
            }
        ]
    },
    {
        slot: '11:00 - 12:00',
        segment: [
            {
                name: Segment.VIP,
                percent: 20,
                enabled: true
            },
            {
                name: Segment.OK,
                percent: 40,
                enabled: true
            },
            {
                name: Segment.LOW,
                percent: 30,
                enabled: true
            },
            {
                name: Segment.NEW,
                percent: 10,
                enabled: true
            }
        ]
    },
    {
        slot: '12:00 - 13:00',
        segment: [
            {
                name: Segment.VIP,
                percent: 28,
                enabled: true
            },
            {
                name: Segment.OK,
                percent: 12,
                enabled: true
            },
            {
                name: Segment.LOW,
                percent: 40,
                enabled: true
            },
            {
                name: Segment.NEW,
                percent: 20,
                enabled: true
            }
        ]
    },
    {
        slot: '13:00 - 14:00',
        segment: [
            {
                name: Segment.VIP,
                percent: 28,
                enabled: true
            },
            {
                name: Segment.OK,
                percent: 12,
                enabled: true
            },
            {
                name: Segment.LOW,
                percent: 40,
                enabled: true
            },
            {
                name: Segment.NEW,
                percent: 20,
                enabled: true
            }
        ]
    }
]
