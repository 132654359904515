export const EN = {
	"Add another address": "Add another address",
	"For free delivery is missing": "For free delivery is missing",
	"Loading available slots": "Loading available slots",
	"Occurred error with fetching available slots": "Occurred error with fetching available slots",
	"Select delivery": "Book this slot",
	"Something has wrong! Please try again": "Something has wrong! Please try again",
	"The reservation will expire on": "The reservation will expire on",
	"Today": "Today",
	"Tomorrow": "Tomorrow",
	"Your delivery": "Your delivery"
}