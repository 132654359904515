
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { PL } from '../../assets/i18n/pl-PL';
import { EN } from '../../assets/i18n/en-GB';
import { Lang } from '../entities/enums/lang';

@Injectable()
export class TranslateLoaderService implements TranslateLoader {

    constructor(private http: HttpClient) {}

    getTranslation(lang: string): Observable<any> {

        return Observable.create(observer => {

            switch (lang) {
                case Lang.PL :
                    observer.next(PL);
                    observer.complete();
                return;
                case Lang.EN :
                    observer.next(EN);
                    observer.complete();
                return;
                default :
                    observer.next(PL);
                    observer.complete();
                return;
            }

        });

    }
}
