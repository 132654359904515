import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    moduleId: 'address',
    template: `
    <div class='container-fluid'>
        <div class='row'>
            <div class='col-xs-12'>
                <div class='main-content'>
                    <router-outlet name='content'></router-outlet>
                </div>
            </div>
        </div>
    </div>
    `
})
export class AddressComponent implements OnInit {

    public lang = 'pl-PL';

    constructor(private translate: TranslateService, private activatedRoute: ActivatedRoute) {}

    ngOnInit() {
    }

    onTranslationChange() {
        this.translate.use(this.lang);
    }
}
