import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AddressModule } from './../../modules/address.module';
import { HttpModule } from '@angular/http';
import { ReactiveFormsModule } from '@angular/forms';
import { LottieAnimationViewModule } from 'ng-lottie';
import { Routing } from './../../app.routing';
import { PickerRouting } from './picker.routing';
import { MainComponent } from './main/main.component';
import { PickerComponent } from './picker/picker.component';
import { StepComponent } from './step/step.component';
import { SliderComponent } from './slider/slider.component';
import { EcoComponent } from './eco/eco.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { SettingsComponent } from './settings/settings.component';
import { OverlayComponent } from './overlay/overlay.component';
import { ParseTimePipe } from './../../helpers/pipes/time.pipe';
import { PickerService } from './services/picker.service';
import { MomentModule } from 'ngx-moment';
import { TranslationModule } from './../../modules/translate.module';


const importPackages = [
  BrowserModule,
  TranslationModule,
  AddressModule,
  HttpModule,
  MomentModule,
  ReactiveFormsModule,
  LottieAnimationViewModule.forRoot()
];

const routings = [
  Routing,
  PickerRouting
];

const components = [
  MainComponent,
  PickerComponent,
  StepComponent,
  SliderComponent,
  DatepickerComponent,
  EcoComponent,
  ParseTimePipe,
  OverlayComponent,
  SettingsComponent
];

const providers = [
  PickerService
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    ...importPackages,
    ...routings
  ],
  providers: [
    ...providers
  ],
  exports: [
    ...components,
    ...routings,
    MomentModule
  ],
  bootstrap: [MainComponent]
})
export class PickerModule { }
