import { Entity } from '../helpers/vo/entity';
import { Serializable } from '../helpers/vo/serializable';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as _ from 'lodash';

export default class Customer extends Entity implements Serializable<Customer> {

    customerId: string;
    customerRef: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    status: string;
    jwt: string;

    deserialize(data: any): Customer {

        this.customerId = _.get(data, 'customerId');
        this.customerRef = _.get(data, 'customerRef');
        this.firstName = _.get(data, 'firstName');
        this.lastName = _.get(data, 'lastName');
        this.phoneNumber = _.get(data, 'phoneNumber');
        this.status = _.get(data, 'status');

        return this;
    }

    serialize() {
        const serialized = {
            customerId: this.customerId,
            customerRef: this.customerRef,
            firstName: this.firstName,
            lastName: this.lastName,
            phoneNumber: this.phoneNumber,
            status: this.status
        };
        return serialized;
    }

    parse(jwt: string): Customer {

        if (_.isNull(jwt)) {
            throw 'Invalid jwt token';
        }

        this.jwt = jwt;

        const helper = new JwtHelperService();
        const decoded = helper.decodeToken(jwt);

        this.customerId = decoded.customerId;
        this.customerRef = decoded.customerRef;
        this.firstName = decoded.firstName;
        this.lastName = decoded.lastName;
        this.phoneNumber = decoded.phoneNumber;
        this.status = decoded.status;

        return this;
    }

    getJwt() {
        return this.jwt;
    }
}
