import { Directive, HostListener } from '@angular/core';
import { SlotSelectionService } from '../services/slot-selection.service';

@Directive({
  selector: '[appShiftListener]'
})
export class ShiftListenerDirective {

  constructor(
    private readonly slotSelectionService: SlotSelectionService,
  ) { }

  @HostListener('mouseleave')
  onMouseleave() {
    console.log('asdas');
    this.slotSelectionService.sliderPositionTop$.next(-1);
  }
}
