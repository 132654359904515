import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'colorPipe'
})
export class ColorPipe implements PipeTransform {

  transform(day: string, hour: number): any {
    console.log(day, hour);


    switch (day) {
      case 'Mon':
        if ([7, 8, 9].includes(hour)) {
          return `c${this.randomInt(7, 10)}`;
        }
        if ([17, 18, 19].includes(hour)) {
          return `c${this.randomInt(7, 10)}`;
        }
        break;

      case 'Tue':
        if ([7, 8, 9].includes(hour)) {
          return `c${this.randomInt(7, 10)}`;
        }
        if ([17, 18, 19].includes(hour)) {
          return `c${this.randomInt(7, 10)}`;
        }
        break;

      case 'Fri':
        if ([17, 18, 19, 20, 21].includes(hour)) {
          return `c${this.randomInt(7, 10)}`;
        }
        break;

      case 'Sat':
        if ([7, 8, 9, 10, 11, 12].includes(hour)) {
          return `c${this.randomInt(7, 10)}`;
        }
        if ([15, 16, 17].includes(hour)) {
          return `c${this.randomInt(7, 10)}`;
        }
        break;

      case 'Sun':
        if ([9, 10, 11, 12, 13].includes(hour)) {
          return `c${this.randomInt(7, 10)}`;
        }
        break;

      default:
        break;
    }


    return `c${this.randomInt(1, 6)}`;
  }

  randomInt(min, max) {
    return min + Math.floor((max - min) * Math.random());
  }

}
