import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector } from '@angular/core';
import { AppComponent } from '../app.component';
import { AddressModule } from './address.module';
import { TranslationModule } from './translate.module';
import { Routing } from '../app.routing';
import { AddressRouting } from '../components/choose-address/address.routing';
import { PickerModule } from '../components/choose-slot/picker.module';
import { PickerRouting } from '../components/choose-slot/picker.routing';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { createCustomElement } from '@angular/elements';
import { APP_BASE_HREF } from '@angular/common';
import { AppService } from '../services/app.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../services/auth.interceptor';
import { LottieAnimationViewModule } from 'ng-lottie';
import { AuthModule } from '../auth/auth.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { LayoutComponent } from '../components/layout/layout.component';
import { SlotSelectionModule } from '../slot-selection/slot-selection.module';
import { ReservationsWizardService } from '../shared/services/reservation-wizard.service';
import { AuthGuardService } from '../services/auth-guard.service';
import { DemoBookingModule } from '../demo-booking/demo-booking.module';
// import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

/**
 * Firebase Config
 */
const firebaseConfig = {
  apiKey: 'AIzaSyBEFo9mgE-dQQHs7bP7igXubZEscO_mp1s',
  authDomain: 'slotpickerdemo.firebaseapp.com',
  databaseURL: 'https://slotpickerdemo.firebaseio.com',
  projectId: 'slotpickerdemo',
  storageBucket: 'slotpickerdemo.appspot.com',
  messagingSenderId: '758077752221',
  appId: '1:758077752221:web:9873d1a348fcc36734a574',
  measurementId: 'G-9611MXPSGG'
};


const importPackages = [
  BrowserModule,
  TranslationModule,
  AddressModule,
  PickerModule,
  BrowserAnimationsModule,
  SlotSelectionModule,
  DemoBookingModule
];

const routings = [
  Routing,
  AddressRouting,
  PickerRouting
];

const components = [
  AppComponent,
  LayoutComponent
];

const providers = [
  AppService,
  ReservationsWizardService,
  AuthGuardService
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    ...importPackages,
    ...routings,
    AuthModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireAuthModule
  ],
  providers: [
    ...providers,
    {provide: APP_BASE_HREF, useValue : '/' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  exports: [BrowserAnimationsModule],
  bootstrap: [AppComponent],
  // entryComponents: [
    
  // ]
})
export class AppModule {
  //constructor(private injector: Injector) { }

  // ngDoBootstrap() {
  //   const el = createCustomElement(AppComponent, { injector: this.injector });
  //   customElements.define('or-slotpicker', el);
  // }
}
