import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { AppService } from './../../../services/app.service';
import { SlotSelectionService } from '../../services/slot-selection.service';

@Component({
  selector: 'app-date-selection',
  templateUrl: './date-selection.component.html',
  styleUrls: ['./date-selection.component.scss']
})
export class DateSelectionComponent implements OnInit {

  @Output() selectedDayChange = new EventEmitter();

  locale;

  constructor(
      private appService: AppService,
      private readonly slotSelectionService: SlotSelectionService
    ) {
     this.locale = moment.locale('en');
  }

  week = [];

  today = moment(new Date());
  selectedDay = moment(new Date()).add(1, 'days').format('YYYY-MM-DD');

  ngOnInit() {
        this.getWeekDay();
        this.selectedDayChange.emit(this.selectedDay);
  }

  getWeekDay() {
      const today = _.cloneDeep(this.today);
      for (let i = 0; i < 5; i++) {
          this.week.push(moment(today, 'YYYY-MM-DD').add(i, 'days').format('YYYY-MM-DD'));
      }
  }

  isActiveDay(day): boolean {
      return (day === this.selectedDay && !this.isPast(day) && this.isCurrentMonth(day)) ? true : false;
  }

  changeDate(day, active): void {
      if (active && this.isCurrentMonth(day)) {
          this.selectedDay = day;
          this.selectedDayChange.emit(day);
      }
  }

  isPast(day): boolean {
      return (moment(day).isAfter(moment(this.today).format('YYYY-MM-DD')) || !this.isCurrentDay(day)) ? false : true;
  }

  todayLabel(day): boolean {
      return (day === moment(this.today).format('YYYY-MM-DD')) ? true : false;
  }

  tomorrowLabel(day): boolean {
      const today = _.cloneDeep(this.today);
      today.add(1, 'days');
      return (day === moment(today).format('YYYY-MM-DD')) ? true : false;
  }

  nextWeek(): void {
      const lastDayInCurrentWeek = this.week[4];
      for (let i = 1; i < 6; i++) {
          this.week.push(moment(lastDayInCurrentWeek).add(i, 'days').format('YYYY-MM-DD'));
      }
      this.week.splice(0, 5);
  }

  previousWeek(): void {
      const firstDayInCurrentWeek = this.week[0];
      for (let i = 1; i < 6; i++) {
          this.week.unshift(moment(firstDayInCurrentWeek).add(-i, 'days').format('YYYY-MM-DD'));
      }
      this.week.splice(5, 10);
  }

  isCurrentMonth(day): boolean {
      return moment(day).isBefore(moment(new Date()).add(30, 'days'));
  }

  isCurrentDay(day): boolean {
      return day === this.today.format('YYYY-MM-DD');
  }

}
