import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sliderTimeFormatPipe'
})
export class SliderTimeFormatPipe implements PipeTransform {

  transform(time: string): any {
    const result = time ? time.slice(0, 5) : '';
    return (result.substring(0, 1) === '0') ? result.slice(1, 5) : result;
  }

}
