import { Component, Input, OnInit, HostListener, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { ShiftType } from '../entities/interfaces';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ChangeSlotState, BounceState, ShowState, ShowEcoSlotState, ValueState } from '../animations';
import { SlotTimeState } from '../entities/animations.enum';
import { environment as env } from '../../../../environments/environment';
import { SlotsConfig } from '../slots.config';
import { AppService } from '../../../services/app.service';
import Step from '../entities/step';
import * as _ from 'lodash';
import { lineAnimations } from './line-animations';
import Reservation from '../../../entities/reservation';
import * as moment from 'moment';

@Component({
    selector: 'eco-zone-component',
    templateUrl: './eco.component.html',
    styleUrls: ['./eco.component.scss'],
    animations: [ChangeSlotState, BounceState, ShowState, ShowEcoSlotState, ValueState]
})
export class EcoComponent implements OnInit, OnChanges, OnDestroy {

    private TAG = '[EcoComponent]';

    private _data = new BehaviorSubject<Step>(null);

    public showEcoSlots = false;

    private slot: Step | null = null;

    public zoneUpMiddle: number | null;
    public zoneDownMiddle: number | null;
    public zoneDownHeight = 0;

    public zoneUpHeight: number;
    public zoneUpTop = 0;

    public upActive = false;
    public downActive = false;
    public slotTime = '';
    public slotTimeState: SlotTimeState;
    private dataSubscription: Subscription;

    public url = env.pathImageURLS;

    @Input() private reset;
    @Input() shiftType: ShiftType;
    @Input() allSlots: Step[];
    @Input() maskPosition;
    @Input() sliderWidth;
    @Input() selectedDate;

    @Output() extendSlotEvent: EventEmitter<number> = new EventEmitter<number>();

    public extendedSlot;
    public startCircleTop = false;
    public startCircleBottom = false;

    public lottieConfig: Object;
    private anim: any;
    private animationSpeed: number = 1;

    private reservationDataSubscription: Subscription;
    private currentReservation: Reservation;

    @Input()
    set reservedSlotByUser(value) {
        this._data.next(value);
    }

    get reservedSlotByUser() {
        return this._data.getValue();
    }

    constructor(private appService: AppService) {
    }

    ngOnInit() {

        this.lottieConfig = {
            animationData: lineAnimations,
            autoplay: true,
            loop: true,
            renderer: 'svg',
        };


        this.dataSubscription = this._data
            .subscribe(slot => {
               
                if (!_.isNull(slot)) {
                    this.slot = slot;
                    this.calcEcoEdges();
                    this.showEcoSlots = (this.shiftType === this.slot.shiftType) ? true : false;
                } else {
                    this.showEcoSlots = false;
                    this.zoneUpHeight = this.zoneDownHeight = this.zoneUpMiddle = 0;
                    this.downActive = this.upActive = false;
                }
            });

        this.reservationDataSubscription = this.appService.reservationData.subscribe(
            (reservation) => {
                if (!_.isNull(reservation) && !_.isNull(reservation.slot) && reservation.slot.date === this.selectedDate) {
                    console.log(`${this.TAG} Detected changes in reservation `, reservation);

                    this.currentReservation = reservation;
                    /*
                     *  If 
                     */
                    if (_.isNull(this.slot)) {
                        this.initialize();
                    }
                } else {
                    this.showEcoSlots = false;
                    this.zoneUpHeight = this.zoneDownHeight = this.zoneUpMiddle = 0;
                    this.downActive = this.upActive = false;
                }

            },
            (err) => {
                console.log(`${this.TAG}`, err);
            }
        );

    }

    handleAnimation(anim: any) {
        this.anim = anim;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['reset']) {
            this._data.next(null);
        }
    }

    @HostListener('mousemove', ['$event.offsetY'])
    mouseMove(offsetY) {

        const classList = event.srcElement.classList.value;

        if (classList.includes('overlay')) {

            const isTopSection = (offsetY < this.zoneDownMiddle);

            if (isTopSection && this.upActive) {

                const diff = 480 - this.zoneUpMiddle - offsetY;
                const height = Math.ceil(diff / 30) * 30;
                this.zoneUpHeight = height <= 60 ? 60 : height;

                (this.zoneUpHeight <= 60)
                    ? this.extendedSlot = Math.floor(this.zoneDownMiddle / SlotsConfig.slotHeight) - 4
                    : this.extendedSlot = Math.floor(offsetY / SlotsConfig.slotHeight);

            } else if (!isTopSection && this.downActive) {

                const diff = Math.abs(offsetY - this.zoneDownMiddle);
                const height = Math.round(diff / 30) * 30 + 30;

                if (offsetY <= 450) {
                    this.zoneDownHeight = (height <= 60) ? 60 : height;

                    (this.zoneDownHeight <= 60)
                        ? this.extendedSlot = Math.round(this.zoneDownMiddle / SlotsConfig.slotHeight) + 1
                        : this.extendedSlot = Math.ceil((offsetY - 15) / SlotsConfig.slotHeight);

                }
            }
        }
    }

    activateEcoZones(event, direction) {
        if (direction === 'TOP') {
            this.upActive = true;
            this.zoneUpHeight = 60;
        } else if (direction === 'DOWN') {
            this.downActive = true;
            this.zoneDownHeight = 60;
        }
    }

    @HostListener('mouseleave', [])
    mouseleave() {

        if (this.upActive) {
            this.upActive = false;
            this.zoneUpHeight = 0;
            this.extendedSlot = -1;
        } else if (this.downActive) {
            this.downActive = false;
            this.zoneDownHeight = 0;
            this.extendedSlot = -1;
        }

    }

    @HostListener('click', ['$event.offsetY', '$event.srcElement'])
    closeClick(offsetY, srcElement) {

        const isOutside = !_.isUndefined(srcElement.classList) && !srcElement.classList.value.includes('remove-btn');
        const isTopSection = (offsetY < this.zoneDownMiddle);


        if (isOutside){
            this.slotString(this.stepNumber(offsetY, isTopSection));
        }


        

        if (isTopSection && isOutside && this.upActive) {
            console.log('startTime', this.slotTime);
            this.updateReservationModel(this.slotTime, 'startTime');
            this.upActive = false;
            this.extendedSlot = -1;

            setTimeout(() => { this.startCircleTop = true; }, 100);
            setTimeout(() => { this.startCircleTop = false; }, 800);


        } else if (!isTopSection && isOutside && this.downActive) {
            console.log('endTime', this.slotTime);
            this.updateReservationModel(this.slotTime, 'endTime');
            this.downActive = false;
            this.extendedSlot = -1;

            setTimeout(() => { this.startCircleBottom = true; }, 100);
            setTimeout(() => { this.startCircleBottom = false; }, 800);
        }

    }

    extendSlot(direction) {

        console.log(direction)
        if (direction === 'up') {
            console.log('startTime', this.slot.startTime);
            this.updateReservationModel(this.slot.startTime, 'startTime');
            this.upActive = false;

            setTimeout(() => { this.startCircleTop = true; }, 100);
            setTimeout(() => { this.startCircleTop = false; }, 800);


        } else if (direction === 'down') {
            console.log('endTime', this.slotTime);
            this.updateReservationModel(this.slot.endTime, 'endTime');
            this.downActive = false;

            setTimeout(() => { this.startCircleBottom = true; }, 100);
            setTimeout(() => { this.startCircleBottom = false; }, 800);
        }
    }

    calcEcoEdges() {
        this.zoneUpMiddle = (SlotsConfig.slotHeight * SlotsConfig.slotsInShift) - (this.slot.index * SlotsConfig.slotHeight);
        this.zoneDownMiddle = (this.slot.index * SlotsConfig.slotHeight) + 60;
    }

    stepNumber(offsetY, topDirection = true): number {
        return (topDirection) 
            ? Math.floor((offsetY) / SlotsConfig.slotHeight)
            : Math.round((offsetY) / SlotsConfig.slotHeight);
    }

    private updateReservationModel(time: string, direction: string): void {
        this.appService.strechSlot(time, direction);
    }

    slotString(stepNumber): void {

        if (this.slotTime !== this.allSlots[stepNumber].startTime) {
            this.slotTime = this.allSlots[stepNumber].startTime;
        }
    }

    get lessPollutionValue() {
        const sum = this.zoneDownHeight + this.zoneUpHeight;

        if (sum <= 120) {
            return '20';
        } else if (sum < 240) {
            return '30';
        } else if (sum < 300) {
            return '40';
        } else if (sum >= 300) {
            return '50';
        }

        //return (((this.zoneDownHeight + this.zoneUpHeight) * 100 / 420) / 2).toFixed(0);
    }

    private initialize(): void {

        console.log(`${this.TAG} Initialize eco slot from current user reservation`);

        const slot = this.currentReservation.slot;
        const top = SlotsConfig.defaultSlots.findIndex((s) => s === `${slot.slot.split('-')[0]}:00`);

        const shift = (top < 16) ? ShiftType.MORNING : ShiftType.AFTERNOON;
        const index = top % 16;
        this.slot = new Step().deserialize(this.currentReservation.slot, index, shift);

        this.calcEcoEdges();

        //console.log('shiftType: ', this.shiftType);

        this.showEcoSlots = (this.shiftType === this.slot.shiftType) ? true : false;

        // this.showEcoSlots = true;

       

        const start = moment(`${slot.date}T${slot.startTime}`);
        const end = moment(`${slot.date}T${slot.endTime}`);
        const mainSlotStart = moment(`${slot.date}T${slot.mainSlotStart}`);
        const mainSlotEnd = moment(`${slot.date}T${slot.mainSlotEnd}`);

        //console.log('dsadasdasdasd: ', mainSlotStart.diff(start, 'minutes')) // 44700
        //console.log('dsadasdasdasd: ', end.diff(mainSlotEnd, 'minutes')) // 44700

        this.zoneDownHeight = end.diff(mainSlotEnd, 'minutes');
        this.zoneUpHeight = mainSlotStart.diff(start, 'minutes');

        this.upActive = false;
        this.downActive = false;

        //this.slot = new Step().deserialize(this.slot, null, this.slot.shiftType);
        //console.log(this.slot)
        this.reservedSlotByUser = this.slot;
        // this.reservedSlotByUser = 
        //console.log(this.reservedSlotByUser)
        

    }

    get showBottomZone(){
        //!upActive && zoneUpHeight >= 60
        if (!this.upActive && this.zoneUpHeight >= 60){
            return false;
        }else if(!this.downActive && this.zoneDownHeight >= 60){
            return true;
        }
    }

    removeEcoZone(area: string) {
        if (area === 'up') {
            this.updateReservationModel(this.currentReservation.slot.mainSlotStart, 'startTime');
            this.zoneUpHeight = 0;
            this.upActive = true;
            this.extendedSlot = -1;
        } else {
            this.updateReservationModel(this.currentReservation.slot.mainSlotStart, 'endTime');
            this.zoneDownHeight = 0;
            this.downActive = true;
            this.extendedSlot = -1;
        }
    }

    ngOnDestroy() {
        console.log(`${this.TAG} OnDestoy`);
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }

        if (this.reservationDataSubscription) {
            this.reservationDataSubscription.unsubscribe();
        }
    }
}
