import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ShowStateEcoSlots, ShowState } from '../../../../slot-selection/animations/state.animation';
import { ValueState } from '../../../../slot-selection/animations/slider.animation';
import { ReservationsWizardService } from '../../../../shared/services/reservation-wizard.service';
import { ReservationInterface } from '../../../../shared/interfaces/reservation-interface';
import { take } from 'rxjs/internal/operators/take';
import { tap } from 'rxjs/internal/operators/tap';
import { filter } from 'rxjs/internal/operators/filter';
import { SlotInterface } from '../../../../shared/interfaces/slot-interface';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { SlotSelectionService } from '../../../../slot-selection/services/slot-selection.service';
import { WidgetMode } from '../../../../shared/enums/widget-mode.enum';

@Component({
  selector: 'app-eco-zone',
  templateUrl: './eco-zone.component.html',
  styleUrls: ['./eco-zone.component.scss'],
  animations: [ShowStateEcoSlots, ValueState, ShowState]
})
export class EcoZoneComponent implements OnInit, OnDestroy {

  @Input() mousePosition;

  currentSliderPosition;
  mouseLeaveEcoSlots = false;

  newSlotBottomFlag = false;
  newSlotTopFlag = false;
  newSlotSpinnerFlag = false;

  wizardServiceSubscription$: Subscription;
  slotSelectionServiceSubscription$: Subscription;

  ecoZoneBottomHeightValue = 0;
  ecoZoneTopHeightValue = 0;

  removeEcoZoneFlag = false;

  reservation: ReservationInterface;

  topHeightIfReserved = 0;
  bottomHeightIfReserved = 0;

  get ecoZoneTopHeight(): number {

    if (this.reservation.mode === WidgetMode.PRE_BOOKED || this.reservation.mode === WidgetMode.RESERVED) {
      return (this.newSlotTopFlag) ? this.topHeightIfReserved : 0;
    }

    if (this.newSlotTopFlag) {
      return this.ecoZoneTopHeightValue;
    }

    if (this.mouseLeaveEcoSlots) {
      return 0;
    }

    const result = this.currentSliderPosition - this.mousePosition + 0;
    this.ecoZoneTopHeightValue = (this.mousePosition < this.currentSliderPosition) ? result : 0;
    return this.mousePosition !== -1 ? this.ecoZoneTopHeightValue : 0;
  }

  get ecoZoneBottomHeight(): number {

    if (this.reservation.mode === WidgetMode.PRE_BOOKED || this.reservation.mode === WidgetMode.RESERVED) {
      return (this.newSlotBottomFlag) ? this.bottomHeightIfReserved : 0;
    }

    if (this.newSlotBottomFlag) {
      return this.ecoZoneBottomHeightValue;
    }

    if (this.mouseLeaveEcoSlots) {
      return 0;
    }

    const result = this.mousePosition - this.currentSliderPosition - 29;
    this.ecoZoneBottomHeightValue = (this.currentSliderPosition < this.mousePosition) ? result : 0;
    return this.mousePosition !== -1 ? this.ecoZoneBottomHeightValue : 0;
  }

  get lessPollutionValue() {
    const pollution = this.ecoZoneBottomHeight + this.ecoZoneTopHeight;
    if (pollution <= 60) {
      return '20';
    } else if (pollution < 100) {
        return '30';
    } else if (pollution < 140) {
        return '35';
    } else if (pollution < 200) {
        return '40';
    } else if (pollution >= 200) {
      return '50';
    }
  }

  constructor(
    private readonly wizardService: ReservationsWizardService,
    private readonly slotSelectionService: SlotSelectionService

  ) { }

  ngOnInit() {
    this.currentSliderPosition = this.mousePosition;

    this.wizardServiceSubscription$ = this.wizardService.getModel().pipe(
      filter((reservation: ReservationInterface) => reservation !== undefined),
      tap((reservation: ReservationInterface) => {

        if (this.reservation === undefined || this.reservation.slot === undefined) {
          this.reservation = reservation;
        } else {
          if (this.reservation.slot.startTime !== reservation.slot.startTime &&
              !reservation.slot.slot.includes(reservation.slot.startTime.slice(0, 5)) ) {
            this.newSlotTopFlag = true;
            this.newSlotSpinnerFlag = true;
            setTimeout(() => { this.newSlotSpinnerFlag = false;  this.removeEcoZoneFlag = true; }, 500);
          } else if (this.reservation.slot.endTime !== reservation.slot.endTime &&
            !reservation.slot.slot.includes(reservation.slot.endTime.slice(0, 5)) ) {

            this.newSlotBottomFlag = true;
            this.newSlotSpinnerFlag = true;
            setTimeout(() => { this.newSlotSpinnerFlag = false;  this.removeEcoZoneFlag = true; }, 500);
          }
        }

        this.reservation = reservation;

        if (this.reservation.mode === WidgetMode.ECO_SLOTS || this.reservation.mode === WidgetMode.RESERVED || this.reservation.mode === WidgetMode.PRE_BOOKED) {

          const sliderStartTime = moment(`${this.reservation.date} ${this.reservation.slot.slot.slice(0,5)}`);
          const sliderEndTime = moment(`${this.reservation.date} ${this.reservation.slot.slot.slice(6, 11)}`);

          const startTime = moment(`${this.reservation.date} ${this.reservation.slot.startTime}`);
          const endTime = moment(`${this.reservation.date} ${this.reservation.slot.endTime}`);


          if (sliderEndTime.isBefore(endTime)) {
            this.bottomHeightIfReserved = endTime.diff(sliderEndTime, 'minutes');
            this.newSlotBottomFlag = true;
            this.ecoZoneBottomHeightValue = this.bottomHeightIfReserved;
          }

          if (sliderStartTime.isAfter(startTime)) {
            this.topHeightIfReserved = sliderStartTime.diff(startTime, 'minutes');
            this.newSlotTopFlag = true;
            this.ecoZoneTopHeightValue = this.topHeightIfReserved;
          }

          this.currentSliderPosition = this.reservation.sliderCurrentPosition;
          this.mouseLeaveEcoSlots = false;
        }

      })
    ).subscribe();

    this.slotSelectionServiceSubscription$ = this.slotSelectionService.sliderPositionTop$.subscribe(
      (position: number) => {
          this.mouseLeaveEcoSlots = position === -1;
      }
  );
  }

  removeEcoZoneBottom(): void {
    console.log('Model updated: removeEcoZoneBottom');

    this.newSlotBottomFlag = false;
    this.ecoZoneBottomHeightValue = 0;

    this.wizardService.updateModel({
      extendedSlotBottom: false,
      mode: WidgetMode.ECO_SLOTS,
      slot: {
        ...this.reservation.slot,
        endTime: this.reservation.slot.slot.split('-')[1] + ':00'
      } as Partial<SlotInterface>
    } as Partial<ReservationInterface>);
    setTimeout(() => { this.newSlotSpinnerFlag = false; }, 500);
  }

  removeEcoZoneTop(): void {
    console.log('Model updated: removeEcoZoneTop');

    this.newSlotTopFlag = false;
    this.ecoZoneTopHeightValue = 0;
    //this.removeEcoZoneFlag = false;
    this.wizardService.updateModel({
      extendedSlotTop: false,
      mode: WidgetMode.ECO_SLOTS,
      slot: {
        ...this.reservation.slot,
        startTime: this.reservation.slot.slot.split('-')[0] + ':00'
      } as Partial<SlotInterface>
    } as Partial<ReservationInterface>);
    setTimeout(() => { this.newSlotSpinnerFlag = false; }, 500);

  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy: EcoZoneComponent');
    if (this.wizardServiceSubscription$) {
      this.wizardServiceSubscription$.unsubscribe();
    }
    if (this.slotSelectionServiceSubscription$) {
      this.slotSelectionServiceSubscription$.unsubscribe();
    }
  }
}
