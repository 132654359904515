import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';

export const ShowState = trigger('showState', [
     transition(':enter', [
          style({
               opacity: 0
          }),
          animate(500)
     ]),
     transition(':leave', animate(1000, style({
          opacity: 0
     })))
]);



export const ShowStateEcoSlots = trigger('showStateEcoSlots', [
     transition(':enter', [
          style({
               opacity: 0
          }),
          animate('1000ms ease-out', style({
               opacity: 0
           })),
          animate(500)
     ]),
     transition(':leave', animate(250, style({
          opacity: 0
     })))
]);






