import { Component, OnInit, Input, HostListener, SimpleChanges, OnChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ShiftType } from '../entities/interfaces';
import { trigger, transition, state, animate, style } from '@angular/animations';
import { SlideState, VanState, SlotTimeState, MoveDirections } from '../entities/animations.enum';
import { environment as env } from '../../../../environments/environment';

import Shift from '../entities/shift';
import Step from '../entities/step';
import * as _ from 'lodash';
import { AppService } from '../../../services/app.service';
import { ChangeSlotState, ChangeSlotToReservedState, ChangeSlotToActiveState, ShowState, ShiftState } from '../animations';
import { ActivatedRoute } from '@angular/router';
import { SlotsConfig } from '../slots.config';
import { BehaviorSubject, Subscription } from 'rxjs';
import Reservation from '../../../entities/reservation';

@Component({
    selector: 'app-picker-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss'],
    animations: [
        trigger('vanState', [
            state('HIDE', style({
                left: '-50px',
            })),
            state('SHOW', style({
                left: '0'
            })),
            transition('SHOW=>HIDE', animate('0ms cubic-bezier(.53,1.03,.83,.67)')),
            transition('HIDE=>SHOW', animate('150ms ease-in'))
        ]),
        trigger('showState', [
            transition(':enter', [
                style({
                    opacity: 0
                }),
                animate(500)
            ]),
            transition(':leave', animate(500, style({
                opacity: 0
            })))
        ]),
        trigger('sliderTextState', [
            transition(':enter', [
                style({
                    transform: 'translateX(-30px)',
                    opacity: 0
                }),
                animate('150ms ease-in')
            ]),
            transition(':leave', animate(150, style({
                transform: 'translateX(10px)',
                opacity: 0
            })))
        ]),
        trigger('changeSlotTime', [
            state('HIDE', style({
                left: '-30',
            })),
            state('SHOW', style({
                left: '0'
            })),
            transition('SHOW=>HIDE', animate('200ms ease-out')),
            transition('HIDE=>SHOW', animate('10ms'))
        ]),
        ChangeSlotToReservedState, ChangeSlotToActiveState, ChangeSlotState, ShowState, ShiftState

    ]
})

export class SliderComponent implements OnInit, OnChanges, OnDestroy {

    private TAG = '[SliderComponent';
    /*
     *  Constant one step height
     */
    //static stepHeight = 30;

    private _data = new BehaviorSubject<number>(null);
    private dataSubscription: Subscription;

    //@Input() sliderTopOffset: number;
    @Input() slotsConfig: Shift;
    // @Input() selectedSlot: Step | null;
    @Input() reset: boolean;
    @Input() extendSlot;

    @Input() selectedDate;

    @Output() reservedSlot: EventEmitter<Step> = new EventEmitter<Step>();
    @Output() currentShiftEvent: EventEmitter<ShiftType> = new EventEmitter<ShiftType>();
    @Output() maskPositionEvent: EventEmitter<number> = new EventEmitter<number>();
    @Output() toggleSliderEvent: EventEmitter<boolean> = new EventEmitter<boolean>(false);

    @Input() sliderWidth;
    

    // get sliderWidth() {
    //     return this._data.getValue();
    // }

    toggleSlider = false;

    private currentShift: ShiftType = ShiftType.MORNING;
    public url = env.pathImageURLS;

    public sliderTop = null;
    public sliderTopMask = 0;
    public sliderLeft: number | 0 = SlotsConfig.sliderLeftOffset;

    /*
     *  Variables for animations
     */
    public slideState: SlideState = SlideState.ACTIVE;
    public vanState: VanState = VanState.HIDE;
    public vanBigState: VanState = VanState.SHOW;
    public slotTimeState: SlotTimeState = SlotTimeState.SHOW;
    public slotEndState = true;
    public ecoMode = false;

    public changeSlotTime: VanState = VanState.HIDE;
    public slotHoursTop = 0;
    public changeSlot = false;

    private reservationDataSubscription: Subscription;
    private currentReservation: Reservation;

    /*
     *  Variable to setting reserved slide position
     */
    public sliderLeftReserved;
    public sliderTopReserved;
    public slotsStringReserved;

    public moveDirection: MoveDirections;

    public strechSlot = {
        startTime: '',
        endTime: '',
        slot: ''
    };

    isOverlay = true;

    /*
     *  Current position mouse on the slot
     */
    public step: Step | null = null;

    constructor(private appService: AppService, private router: ActivatedRoute) { }


    ngOnInit() {
        this.reservationDataSubscription = this.appService.reservationData.subscribe(
            (reservation) => {
                if (!_.isNull(reservation) && !_.isNull(reservation.slot) && reservation.slot.date === this.selectedDate) {
                    console.log(`${this.TAG} Detected changes in reservation `, reservation);

                    this.currentReservation = reservation;
                    /*
                     *  If 
                     */
                    if (_.isNull(this.step)){
                        this.initialize();
                    }
                }

            },
            (err) => {
                console.log(`${this.TAG}`, err);
            }
        );

        this.step = this.slotsConfig.getMorningShift[0];
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes['reset']) {
            this.slideState = SlideState.ACTIVE;
            this.reservedSlot.emit(null);
        }

        // if (!_.isUndefined(changes['sliderWidth'])) {
        //     this.sliderWidth = changes['sliderWidth']['currentValue'];
        // }

    }

    @HostListener('mousemove', ['$event'])
    mousemove(event) {

        const offsetY = event.offsetY;
        const isOutside = !_.isUndefined(event.srcElement.classList) && event.srcElement.classList.value.includes('overlay');
        const step = (this.stepNumber(offsetY) < SlotsConfig.slotsInShift)
            ? this.stepNumber(offsetY - 15) - 1
            : SlotsConfig.slotsInShift - 1;


        if (isOutside) {

            const shiftType = (event.offsetX < this.sliderWidth + 15) ? ShiftType.MORNING : ShiftType.AFTERNOON;

            if (offsetY <= SlotsConfig.slotHeight) {
                //this.toggleSlider = false;
                this.maskPositionEvent.emit(this.sliderTopCalc + 30);
            }else if (offsetY > 450 && this.isAvailable(step, shiftType)) {
                this.sliderTop = SlotsConfig.columnHeigh - (2 * SlotsConfig.slotHeight);
                this.setPosition(step, event.offsetX, event.offsetY, true);
            } else if (this.isAvailable(step, shiftType)) {
                this.setPosition(step, event.offsetX, event.offsetY);
            }else if (this.isAvailable(step + 1, shiftType)) {
                this.setPosition(step + 1, event.offsetX, event.offsetY);
            }
        }
    }

    // @HostListener('mouseenter')
    // mouseenter() {
    //     this.toggleSlider = true;
    //     this.maskPositionEvent.emit(this.sliderTopCalc + 30);
    //     this.toggleSliderEvent.emit(true);
    // }

    @HostListener('mouseleave')
    mouseleave() {
        this.toggleSlider = (!this.isReserved) ? false : true;
        this.maskPositionEvent.emit(this.sliderTopCalc + 30);
        this.toggleSliderEvent.emit((!this.isReserved) ? false : true);
    }


    @HostListener('click', ['$event'])
    reservedMainSlotEvent(event) {

        const isOutside = !_.isUndefined(event.srcElement.classList) && !event.srcElement.classList.value.includes('remove');

        if (isOutside) {

            /*
            *  SLIDE_RESERVED: set positions
            */
            this.sliderLeftReserved = this.sliderLeft;
            this.sliderTopReserved = this.sliderTop;
            this.slotsStringReserved = this.slotsString;

            this.slideState = SlideState.RESERVED;
            this.ecoMode = true;
            this.isOverlay = false;
            this.maskPositionEvent.emit(this.sliderTopCalc + 30);
            this.toggleSlider = true;
            this.toggleSliderEvent.emit(true);
        } else {
            this.strechSlot.slot = '';
            this.ecoMode = false;
            this.slideState = SlideState.ACTIVE;
            this.extendSlot = -1;
            this.isOverlay = true;
            this.step = null;
            this.toggleSlider = true;
            this.maskPositionEvent.emit(this.sliderTopCalc + 30);
            this.toggleSliderEvent.emit(true);

        }

        this.updateReservationModel();

        /*
         *  SLIDE_RESERVED: emit
         */
        switch (this.slideState) {
            case SlideState.RESERVED:
                this.reservedSlot.emit(this.step);
                break;
            default:
                this.reservedSlot.emit(null);
                break;
        }
    }

    removeReservedSlot() {
        if (this.vanBigState === VanState.HIDE) {
            this.vanBigState = VanState.SHOW;
        }
        this.updateReservationModel();
    }

    reservedMainSlot() {
        /*
         *  SLIDE_ANIMATIONS: toggle between active and reserved
         */
        this.slideState = (this.slideState === SlideState.RESERVED) ? SlideState.ACTIVE : SlideState.RESERVED;


        /*
         *  VAN_ANIMATIONS: arrival on the left
         */
        setTimeout(() => {
            this.vanState = (this.slideState === SlideState.RESERVED) ? VanState.SHOW : VanState.HIDE;
        }, 200);


        /*
         *  SLIDE_RESERVED: emit
         */
        switch (this.slideState) {
            case SlideState.RESERVED:
                this.reservedSlot.emit(this.step);
                break;
            default:
                this.reservedSlot.emit(null);
                break;
        }
    }

    private setPosition(step, offsetX, offsetY, overflow = false) {
        if (!overflow) {
            this.sliderTop = step * SlotsConfig.slotHeight;
            this.maskPositionEvent.emit(this.sliderTopCalc + 30);
        }

        this.toggleSlider = true;
        this.maskPositionEvent.emit(this.sliderTopCalc + 30);
        this.toggleSliderEvent.emit(true);

        /*
        *  Checking if shift type was change
        */
        if (offsetX < this.sliderWidth + 15) {
            this.sliderLeft = SlotsConfig.sliderLeftOffset;
            this.currentShift = ShiftType.MORNING;
            this.step = this.slotsConfig.getMorningShift[step];
            this.currentShiftEvent.emit(this.currentShift);
        } else {
            this.sliderLeft = this.sliderWidth + SlotsConfig.sliderWidthOffset;
            this.currentShift = ShiftType.AFTERNOON;
            this.step = this.slotsConfig.getAfternoonShift[step];
            this.currentShiftEvent.emit(this.currentShift);
        }
    }

    private updateReservationModel(): void {
        this.appService.setSlot(this.step, this.selectedDate);
    }

    get slotsString(): string {
        if (!_.isNull(this.step) || _.isUndefined(this.step)) {
            return (this.strechSlot.slot !== '') ? this.strechSlot.slot : this.step.slot;
        }
    }

    isAvailable(stepNumber: number, shiftType): boolean {

        if (shiftType === ShiftType.MORNING) {
            const slotsAsArray = this.slotsConfig.getMorningShift;
            if (!_.isUndefined(slotsAsArray[stepNumber]) && !_.isUndefined(slotsAsArray[stepNumber + 1])) {
                return slotsAsArray[stepNumber].availability && slotsAsArray[stepNumber + 1].availability;
            }else {
                return false;
            }
        } else {
            const slotsAsArray = this.slotsConfig.getAfternoonShift;
            if (!_.isUndefined(slotsAsArray[stepNumber]) && !_.isUndefined(slotsAsArray[stepNumber + 1])) {
                return slotsAsArray[stepNumber].availability && slotsAsArray[stepNumber + 1].availability;
            }else {
                return false;
            }

        }
    }

    private initialize(): void {

        console.log(`${this.TAG} Initialize slider`);

        const top = SlotsConfig.defaultSlots.findIndex((s) => s === this.currentReservation.slot.mainSlotStart);

        this.sliderLeft = this.sliderLeftReserved = (top < 16)
            ? SlotsConfig.sliderLeftOffset - 1
            : this.sliderWidth + SlotsConfig.sliderWidthOffset;


        this.sliderTop = this.sliderTopReserved = (top % 16) * SlotsConfig.slotHeight;

        this.slideState = SlideState.RESERVED;
        this.toggleSlider = true;
        this.ecoMode = true;
        this.isOverlay = false;

        this.maskPositionEvent.emit(this.sliderTopCalc + 30);
        this.toggleSlider = true;
        this.toggleSliderEvent.emit(true);
        this.currentShiftEvent.emit((top < 16) ? ShiftType.MORNING : ShiftType.AFTERNOON);

    }

    /*
     *  Check if slider is active or user reserved slot
     */
    get isReserved(): boolean {
        return this.slideState === SlideState.RESERVED;
    }

    get sliderTopCalc() {
        return (this.isReserved) ? this.sliderTopReserved : this.sliderTop;
    }

    get sliderTopMaskCalc() {
        return (this.isReserved) ? this.sliderTopReserved : this.sliderTopMask - 15;
    }

    get sliderLeftCalc() {
        return (this.isReserved) ? this.sliderLeftReserved : this.sliderLeft;
    }

    get extendSlotCalc() {
        return this.extendSlot * 30;
    }

    stepNumber(offsetY): number {
        return Math.round((offsetY) / SlotsConfig.slotHeight);
    }

    get isShowSlider() {
        return !_.isUndefined(this.step) && this.step.availability;
    }

    get isExtendSlotMode() {
        return !_.isUndefined(this.extendSlot) && this.extendSlot !== -1;
    }

    ngOnDestroy() {
        console.log(`${this.TAG} ngOnDestroy`)
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }

        if (this.reservationDataSubscription) {
            this.reservationDataSubscription.unsubscribe();
        }

        this.reservedSlot.emit(null);
        this.currentShiftEvent.emit(null);
        this.maskPositionEvent.emit(null);
        this.toggleSliderEvent.emit(null);

    }
}
