import { Directive, HostListener, ElementRef, AfterContentInit, ContentChildren, QueryList } from '@angular/core';
import { Subject } from 'rxjs';
import { flatMap, filter, map } from 'rxjs/operators';
import { SEGMENT_ID, SLIDER_ID_LEFT, SLIDER_ID_RIGHT } from '../components/smart-booking/smart-booking-data';
import { SegmentDirective } from './segment.directive';
import * as _ from 'lodash';
import { SmartBookingService } from '../services/smart-booking.service';

@Directive({
  selector: '[appBarContainer]'
})
export class BarContainerDirective implements AfterContentInit {

  @ContentChildren(SegmentDirective, { read: ElementRef })
  public segments: QueryList<ElementRef>;

  private sliderIdLeft = SLIDER_ID_LEFT;
  private sliderIdRight = SLIDER_ID_RIGHT;

  private segmentId = SEGMENT_ID;

  public bar: {
    width: number,
  }

  private mouseDown: Subject<any> = new Subject();
  private mouseMove: Subject<any> = new Subject();
  private mouseUp: boolean = false;
  private segmentPosition: number = 0;
  private sliderDirection;

  constructor(
    private readonly _elementRef: ElementRef,
    private readonly bookingService: SmartBookingService) {
  }

  ngAfterContentInit() {

    this.bar = {
      width: this._elementRef.nativeElement.clientWidth,
    }

    this.stream();
  }

  stream() {
    this.mouseDown.pipe(
      filter(Boolean),
      flatMap(startPos => {
        console.log('start: ', startPos);
        this.segmentPosition = this.findSegmentPosition(startPos)
        return this.mouseMove;
      }),


      map((event) => {

        const a = event.path.filter(a => a.id === this.segmentId);

        if (this.segmentPosition !== this.findSegmentPosition(event)) {
          this.mouseUp = false;
        }

        if (a && this.mouseUp) {
          const segmentName = a[0].innerText;
          const e = this.segments.find(a => a.nativeElement.innerText === segmentName);
          const index = this.segments['_results'].findIndex(a => a.nativeElement.innerText === segmentName)

          if (event.layerX > 5) {

            if (this.sliderDirection === SLIDER_ID_RIGHT) {

              const next = this.segments['_results'][index + 1];

              if (next !== undefined) {
                const width2 = event.layerX + 5;

                this.bookingService.positionSource.next({
                  width: Math.round((100 * width2) / this.bar.width / 5) * 5,
                  index: index,
                  direction: SLIDER_ID_RIGHT,
                  slot: a[0].dataset['slot'],
                  segmentName: a[0].dataset['name']
                })
              }
            } else {

              const prev = this.segments['_results'][index - 1];
              if (prev !== undefined) {
                const width = event.layerX;
                const w = ((100 * (prev.nativeElement.offsetWidth + width)) / this.bar.width) + 2;

                this.bookingService.positionSource.next({
                  width: Math.round(w / 5) * 5,
                  index: index,
                  direction: SLIDER_ID_LEFT,
                  slot: a[0].dataset['slot'],
                  segmentName: a[0].dataset['name']
                })
              }
            }


          }
        }
      }),
    ).subscribe()
  }

  @HostListener('mouseup', ['$event'])
  onMouseup(event) {
    this.mouseUp = false;
  }

  @HostListener('mousedown', ['$event'])
  onMousedown(event) {
    const inside = event.path.find(event => event.id === this.sliderIdLeft || event.id === this.sliderIdRight);

    if (inside !== undefined) {
      this.sliderDirection = inside.id;
      this.mouseDown.next(event)
      this.mouseUp = true;
    } else {
      this.mouseUp = false;
    }

    return false;
  }

  @HostListener('mousemove', ['$event'])
  onMousemove(event) {
    if (this.mouseUp) {
      this.mouseMove.next(event);
    }
  }

  @HostListener('mouseleave', ['$event'])
  onMouseleave(event) {
    this.mouseUp = false;
    this.mouseDown.next(false);
  }

  findSegmentPosition(event) {
    const a = event.path.filter(a => a.id === this.segmentId);
    const s = a[0].innerText;
    const index = this.segments['_results'].findIndex(a => a.nativeElement.innerText === s)
    return index;
  }
}
