import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '../../shared/stores/store';
import { ReservationInterface } from '../interfaces/reservation-interface';

const ERR_NOT_INITIALIZED = 'Please call create() method first!';

@Injectable()
export class ReservationsWizardService {
  private store: Store<ReservationInterface>;

  create(initialModel: ReservationInterface) {
    this.store = new Store<ReservationInterface>(initialModel);
  }

  getModel(): Observable<ReservationInterface> {
    if (!this.store) {
      throw new Error(ERR_NOT_INITIALIZED);
    }

    return this.store.state$;
  }

  getModelState(): ReservationInterface {
    if (!this.store) {
      throw new Error(ERR_NOT_INITIALIZED);
    }

    return this.store.state;
  }

  updateModel(partial: Partial<ReservationInterface>): ReservationInterface {
    const current = this.store.state;
    this.store.setState({
      ...current,
      ...partial
    } as ReservationInterface);

    return this.store.state;
  }

  modelHasDefinedProperties(propertiesMap: string[]): boolean {
    if (!this.store) {
      throw new Error(ERR_NOT_INITIALIZED);
    }

    const model = this.store.state;
    return propertiesMap.every(prop => (model as any)[ prop ] !== undefined);
  }

  destroy() {
    if (this.store) {
      this.store.destroy();
      delete(this.store);
    }
  }
}
