import { Injectable } from '@angular/core';
import { IBarsPosition } from '../interfaces/IBarsPosition.interface';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SmartBookingService {


  public positionSource: Subject<IBarsPosition> = new Subject<IBarsPosition>();
  public position: Observable<IBarsPosition> = this.positionSource.asObservable();

  constructor() { }
}
