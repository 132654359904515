import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'slotHourPipe'
})
export class SlotHourPipe implements PipeTransform {
  transform(value: any, isEcoSlot): any {
    const slot = moment(`2000-01-01 ${value}`);
    return `${slot.format('H:mm')}${!isEcoSlot ? ` - ${slot.add(1, 'hour').format('H:mm')}` : '' }`;
  }
}
