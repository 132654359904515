import { Pipe, PipeTransform } from '@angular/core';
import { ISmartBooking } from '../interfaces/ISmartBooking.interface';

@Pipe({
  name: 'isNotEnabledPipe',
})
export class IsNotEnabledPipe implements PipeTransform {

  transform(data: ISmartBooking): boolean {
    console.log(data);
    return data.segment.find(s => s.enabled) === undefined;
  }

}
