import { Pipe, PipeTransform, ElementRef } from '@angular/core';

@Pipe({
  name: 'widthLabelPipe'
})
export class WidthLabelPipe implements PipeTransform {

  constructor(public readonly elementRef: ElementRef) {}

  
  transform(value: any, args?: any): any {
    console.log(this.elementRef);
    return this.elementRef.nativeElement.dataset['width'] + '%';
  }

}
