import { BehaviorSubject, Observable } from 'rxjs';

export class Store<T> {
  private stateSubject: BehaviorSubject<T>;
  state$: Observable<T>;
  _state: T;

  constructor(initialState: T) {
    this.stateSubject = new BehaviorSubject<T>(initialState);
    this._state = initialState;
    this.state$ = this.stateSubject.asObservable();
  }

  get state(): T {
    return this._state;
  }

  setState(nextState: T): void {
    this._state = nextState;
    this.stateSubject.next(nextState);
  }

  destroy() {
    this.stateSubject.complete();
  }
}
