import { Component, Input, Output, EventEmitter, SimpleChanges, OnInit, OnChanges, OnDestroy, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AppService } from './services/app.service';
import { environment } from '../environments/environment';
import { configuration } from './helpers/config.mock';
import { Subscription } from 'rxjs';
import Configuration from './entities/configuration';
import * as _ from 'lodash';
import { ReservationsWizardService } from './shared/services/reservation-wizard.service';
import { ReservationInterface } from './shared/interfaces/reservation-interface';
import { WidgetMode } from './shared/enums/widget-mode.enum';

@Component({
  selector: 'or-slotpicker',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnChanges, OnDestroy {

  private TAG = '[SlotPicker] - [AppComponent]';
  env = environment;
  error = false;

  constructor(
    private translate: TranslateService,
    private router: Router, private appService: AppService,
    private readonly wizardService: ReservationsWizardService) {
      this.wizardService.create({mode: WidgetMode.ACTIVE} as ReservationInterface );
      // this.wizardService.create({
      //   "date":"2020-03-31","settings":{"defaultSize":60,"minSize":1,"maxSize":6,"overlapingStep":30,"preBookedSlot":false},"location":{"additional":{"companyName":"","contactName":"","contactPhoneNO":"","domofonCode":"","flatNO":"","floor":0,"lift":0,"staircaseNO":""},"address":{"country":"Poland","district":"Warszawa","houseNO":"35","street":"Bajana 1","town":"Warszawa","voivoidship":"Warszawa","zip":"01-905"},"coordinates":{"lat":"52.2744999","lng":"20.9341799"},"label":"Dom","locationId":"125a7eae-e39f-460a-b26b-6deabb991452","locationRef":"125a7eae-e39f-460a-b26b-6deabb991452"},"mode":"PRE_BOOKED","extendedSlot":true,"slot":{"slotId":null,"date":null,"startTime":"08:00:00","endTime":"13:30:00","cutOff":null,"slot":"12:30-13:30","availability":true,"isLast":false,"index":13,"freeDelivery":null,"shiftType":"MORNING"},"sliderCurrentPosition":360
      // } as ReservationInterface);
    }

  @Input() configuration;

  @Output() reservationChanged = new EventEmitter<string>();
  @Output() createLocationRequested = new EventEmitter<boolean>();
  @Output() reservationCancelRequested = new EventEmitter<boolean>();

  private reservationSubscription: Subscription;
  private locationCreateSubscription: Subscription;

  sendEventReservationChanged(jwt: string) {
    console.warn(`${this.TAG} [reservationChanged] - Sending event to partnerstore`);
    this.reservationChanged.emit(jwt);
  }

  sendLocationCreateRequestEvent() {
    console.warn(`${this.TAG} [createLocationRequested] - Sending event to partnerstore`);
    this.createLocationRequested.emit(true);
  }

  sendReservationCancelRequestedEvent() {
    console.warn(`${this.TAG} [reservationCancelRequested] - Sending event to partnerstore`);
    this.reservationCancelRequested.emit(true);
  }

  ngOnInit() {
    console.log(`ENV:`, this.env);

    if (environment.mode === 'DEVELOPMENT') {
      const config: Configuration = JSON.parse(JSON.stringify(configuration));
      console.log(`${this.TAG} Received configuration from partner store in Slot Picker`, config);

      this.setDefaultLang(config.lang);
      this.appService.loadConfigurationData(config);
      //this.router.navigateByUrl('/picker', { skipLocationChange: true });
    }

    this.reservationSubscription = this.appService.reservationJWT.subscribe(
      (jwt: string) => {
        (!_.isNull(jwt)) ? this.sendEventReservationChanged(jwt) : this.sendReservationCancelRequestedEvent();
      });

    this.locationCreateSubscription = this.appService.createLocationRequest.subscribe(
      () => {
        this.sendLocationCreateRequestEvent();
      });
  }

  ngOnChanges(changes: SimpleChanges) {

    const config: Configuration = new Configuration().deserialize(JSON.parse(changes.configuration.currentValue));
    const result = this.appService.loadConfigurationData(config);

    if (result) {
      console.log(`${this.TAG} Received configuration from partner store in Slot Picker`, config);

      if (environment.mode === 'INTEGRATION') {
        if (config.userToken !== '') {
          this.setDefaultLang(config.lang);
          this.appService.loadConfigurationData(config);
          this.router.navigateByUrl('/picker', { skipLocationChange: true });
        }
      }
      this.error = false;
    } else {
      this.error = true;
    }
  }

  setDefaultLang(lang): void {
    this.translate.setDefaultLang('en-GB');
    this.translate.use('en-GB');
  }

  ngOnDestroy() {
    console.error(`${this.TAG} On destroy`);
    if (this.reservationSubscription) {
      this.reservationSubscription.unsubscribe();
    }
    if (this.locationCreateSubscription) {
      this.locationCreateSubscription.unsubscribe();
    }

    this.wizardService.destroy();

  }
}


