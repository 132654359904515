import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlotSelectionComponent } from './components/slot-selection/slot-selection.component';
import { DateSelectionComponent } from './components/date-selection/date-selection.component';
import { TranslationModule } from './../modules/translate.module';
import { MomentModule } from 'ngx-moment';
import { StepPickerUIComponent } from './components/picker-ui/step/step.component';
import { SliderComponent } from './components/picker-ui/slider/slider.component';
import { SliderListenerDirective } from './directives/slider-listener.directive';
import { StepListenerDirective } from './directives/step-listener.directive';
import { SlotsHoursComponent } from './components/picker-ui/slots-hours/slots-hours.component';
import { SlotHourPipe } from './pipes/slot-hour.pipe';
import { OverlayListenerDirective } from './directives/overlay-listener.directive';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { EcoComponent } from './components/picker-ui/eco/eco.component';
import { LottieAnimationViewModule } from 'ng-lottie';
import { EcoZoneComponent } from './components/picker-ui/eco-zone/eco-zone.component';
import { SliderTimeFormatPipe } from './pipes/slider-time-format.pipe';
import { ReservationSummaryComponent } from './components/reservation-summary/reservation-summary.component';
import { ShiftListenerDirective } from './directives/shift-listener.directive';


@NgModule({
  declarations: [SlotSelectionComponent, DateSelectionComponent, StepPickerUIComponent, SliderComponent, SliderListenerDirective, StepListenerDirective, SlotsHoursComponent, SlotHourPipe, OverlayListenerDirective, EcoComponent, EcoZoneComponent, SliderTimeFormatPipe, ReservationSummaryComponent, ShiftListenerDirective],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    // CommonModule,
    TranslationModule,
    MomentModule,
    LottieAnimationViewModule.forRoot()
  ],
  exports: [
    SlotSelectionComponent
  ]
})
export class SlotSelectionModule { }
