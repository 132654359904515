import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppService } from './../../../services/app.service';
import { Router } from '@angular/router';
import Configuration from './../../../entities/configuration';
import Location from './../../../entities/locations';
import * as _ from 'lodash';

@Component({
    selector: 'address-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class AddressListComponent implements OnInit, OnDestroy {

    private TAG = '[AddressListComponent]';

    public locations: Location[];
    public selectedLocation: Location;

    private configurationSubscription: Subscription;
    private configuration: Configuration;

    public loader = true;
    public error = false;

    constructor(
        private appService: AppService,
        private router: Router,
    ) { }

    ngOnInit() {
        this.configurationSubscription = this.appService.configurationData
            .subscribe((configuration: Configuration) => {
                if (!_.isNull(configuration)) {
                    console.log(`${this.TAG} Recieved locations list`, configuration.locationList);
                    this.configuration = configuration;
                    this.locations = configuration.locationList;
                    this.selectedLocation = configuration.selectedLocation;
                    this.loader = false;
                    this.error = false;
                }
            });
    }

    changeSelectedLocation(location: Location) {
        this.selectedLocation = location;
        this.configuration.selectedLocation = location;
        this.appService.changeConfigurationByUser(this.configuration);
        this.router.navigateByUrl(`/picker/(content:choose-available-slot;locationId=${this.selectedLocation.locationId})`
            , { replaceUrl: true, skipLocationChange: true });

    }

    editAddress(location: Location): void {
        console.log(`${this.TAG} Address to edit`, location);
        const locationId = location.locationId;
        this.router.navigateByUrl(`/address/(content:edit;locationId=${this.selectedLocation.locationId})`
            , { replaceUrl: true, skipLocationChange: true });

    }

    addLocation(): void {
        this.appService.locationsCreated();
    }

    ngOnDestroy() {
        console.log(`${this.TAG} NgOnDestroy method`);
        if (this.configurationSubscription) {
            this.configurationSubscription.unsubscribe();
        }
    }
}
