import { Injectable } from '@angular/core';
import { User, UserInfo } from 'firebase';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { filter } from 'rxjs/internal/operators/filter';
import * as jwtDecode from 'jwt-decode';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userData: any;
  readonly authState$: Observable<User | null> = this.afAuth.authState;

  constructor( public afAuth: AngularFireAuth, private readonly router: Router) {
    this.afAuth.authState
    .pipe(filter(user => user !== undefined))
    .subscribe((user: User) => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
      } else {
        localStorage.setItem('user', null);
      }
    });
   }

  async register(email: string, password: string) {
    try {
      return this.afAuth.auth.createUserWithEmailAndPassword(email, password);
    } catch (error) {
      return error;
    }
  }

  async SignIn(email: string, password: string) {
    try {
      return await this.afAuth.auth.signInWithEmailAndPassword(email, password);
      //this.router.navigate(['picker']);

    } catch (error) {
      throw error;
      //this.notification.error(error.code, error.message);
    }
  }

  isAuthenticated(): boolean {
    return localStorage.getItem('user') !== undefined ? true : false;
  }

  signOut() {
    this.afAuth.auth.signOut();
  }
}

