import { Component, OnInit } from '@angular/core';
import { AddressSelectionService } from '../../services/address-selection.service';
import { Observable } from 'rxjs';
import { LocationInterface } from '../../interfaces/location-interface';
import { AngularFirestore } from '@angular/fire/firestore';
import { filter } from 'rxjs/internal/operators/filter';
import { map } from 'rxjs/internal/operators/map';
import { tap } from 'rxjs/internal/operators/tap';

@Component({
  selector: 'app-address-selection',
  templateUrl: './address-selection.component.html',
  styleUrls: ['./address-selection.component.scss']
})
export class AddressSelectionComponent implements OnInit {

  locations$: Observable<LocationInterface[]>;
  selectedLocation: LocationInterface;
  loader = true;

  constructor(
    private readonly addressSelectionService: AddressSelectionService,
    private readonly fb: AngularFirestore
  ) { }

  ngOnInit() {
    this.locations$ = this.addressSelectionService.getLocations().pipe(
      filter(location => location !== undefined),
      tap(locations => {
        this.selectedLocation = locations[0];
        this.changeSelectedLocation(this.selectedLocation);
      }),
      tap(() => this.loader = false)
    );
  }

  changeSelectedLocation(location) {
    this.addressSelectionService.changedLocation(location);
  }


  // test() {
  //   this.fb.collection("locations").doc("Location_5").set(this.temp[3])
  //   .then(function() {
  //       console.log("Document successfully written!");
  //   })
  //   .catch(function(error) {
  //       console.error("Error writing document: ", error);
  //   });
  // }

}
