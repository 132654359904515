import { Directive, ElementRef, Renderer2, ContentChild, HostListener, AfterContentInit } from '@angular/core';
import { DropdownToggleDirective } from './dropdown-toggle.directive';
import { DropdownMenuDirective } from './dropdown-menu.directive';

@Directive({
  selector: '[dropdown]'
})
export class DropdownDirective implements AfterContentInit {

  constructor(private elem: ElementRef, private renderer: Renderer2) { }

  @ContentChild(DropdownToggleDirective, { read: DropdownToggleDirective, static: false })
  public toggle: DropdownToggleDirective;

  @ContentChild(DropdownMenuDirective, { read: ElementRef , static: false})
  public menu: ElementRef;

  @HostListener('keyup.escape')
  @HostListener('document:click', ['$event.path'])
  exitDropdown(path = []) {
    const isOutside = path.indexOf(this.elem.nativeElement) === -1;
    //if (isOutside) {
      const menu = this.menu.nativeElement;
      this.renderer.setProperty(menu, 'hidden', true);
    //}
  }

  ngAfterContentInit() {
    const menu = this.menu.nativeElement;
    this.renderer.setProperty(menu, 'hidden', true);
    this.toggle.onToggle.subscribe(() => this.renderer.setProperty(menu, 'hidden', !menu.hidden));
  }
}
