import { Injectable } from '@angular/core';
import { ReservationsWizardService } from '../../shared/services/reservation-wizard.service';
import { ReservationInterface } from '../../shared/interfaces/reservation-interface';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { PickerUIInterface } from '../interfaces/pickerUI-interface';
import { shareReplay, map, tap } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SlotSelectionService {

  private static CACHE_SIZE = 1;
  public cache$: Observable<PickerUIInterface | void>;
  public availableSlots: PickerUIInterface;

  private itemsCollection$: AngularFirestoreCollection<PickerUIInterface | void>;
  public availableSlots$: Observable<PickerUIInterface | void>;

  sliderPositionTopOldValue;
  public sliderPositionTop$: Subject<number> = new Subject<number>();

  constructor(
    private readonly wizardService: ReservationsWizardService,
    private readonly afs: AngularFirestore,
  ) { }

  getAvailableSlots(selectedDate?: string): Observable<PickerUIInterface | void> {

    this.cache$ = this.requestLocations().pipe(
      //shareReplay(SlotSelectionService.CACHE_SIZE),
      map((configurations) => {
        const config = configurations[_.random(configurations.length - 1)];
        config['shifts'].forEach((shift) => {
          //console.log(shift);
            shift['date'] = selectedDate;
            const length = shift['steps'].length;
            shift['steps'].map((step, index) => {
              step['date'] = selectedDate;

              (index < length - 1)
                ? step.isLast = (!shift['steps'][index + 1].availability)
                : step.isLast = true;

              (index > 0 && index < length)
                ? step.isFirst = (!shift['steps'][index - 1].availability)
                : step.isFirst = true;

            });
        });
        return config;
      }),
      tap(configurations => this.availableSlots = configurations as PickerUIInterface)
    );

    if (selectedDate === undefined) {
      return this.cache$;
    }
    return this.cache$;
  }

  requestLocations() {
    this.itemsCollection$ = this.afs.collection<PickerUIInterface>('available_slots');
    return this.itemsCollection$.valueChanges();
  }

  changedReservationDate(date: string) {

    this.wizardService.updateModel({
      date: date
    } as Partial<ReservationInterface>);
  }


}
