import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, OnDestroy, Input, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import { PickerService } from '../services/picker.service';
import { ShiftType } from '../entities/interfaces';
import { AppService } from '../../../services/app.service';
import { environment } from '../../../../environments/environment';
import { Mode } from '../../../helpers/app.mode';
import { slots } from '../../../helpers/slots.mock';
import { Subscription } from 'rxjs';

import Shift from '../entities/shift';
import Step from '../entities/step';
import Configuration from '../../../entities/configuration';
import Location from '../../../entities/locations';

import * as _ from 'lodash';
import * as moment from 'moment';
import { SlotsConfig } from '../slots.config';
import Reservation from '../../../entities/reservation';
import { ShowState } from '../animations';
import { Settings } from '@angular/fire/firestore';

@Component({
    selector: 'app-picker',
    templateUrl: './picker.component.html',
    styleUrls: ['./picker.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    animations: [ShowState]
})
export class PickerComponent implements OnInit, OnDestroy, OnChanges {

    private TAG = '[PickerComponent]';

    @Input() slotpickerSettings: Settings;

    public date = moment().format('MMMM Do YYYY');

    public loader = true;
    public error = false;

    private selectedAddress: Location = null;

    public selectedDate: string = moment(new Date()).add(1, 'days').format('YYYY-MM-DD');

    public sliderWidth: number | null = 218;
    public sliderTopOffset: number | null = null;
    public selectedSlot: Object | null = null;

    public configuration: Configuration;
    private configurationSubscription: Subscription;

    public reservation: Reservation = null;
    private reservationSubscription: Subscription;

    public reservationExpirationTime: string = null;
    private reservationJWTSubscription: Subscription;

    public color: 'primary';

    public reset = false;

    public reservedSlotByUser: Step | null = null;

    options = new Date(Date.now());
    events: string[] = [];

    public extendSlot;
    public slotsConfig: Shift;
    public currentShift: ShiftType = ShiftType.MORNING;

    public maskPosition;
    showOverlay;

    //private slotsContainer: ElementRef;

    //@ViewChild('slotsContainer') slotsContainer: ElementRef;
   //@ViewChild('slotsContainer') el:ElementRef;


    // @ViewChild('slotsContainer') set controlElRef(elementRef: ElementRef) {
    //     console.log(elementRef);
    //     if (!_.isUndefined(elementRef)) {
    //         this.slotsContainer = elementRef;
    //         this.calcSliderPos();
    //     }
    // }

    // @ViewChild("slotsContainer")
    // set myInput(_input: ElementRef | undefined) {
    //     if (_input !== undefined) {
    //         setTimeout(() => {
    //             this._renderer.invokeElementMethod(_input.nativeElement, "focus");
    //         }, 0);
    //     }
    // }

    locale;

    constructor(
        private pickerService: PickerService,
        private appService: AppService) {

            this.locale = moment.locale(this.appService.lang);

        this.configurationSubscription = this.appService.configurationData.subscribe(configuration => {
            if (!_.isNull(configuration)) {
                this.configuration = configuration;
                this.reset = true;
                this.loadAvailableSlot();
            }
        });
    }


    ngOnInit() {
        this.selectedAddress = this.configuration.selectedLocation;

        

        this.reservationSubscription = this.appService.reservationData.subscribe(reservation => {
            this.reservation = reservation;
        });

        this.reservationJWTSubscription = this.appService.reservationJWT.subscribe(
        (jwt: string) => {
            this.reservationExpirationTime = new Reservation().getExpirationTime(jwt);

        });

       
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(this.slotpickerSettings);
        
    }

    // ngAfterViewInit()
    // {
    //     console.log(this.el)
    //     this.el.nativeElement.focus();
    // }

    calcSliderPos() {
        // if (!_.isUndefined(this.slotsContainer)) {
        //this.sliderTopOffset = this.slotsContainer.nativeElement.offsetTop + this.slotsContainer.nativeElement.scrollTop;
        //this.sliderWidth = (this.slotsContainer.nativeElement.clientWidth / 2) - SlotsConfig.sliderWidthOffset + 1;
        //}
    }

    loadAvailableSlot() {

        this.loader = true;
        this.error = false;

        this.reservedSlotByUser = null;

        if (environment.mode === Mode.INTEGRATION) {

            this.pickerService.getAvailableStols(this.configuration, this.selectedDate).subscribe(
                (allSlots: any) => {
                    //todo: remove const
                    console.log(this.selectedDate)
                    if(this.selectedDate == "2019-03-14" || this.selectedDate == "2019-03-07") {
                        this.slotsConfig = new Shift().deserialize(slots);
                    }else{
                        this.slotsConfig = new Shift().deserialize(allSlots);
                    }

                    console.log(`${this.TAG} Slots configuration`, this.slotsConfig);
                    //this.findFirstAvailableSlot();
                    this.loader = false;
                    this.error = false;
                    this.reset = false;
                },
                (err) => {
                    console.log(`${this.TAG} Error during fetching the available slots`, err);
                    this.loader = false;
                    this.error = true;
                    this.reset = false;
                });
        } else if (environment.mode === Mode.DEVELOPMENT) {
            this.slotsConfig = new Shift().deserialize(JSON.stringify(slots));
            console.log(`${this.TAG} Slots configuration`, this.slotsConfig);
            //this.findFirstAvailableSlot();
            this.loader = false;
            this.reset = false;
        }
    }

    // findFirstAvailableSlot(): void {
    //     const morning = this.slotsConfig.getMorningShift;

    //     for (let i = 0; i < morning.length - 1; i++) {
    //         const previousElem: Step = morning[i];
    //         const nextElem: Step = morning[i + 1];
    //         if (previousElem.availability && nextElem.availability) {
    //             this.selectedSlot = previousElem;
    //             break;
    //         }
    //     }
    // }

    getSlots(shiftType: ShiftType) {
        if (shiftType === ShiftType.MORNING) {
            return this.slotsConfig.getMorningShift;
        } else if (shiftType === ShiftType.AFTERNOON) {
            return this.slotsConfig.getAfternoonShift;
        }
    }

    onResize(event) {
        console.log(`resize`, event);
        //this.sliderWidth = (this.slotsContainer.nativeElement.clientWidth / 2) - SlotsConfig.sliderWidthOffset + 1;
    }

    reservedSlotEvent(event) {
        this.reservedSlotByUser = event;
        if (this.isReserved) {
            this.sendReservationRequest();
        }
        console.log(`${this.TAG} - Reserved slot: `, this.reservedSlotByUser);
    }

    selectedDayChange(event) {
        console.log(`${this.TAG} Changed day: ${event}`);
        this.selectedDate = event;
        this.reset = true;
        this.extendSlot = -1;
        this.loadAvailableSlot();
    }

    sendReservationRequest() {
        this.appService.makeReservation(this.selectedDate).subscribe(
            (response) => {
                console.log(`${this.TAG} Clear all setted data`, response);
            },
            (err) => {
                console.error(`${this.TAG} Error during sendig finnall reservation data model`, err);
            }
        );
    }

    currentShiftEvent(event) {
        if (!this.isReserved) {
            this.currentShift = event;
        }
    }

    get isReserved() {
        return !_.isNull(this.reservedSlotByUser);
    }

    extendSlotEvent(event) {
        this.maskPosition = event * SlotsConfig.slotHeight ;
        this.extendSlot = event;
    }

    toggleSliderEvent(event) {
        this.showOverlay = event;
    }

    ngOnDestroy() {
        if (this.configurationSubscription) {
            this.configurationSubscription.unsubscribe();
        }
        if (this.reservationSubscription) {
            this.reservationSubscription.unsubscribe();
        }
    }

    get freeDelivery(){
        return SlotsConfig.freeDelivery - this.configuration.basketAmount;
    }

    get madeReservation(){
        return !_.isNull(this.reservation) && !_.isNull(this.reservation.slot);
    }

    maskPositionEvent(event) {
        this.maskPosition = event;
    }
}
