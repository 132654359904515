import { Component, OnInit } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { AngularFirestore } from '@angular/fire/firestore';
import { tap, map } from 'rxjs/operators';

@Component({
  selector: 'app-cash-withdrawal',
  templateUrl: './cash-withdrawal.component.html',
  styleUrls: ['./cash-withdrawal.component.scss']
})
export class CashWithdrawalComponent implements OnInit {

  code;

  status: 'INIT' | 'IN_PROGRESS' | 'COMPLETED'  | 'CASH' = 'INIT';
  index = 0;
  interval;

  constructor(private readonly fireStoreService: AngularFirestore) { }

  ngOnInit() {
    this.code = UUID.UUID().replace(/[.*+\-?^${}()|[\]\\]/g, '');
    //this.code = '4fb9bd4b7f16fde0194680ab51f38c73';
    this.initCode();

    const connectionList = this.fireStoreService.collection(`connection`).doc(this.code).valueChanges();

    connectionList.pipe().subscribe((a: {status: 'INIT' | 'IN_PROGRESS' | 'COMPLETED', 'CASH'}) => {
      console.log(a.status);
      this.status = a.status;

      if (a.status === 'IN_PROGRESS') {
        this.interval = setInterval(() => {
          this.index += 1;
        }, 1000);
      } else {
        if (this.interval) {
          clearInterval(this.interval);
        }
      }
     });

  }

  initCode() {
    return new Promise<any>((resolve, reject) => {
      this.fireStoreService
          .collection('connection')
          .doc(this.code)
          .set({ status: 'INIT' }, { merge: true })
          .then(res => {}, err => reject(err));
    });
  }


}
