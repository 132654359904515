
import { Entity } from '../helpers/vo/entity';
import { Serializable } from '../helpers/vo/serializable';
import * as _ from 'lodash';
import Location from './locations';
import Customer from './customer';
import Slot from './slot';
import { JwtHelperService } from '@auth0/angular-jwt';

export default class Reservation extends Entity implements Serializable<Reservation> {

    reservationId: string;
    customer: Customer;
    expiration: string;
    location: Location;
    paymentType: string;
    slot: Slot;


    deserialize(data: any): Reservation {

        if (_.isNull(data)) {
            return null;
        }

        this.customer = new Customer().deserialize(_.get(data, 'customer'));
        this.expiration = _.get(data, 'expiration', '');
        this.location = new Location().deserialize(_.get(data, 'location'));
        this.paymentType = _.get(data, 'paymentType', '');
        this.reservationId = _.get(data, 'reservationId', '');
        this.slot = new Slot().deserialize(_.get(data, 'slot'));

        return this;
    }

    serialize() {
        const serialized = {
            customer: this.customer,
            expiration: this.expiration,
            location: this.location,
            paymentType: this.paymentType,
            reservationId: this.reservationId,
            slot: this.slot
        };

        return serialized;
    }

    parseJWT(jwt: string): Reservation {

        if (_.isNull(jwt)) {
            throw 'Invalid jwt token';
        }


        const helper = new JwtHelperService();
        const decoded = helper.decodeToken(jwt);

        this.expiration = _.get(decoded, 'expiration');
        this.paymentType = _.get(decoded, 'paymentType');
        this.reservationId = _.get(decoded, 'reservationId');

        const customer = _.get(decoded, 'customer');
        this.customer = new Customer().deserialize(_.get(customer, 'customer'));
        const location = _.get(decoded, 'location');
        this.location = new Location().deserialize(_.get(location, 'location'));

        const slot = _.get(decoded, 'slot');
        this.slot = new Slot().deserialize(_.get(slot, 'slot'));

        return this;
    }

    getExpirationTime(jwt: string): string {
        if (_.isNull(jwt)) {
            throw 'Invalid jwt token';
        }

        const helper = new JwtHelperService();
        const decoded = helper.decodeToken(jwt);

        return _.get(decoded, 'expiration', null);
    }

    strechSlot(time: string, direction: string) {
        if (direction === 'startTime') {
            this.slot.startTime;
        } else if (direction === 'endTime') {
            this.slot.endTime;
        }

        return this;
    }
}
