import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';


export const ChangeSlotToActiveState = trigger('ChangeSlotToActiveState', [
    transition('RESERVED=>ECO_SLOTS', [

    ]),
    transition('*=>ECO_SLOTS', [
        group([
            query('.van-img', [
                style({
                    left: '-10px',
                    opacity: 0
                }),
                animate(200, style({
                    left: '10px',
                    opacity: 0.5
                })),
                animate(200, style({
                    left: '0px',
                    opacity: 1
                })),
            ], { optional: true }),
            query('.reserved__content__text__text_van', [
                style({
                    left: '-40px',
                    opacity: 0
                }),
                animate(1000, style({
                    left: '-40px',
                    opacity: 0
                })),
                animate(300, style({
                    left: '10px',
                    opacity: 1
                })),
                animate(200, style({
                    left: '0px',
                    opacity: 1
                })),
            ], { optional: true }),
            query('.reserved__content__decline_slot', [
                style({
                    opacity: 0
                }),
                animate(800, style({
                    opacity: 0
                })),
                animate(300, style({
                    opacity: 1,
                    transform: 'scale(1.2)',
                })),
                animate(200, style({
                    transform: 'scale(1)',
                })),

            ], { optional: true }),
        ]),
    ]),
    transition(':leave', [
        group([
            query('.active-text', [
                style({
                    left: '70px',
                    opacity: 1
                }),
                animate(2000)
            ], { optional: true }),
        ])
    ]),
]);


export const ChangeSlotToReservedState = trigger('ChangeSlotToReservedState', [

    transition(':leave', [
          style({
               opacity: 1
          }),
        group([
            query('.active__content__van', [
               style({
                    right: '0%',
                }),
                animate('250ms ease-out', style({
                    right: '-1%',
                })),
                animate('250ms ease-out', style({
                    right: '-6%',
                })),
                animate('250ms ease-out', style({
                    right: '2%',
                })),
                animate('200ms ease-out', style({
                    right: '-20%',
                })),
            ], { optional: true }),
            query('.active__content__text', [
               animate('200ms ease-out', style({
                    left: '10%',
                    opacity: 0
               })),
            ], { optional: true }),
            query('.active-text', [
                style({
                    left: '70px',
                    opacity: 0
                }),
                animate(2000)
            ], { optional: true }),
        ])
    ]),


]);

// export const ChangeSlotState = trigger('ChangeSlotState', []);

export const BounceState = trigger('BounceState', [
    state('top', style({
        top: '-10px',
    })),
    state('bottom', style({
        top: '0',
    })),
    transition('top <=> bottom', animate('0.6s 100ms ease-in')),
]);

export const ShowState = trigger('ShowState', [
    transition(':enter', [
        style({
            opacity: 0
        }),
        animate(400)
    ]),
    // transition(':leave', animate(500, style({
    //     opacity: 0
    // })))
]);

export const SliderTimeAnimation = trigger('SliderTimeAnimation', [
    transition('SHOW<=>START_TIME_SLIDE_TOP', [
        group([
            query('.start_time', [
                animate(80, style({
                    top: '20px'
                })),
                animate(1, style({
                    top: '-20px'
                })),
                animate(110, style({
                    top: '20px',
                })),
                animate(1, style({
                    top: '-20px'
                })),
                animate(110, style({
                    top: '20px',
                })),
                animate(1, style({
                    top: '-20px'
                })),
                animate(110, style({
                    top: '20px'
                })),
                animate(1, style({
                    top: '-20px'
                }))
            ], { optional: true })
        ])
    ]),
    transition('SHOW<=>START_TIME_SLIDE_BOTTOM', [
        group([
            query('.start_time', [
                animate(110, style({
                    top: '-20px'
                })),
                animate(1, style({
                    top: '20px'
                })),
                animate(110, style({
                    top: '-20px',
                })),
                animate(1, style({
                    top: '20px'
                })),
                animate(110, style({
                    top: '-20px',
                })),
                animate(1, style({
                    top: '20px',
                })),
                animate(110, style({
                    top: '-20px'
                })),
                animate(1, style({
                    top: '20px'
                }))
            ], { optional: true })
        ])
    ]),
    transition('SHOW<=>END_TIME_SLIDE_TOP', [
        group([
            query('.end_time', [
                animate(110, style({
                    top: '20px'
                })),
                animate(1, style({
                    top: '-20px'
                })),
                animate(110, style({
                    top: '20px',
                })),
                animate(1, style({
                    top: '-20px'
                })),
                animate(110, style({
                    top: '20px',
                })),
                animate(1, style({
                    top: '-20px',
                })),
                animate(110, style({
                    top: '20px'
                })),
                animate(1, style({
                    top: '-20px'
                }))
            ], { optional: true })
        ])
    ]),
    transition('SHOW<=>END_TIME_SLIDE_BOTTOM', [
        group([
            query('.end_time', [
                animate(110, style({
                    top: '-20px'
                })),
                animate(1, style({
                    top: '20px'
                })),
                animate(110, style({
                    top: '-20px',
                })),
                animate(1, style({
                    top: '20px'
                })),
                animate(110, style({
                    top: '-20px'
                })),
                animate(1, style({
                    top: '20px',
                })),
                animate(110, style({
                    top: '-20px'
                })),
                animate(1, style({
                    top: '20px'
                }))
            ], { optional: true })
        ])
    ])
]);


export const ChangeSlotState = trigger('slotTimeState', [
    state('SHOW_TOP', style({
        top: '-20px',
    })),
    state('HIDE_TOP', style({
        top: '-20px',
    })),
    state('SHOW', style({
        top: '0px',
    })),
    state('HIDE_BOTTOM', style({
        top: '20px',
    })),
    state('SHOW_BOTTOM', style({
        top: '20px',
    })),
    transition('SHOW_TOP<=>SHOW', animate('150ms linear')),
    transition('SHOW<=>HIDE_BOTTOM', animate('150ms linear')),
    transition('HIDE_TOP<=>SHOW', animate('150ms linear')),
    transition('SHOW_TOP<=>HIDE_BOTTOM', animate('0ms')),
    transition('HIDE_BOTTOM=>HIDE_TOP', animate('0ms'))
]);

export const ShowEcoSlotState = trigger('ShowEcoSlotState', [
    transition(':enter', [
        group([
            query('.wave', [
                style({
                    opacity: 0
                }),
                animate(600, style({
                    opacity: 1
                })),
            ], { optional: true }),
            query('.info-up .leaf', [
                style({
                    top: '60px',
                    opacity: 0
                }),
                animate(300, style({
                    top: '-10px',
                    opacity: 0.4
                })),
                animate(300, style({
                    top: '0px',
                    opacity: 1
                })),
            ], { optional: true }),
            query('.info-down .leaf', [
                style({
                    top: '-60px',
                    opacity: 0
                }),
                animate(300, style({
                    top: '10px',
                    opacity: 0.4
                })),
                animate(300, style({
                    top: '-10px',
                    opacity: 1
                })),
            ], { optional: true }),
        ]),
    ]),
]);

export const ValueState = trigger('ValueState', [
    transition(':increment', [
        style({
            opacity: 0
        }),
        animate(600, style({
            opacity: 1
        })),
    ]),
    transition(':decrement', [

        style({
            opacity: 0
        }),
        animate(600, style({
            opacity: 1
        })),

    ]),
]);


export const ShiftState = trigger('ShiftState', [
    state('MORNING', style({
        top: '-20px',
    })),
    state('AFTERNOON', style({
        top: '-20px',
    })),
    transition('MORNING<=>AFTERNOON', [

        style({
            opacity: 0
        }),
        animate(0, style({
            opacity: 1
        })),

    ]),

]);

