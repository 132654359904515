import { Directive, HostListener, ContentChildren, ElementRef, QueryList, AfterContentInit } from '@angular/core';
import { SlotSelectionService } from '../services/slot-selection.service';
import { StepPickerUIComponent } from '../components/picker-ui/step/step.component';
import { PickerUIInterface } from '../interfaces/pickerUI-interface';
import { stepsConfigurations } from '../components/picker-ui/step/tmp';
import { StepInterface } from '../interfaces/step-interface';
import { ReservationsWizardService } from '../../shared/services/reservation-wizard.service';
import { ReservationInterface } from '../../shared/interfaces/reservation-interface';
import { SlotInterface } from '../../shared/interfaces/slot-interface';
import * as moment from 'moment';
import { WidgetMode } from '../../shared/enums/widget-mode.enum';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../../auth/services/auth.service';

@Directive({
  selector: '[appOverlayListener]'
})
export class OverlayListenerDirective implements AfterContentInit {

  sliderPositionTop: number;
  sliderPositionTopOldValue: number;

  stepConfiguration: PickerUIInterface;

  reservation: ReservationInterface;

  @ContentChildren(StepPickerUIComponent, { read: ElementRef }) contentChildren: QueryList<StepPickerUIComponent>;
  private steps: ElementRef<StepPickerUIComponent>[];

  constructor(
    private readonly slotSelectionService: SlotSelectionService,
    private readonly wizardService: ReservationsWizardService,
    private readonly fireStoreService: AngularFirestore,
    private readonly authService: AuthService
  ) { }

  ngAfterContentInit(): void {
    this.slotSelectionService.sliderPositionTop$.subscribe((sliderPositionTop) => {
      this.sliderPositionTop = sliderPositionTop;
    });

    this.wizardService.getModel().subscribe((reservation: ReservationInterface) => {
      this.reservation = reservation;
   });


    this.steps = this.contentChildren['_results'];

  }

  @HostListener('mousemove', ['$event.offsetY', '$event'])
  onMousemove(postitionY, event) {

    const shift = event.target.dataset.shift;
    const sliderPostion = Math.round(postitionY / 30);

    const steps: StepInterface[] = this.slotSelectionService.availableSlots.shifts.find(s => s.type === shift).steps;

    if (this.reservation.slot !== undefined && this.reservation.slot.slot !== null && event.target.dataset.shift !== this.reservation.slot.shiftType) {
      this.slotSelectionService.sliderPositionTop$.next(-1);
      return;
    }

    if (this.reservation.mode === WidgetMode.ACTIVE) {
        if (sliderPostion >= 1 && (!steps[sliderPostion] || !steps[sliderPostion].availability || !steps[sliderPostion - 1].availability)) {
          return;
        } else {
          this.sliderPositionTopOldValue = -1;
          this.slotSelectionService.sliderPositionTop$.next(-1);
        }
    }

    if (sliderPostion !== this.sliderPositionTopOldValue) {
      if (sliderPostion === 0) {
        this.sliderPositionTopOldValue = sliderPostion;
        this.slotSelectionService.sliderPositionTop$.next(sliderPostion * 30);
       } else if (sliderPostion !== 0 ) {
        this.sliderPositionTopOldValue = sliderPostion;
        this.slotSelectionService.sliderPositionTop$.next(sliderPostion * 30 - 30 );
       }
    }
  }

  @HostListener('click', ['$event.offsetY', '$event'])
  onClick(postitionY, event) {

    const shift = event.target.dataset.shift;
    const sliderPostion = Math.round(postitionY / 30);

    console.log(sliderPostion);

    const steps: StepInterface[] = this.slotSelectionService.availableSlots.shifts.find(s => s.type === shift).steps;

    if (steps[sliderPostion - 1] && steps[sliderPostion - 1].availability && (this.reservation.slot === undefined || this.reservation.slot.startTime == null)) {

      const reservation = {
        date: steps[sliderPostion].date,
        mode: WidgetMode.ECO_SLOTS,
        extendedSlotTop: false,
        extendedSlotBottom: false,
        slot: steps[sliderPostion - 1] as Partial<SlotInterface>,
        sliderCurrentPosition: this.sliderPositionTop
      } as Partial<ReservationInterface>;

      this.sendEvent(reservation);
      this.wizardService.updateModel(reservation);
      return;
    }

    console.log(2);

    /*
    *     Check if eco slot is in proper shiftType. Reservation in MORNING click in AFTERNOON;
    */
    if (this.reservation.slot.shiftType !== steps[0].shiftType) {
      return;
    }

    console.log(3);

    const actualReservationTime = moment(`${this.reservation.date} ${this.reservation.slot.startTime}`);
    const selectedReservationTime = moment(`${this.reservation.date} ${(sliderPostion === 0) ? steps[sliderPostion].startTime : steps[sliderPostion - 1].startTime}`);


    const checkIsNotSliderPosition = selectedReservationTime.diff(actualReservationTime) / 1000 / 60;
    if (checkIsNotSliderPosition > 0 && checkIsNotSliderPosition <= 59 ) {
      return;
    }

    console.log(4);

    if (sliderPostion === 0) {
      this.wizardService.updateModel({
        extendedSlotTop: true,
        date: steps[sliderPostion].date,
        mode: WidgetMode.ECO_SLOTS,
        slot: {
          ...this.reservation.slot,
          startTime: steps[sliderPostion].startTime
        } as Partial<SlotInterface>
      } as Partial<ReservationInterface>);
      return;
    }

    if (selectedReservationTime.isBefore(actualReservationTime)) {
      this.wizardService.updateModel({
        extendedSlotTop: true,
        date: steps[sliderPostion].date,
        mode: WidgetMode.ECO_SLOTS,
        slot: {
          ...this.reservation.slot,
          startTime: steps[sliderPostion - 1].startTime
        } as Partial<SlotInterface>
      } as Partial<ReservationInterface>);

    } else if (selectedReservationTime.isAfter(actualReservationTime)) {
      this.wizardService.updateModel({
        extendedSlotBottom: true,
        date: steps[sliderPostion - 1].date,
        mode: WidgetMode.ECO_SLOTS,
        slot: {
          ...this.reservation.slot,
          endTime: steps[sliderPostion - 2].endTime
        } as Partial<SlotInterface>
      } as Partial<ReservationInterface>);
    }
  }


  sendEvent(reservation) {
    return new Promise<any>((resolve, reject) => {
      this.fireStoreService
          .collection('reserved_slots')
          .doc(this.authService.userData.uid)
          .collection('reserved_slots')
          .add(reservation)
          .then(res => {}, err => reject(err));
  });
  }

}
