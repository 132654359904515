import { Component, OnInit, HostListener, Input, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { SlotSelectionService } from './../../../../slot-selection/services/slot-selection.service';
import { ReservationsWizardService } from '../../../../shared/services/reservation-wizard.service';
import { ReservationInterface } from '../../../../shared/interfaces/reservation-interface';
import { WidgetMode } from '../../../../shared/enums/widget-mode.enum';
import { ShowState, ShowStateEcoSlots } from '../../../../slot-selection/animations/state.animation';
import { ChangeSlotToReservedState, ChangeSlotToActiveState, SliderTimeAnimation } from '../../../..//slot-selection/animations/slider.animation';
import { filter } from 'rxjs/internal/operators/filter';
import * as _ from 'lodash';
import { StepInterface } from '../../../../slot-selection/interfaces/step-interface';
import { Subscription } from 'rxjs';

export enum SliderTimeAnimationState {
    SHOW = 'SHOW',
    START_TIME_SLIDE_TOP = 'START_TIME_SLIDE_TOP',
    START_TIME_SLIDE_BOTTOM = 'START_TIME_SLIDE_BOTTOM',
    END_TIME_SLIDE_TOP = 'END_TIME_SLIDE_TOP',
    END_TIME_SLIDE_BOTTOM = 'END_TIME_SLIDE_BOTTOM'
}


@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  animations: [ShowState, ChangeSlotToReservedState, ChangeSlotToActiveState, SliderTimeAnimation, ShowStateEcoSlots]
})
export class SliderComponent implements OnInit, OnDestroy {

    @Input() sliderHeight: number;

    sliderTimeAnimationState = SliderTimeAnimationState.SHOW;
    sliderPostionTop;

    wizardServiceSubscription$: Subscription;
    slotSelectionServiceSubscription$: Subscription;

    reservation: ReservationInterface;

    get isReserved(): boolean {
        return this.reservation.mode === WidgetMode.RESERVED;
    }

    get isActive(): boolean {
        return this.reservation.mode === WidgetMode.ACTIVE || this.reservation.mode === undefined;
    }

    get isEcoSlots(): boolean {
        return this.reservation.mode === WidgetMode.ECO_SLOTS;
    }

    constructor(
        private readonly slotSelectionService: SlotSelectionService,
        private readonly wizardService: ReservationsWizardService
    ) { }

    ngOnInit() {

        this.wizardServiceSubscription$ = this.wizardService.getModel()
            .pipe(
                filter(reservation => reservation !== undefined),
                filter(reservation => !_.isEqual(reservation, this.reservation))
            )
            .subscribe((reservation: ReservationInterface) => {

                if (this.reservation && reservation.slot && this.reservation.slot &&
                    this.reservation.slot.startTime !== null && this.reservation.slot.endTime !== null) {
                    if (this.reservation.slot.startTime !== reservation.slot.startTime) {
                        this.sliderTimeAnimationState = SliderTimeAnimationState.START_TIME_SLIDE_TOP;
                    } else if (this.reservation.slot.endTime !== reservation.slot.endTime) {
                        this.sliderTimeAnimationState = SliderTimeAnimationState.END_TIME_SLIDE_BOTTOM;
                    }
                }
                this.reservation = reservation;
                this.sliderPostionTop = this.reservation.sliderCurrentPosition;
            });

        this.slotSelectionServiceSubscription$ = this.slotSelectionService.sliderPositionTop$.subscribe(
            (position: number) => {

                if (position !== -1) {
                    if (this.isActive) {
                        this.sliderPostionTop = position;
                    }
                } else {
                    if (this.isActive) {
                        this.sliderPostionTop = this.reservation.sliderCurrentPosition;
                    }
                }

            }
        );
    }

    decline(): void {
        console.log('Model Updated: SliderComponent');
        this.wizardService.updateModel({
            mode: WidgetMode.ACTIVE,
            sliderCurrentPosition: this.reservation.sliderCurrentPosition - 30,
            slot: {
                startTime: null,
                endTime: null,
                slot: null,
                id: null,
            } as Partial<StepInterface>
        } as Partial<ReservationInterface>);

        this.slotSelectionService.sliderPositionTop$.next(this.sliderPostionTop + 30);
    }

    animEnd() {
        this.sliderTimeAnimationState = SliderTimeAnimationState.SHOW;
    }

    ngOnDestroy() {
        console.log('ngOnDestroy: SliderComponent');

        if (this.wizardServiceSubscription$) {
            this.wizardServiceSubscription$.unsubscribe();
        }

        if (this.slotSelectionServiceSubscription$) {
            this.slotSelectionServiceSubscription$.unsubscribe();
        }
    }

}
