import { Mode } from './enums/mode';
import { Entity } from '../helpers/vo/entity';
import { Serializable } from '../helpers/vo/serializable';
import { JwtHelperService } from '@auth0/angular-jwt';

import Customer from './customer';
import Location from './locations';

import * as _ from 'lodash';
import { environment } from '../../environments/environment';
import { Lang } from './enums/lang';
import Reservation from './reservation';

export default class Configuration extends Entity implements Serializable<Configuration> {

    private TAG = '[Configuration]';

    userToken: string;
    locationList: Location[] = [];
    selectedLocation: Location;
    basketAmount: number;
    mode: Mode;
    customer: Customer;
    lang: Lang;
    reservation: Reservation | null;


    deserialize(data: any): Configuration {

        this.userToken = _.get(data, 'userToken');


        (_.get(data, 'locationList', [])).map((location: any) => this.locationList.push(new Location().deserialize(location)));

        this.selectedLocation = new Location().deserialize(_.get(data, 'selectedLocation'));
        this.basketAmount = _.get(data, 'basketAmount', 0);
        this.mode = _.get(data, 'mode', Mode.NEW);
        this.lang = _.get(data, 'lang', Lang.PL);
        this.customer = new Customer().parse(_.get(data, 'userToken'));
        //this.customer = new Customer().deserialize(_.get(data, 'customer'));
        this.reservation = new Reservation().deserialize(_.get(data, 'reservation'));

        return this;
    }

    serialize() {
        const serialized = {
            userToken: this.userToken,
            locationList: this.locationList,
            selectedLocation: this.selectedLocation,
            basketAmount: this.basketAmount,
            mode: this.mode,
            customer: this.customer
        };
        return serialized;
    }

    get isValid(): boolean {

        // const helper = new JwtHelperService();
        // let tmp;

        // try {
        //     tmp = helper.isTokenExpired(this.userToken);
        // } catch (e) {
        //     tmp = true;
        // }

        // if (environment.mode === 'DEVELOPMENT') {
        //     tmp = false;
        // }

        // if (tmp) {
        //     console.error(`${this.TAG} Configuration object is invalid. User token is incorrect!`, this);
        //     return false;
        // } else {
        //     return true;
        // }

        return true;
    }
}
