export const PL = {
	"Add another address": "Dodaj nowy adres",
	"For free delivery is missing": "Do darmowej dostawy brakuje",
	"Loading available slots": "Ładowanie dostępnych slotów",
	"Occurred error with fetching available slots": "Wystąpił problem podczas pobierania dostępnych godzin dostaw",
	"Select delivery": "Wybierz dostawę",
	"Something has wrong! Please try again": "Wystąpił błąd spróbuj ponownie",
	"The reservation will expire on": "Termin zarezerwowany do",
	"Today": "Dziś",
	"Tomorrow": "Jutro",
	"Your delivery": "Twoja dostawa"
}