import { Component, OnInit } from '@angular/core';
import { ReservationsWizardService } from '../../../shared/services/reservation-wizard.service';
import { ReservationInterface } from '../../../shared/interfaces/reservation-interface';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { WidgetMode } from '../../../shared/enums/widget-mode.enum';
import { StepInterface } from '../../interfaces/step-interface';
import { SlotSelectionService } from '../../services/slot-selection.service';

@Component({
  selector: 'app-reservation-summary',
  templateUrl: './reservation-summary.component.html',
  styleUrls: ['./reservation-summary.component.scss']
})
export class ReservationSummaryComponent implements OnInit {

  reservation$: Observable<ReservationInterface>;
  reservation: ReservationInterface;

  constructor(
    private readonly wizardService: ReservationsWizardService,
    private readonly slotSelectionService: SlotSelectionService,
  ) { }

  ngOnInit() {
    this.reservation$ = this.wizardService.getModel().pipe(
      filter(reservation => reservation !== undefined),
      tap(reservation => this.reservation = reservation)
    );
  }

  decline(): void {
    console.log('Model Updated: SliderComponent');
    this.wizardService.updateModel({
        mode: WidgetMode.ACTIVE,
        sliderCurrentPosition: this.reservation.sliderCurrentPosition - 30,
        slot: {
            startTime: null,
            endTime: null,
            slot: null,
            id: null,
        } as Partial<StepInterface>
    } as Partial<ReservationInterface>);
  }
}
