export const environment = {
    production: false,
    mode: 'DEVELOPMENT',
    apiUrl: 'https://e0ebd6ae-9b74-4438-a968-468b879768c2.mock.pstmn.io',
    version: '1.0.16',
    pathImageURLS: './assets/img/',
    host: 'https://uat.open-routing.com/',
    prefix: 'booking',
    apiVerison: 'v1',
    pickerPath: `//picker.uat.open-routing.com/`,
    pathToTranslations: '/assets/i18n/',
    firebaseConfig: {
        apiKey: 'AIzaSyBEFo9mgE-dQQHs7bP7igXubZEscO_mp1s',
        authDomain: 'slotpickerdemo.firebaseapp.com',
        databaseURL: 'https://slotpickerdemo.firebaseio.com',
        projectId: 'slotpickerdemo',
        storageBucket: 'slotpickerdemo.appspot.com',
        messagingSenderId: '758077752221',
        appId: '1:758077752221:web:9873d1a348fcc36734a574',
        measurementId: 'G-9611MXPSGG'
    }
};
