export class Entity {
    public serialize() {
        return {};
    }

    public toJson(spaces = 2) {
        let data = this.serialize();
        return JSON.stringify(data, null, spaces);
    }
}
