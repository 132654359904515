import { Entity } from '../helpers/vo/entity';
import { Serializable } from '../helpers/vo/serializable';
import * as _ from 'lodash';

export default class Coordinates extends Entity implements Serializable<Coordinates> {

    lat: number;
    lng: number;

    deserialize(data: any): Coordinates {

        this.lat = _.get(data, 'lat', 0);
        this.lng = _.get(data, 'lng', 0);

        return this;
    }

    serialize() {
        const serialized = {
            lat: this.lat,
            lng: this.lng
        };
        return serialized;
    }
}
