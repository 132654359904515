import { Component, OnInit, HostListener, Input, ViewChild, OnDestroy } from '@angular/core';
import { PickerUIInterface } from '../../interfaces/pickerUI-interface';
import { stepsConfigurations } from '../picker-ui/step/tmp';
import { ReservationsWizardService } from './../../../shared/services/reservation-wizard.service';
import { filter, tap, shareReplay, map } from 'rxjs/operators';
import { ReservationInterface } from './../../../shared/interfaces/reservation-interface';
import { SlotSelectionService } from '../../services/slot-selection.service';
import { Settings } from './../../../components/choose-slot/settings/settings.interface';
import { WidgetMode } from '../../../shared/enums/widget-mode.enum';
import { ShowStateEcoSlots } from '../../animations/state.animation';
import { ShowState } from '../../animations/slider.animation';
import { ShiftType } from '../../../components/choose-slot/entities/interfaces';
import { Subscription, Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import * as _ from 'lodash';

@Component({
  selector: 'app-slot-selection',
  templateUrl: './slot-selection.component.html',
  styleUrls: ['./slot-selection.component.scss'],
  animations: [ShowStateEcoSlots, ShowState]
})
export class SlotSelectionComponent implements OnInit, OnDestroy {

  @Input() slotpickerSettings: Settings;
  @ViewChild('slider', {static: true}) slider: any;

  widgetMode: WidgetMode;

  stepConfiguration$: Observable<PickerUIInterface | void>;
  loader = true;

  reservation: ReservationInterface;

  wizardServiceSubscription$: Subscription;
  slotSelectionServiceSubscription$: Subscription;

  sliderPostionTop = 0;
  maskPostionTop = 0;

  selectedDate: string;

  get isReserved(): boolean {
    return this.reservation.mode === WidgetMode.RESERVED;
  }

  get isEcoSlots(): boolean {
    return this.reservation.mode === WidgetMode.ECO_SLOTS;
  }

  get isActive(): boolean {
      return this.reservation.mode === WidgetMode.ACTIVE || this.reservation.mode === undefined;
  }

  get isPreBooked(): boolean {
    return this.reservation.mode === WidgetMode.PRE_BOOKED;
  }

  constructor(
    private readonly wizardService: ReservationsWizardService,
    private readonly slotSelectionService: SlotSelectionService,
    private readonly fireStoreService: AngularFirestore
  ) { }

  ngOnInit() {

    this.slotSelectionServiceSubscription$ = this.slotSelectionService.sliderPositionTop$.subscribe(
      (position: number) => {

        if (position !== -1) {
          if (this.sliderPostionTop !== position) {
            this.sliderPostionTop = position;

            if (this.reservation.extendedSlotTop && this.isEcoSlots && position < this.reservation.sliderCurrentPosition) {
              console.log('top');
              this.maskPostionTop = this.reservation.sliderCurrentPosition;
            } else if (this.reservation.extendedSlotBottom && this.isEcoSlots && position > this.reservation.sliderCurrentPosition) {
              console.log('bottom');
              this.maskPostionTop = this.reservation.sliderCurrentPosition + 5;
            }else {
              this.maskPostionTop = (this.isEcoSlots) ? position + 6 : position + 30 ;
            }

          }
        } else {
          this.maskPostionTop = (this.isEcoSlots) ? this.reservation.sliderCurrentPosition : 30;
          this.sliderPostionTop = -1;
        }
      }
    );

    this.wizardServiceSubscription$ = this.wizardServiceSubscription$ = this.wizardService.getModel()
      .pipe(
          filter(reservation => reservation !== undefined),
          tap(reservation => this.reservation = reservation)
      ).subscribe();
    }

  loadData() {
    this.stepConfiguration$ = this.slotSelectionService.getAvailableSlots(this.selectedDate).pipe(
      filter(steps => steps !== undefined),
      tap((a) => console.log(a)),
      tap(() => this.loader = false)
    );
  }

  reservedSlot(): void {
    console.log('Model Updated: SliderComponent');
    this.widgetMode = WidgetMode.RESERVED;
    this.wizardService.updateModel({
        mode: WidgetMode.ECO_SLOTS
    } as Partial<ReservationInterface>);
  }

  showComponent(shiftType: ShiftType) {
    return (this.isPreBooked || this.isReserved || this.isEcoSlots) && this.reservation.slot.shiftType === shiftType && this.reservation.date === this.selectedDate;
  }

  selectedDayChange(date) {

    if (this.reservation.mode !== WidgetMode.ACTIVE && this.reservation.date !== date ) {
      this.wizardService.updateModel({
          mode: WidgetMode.RESERVED
        } as Partial<ReservationInterface>);
    } else if (this.reservation.mode !== WidgetMode.ACTIVE && this.reservation.date === date){
      setTimeout(() => {

      
        this.wizardService.updateModel({
          mode: WidgetMode.ECO_SLOTS
        } as Partial<ReservationInterface>);

      },1000);
    }

    this.selectedDate = date;
    this.loader = true;
    this.loadData();
  }

  ngOnDestroy(): void {
    if (this.wizardServiceSubscription$) {
      this.wizardServiceSubscription$.unsubscribe();
    }
    if (this.slotSelectionServiceSubscription$) {
      this.slotSelectionServiceSubscription$.unsubscribe();
    }
  }


  sendSlots() {
    const stepConfiguration = stepsConfigurations as PickerUIInterface;

    return new Promise<any>((resolve, reject) => {
      this.fireStoreService
          .collection('available_slots')
          .doc('slots_3')
          .set(stepConfiguration)
          .then(res => {}, err => reject(err));
  });
  }
}
