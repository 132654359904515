import { Routes, RouterModule, } from '@angular/router'
import { AuthComponent } from './auth.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { NgModule } from '@angular/core';

const routes: Routes = [
    {
        path: '',
        component: AuthComponent,
        children: [
            { path: '', component: LoginComponent },
            {
                path: 'login',
                component: LoginComponent
            },
            {
                path: 'register',
                component: RegisterComponent
            },
        ],
    },

    {
        path: '**',
        component: AuthComponent,
      },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
    ],
    exports: [
        RouterModule
    ]
})
export class AuthRoutingModule { }
