import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from '../auth/services/auth.service';
import { AngularFireAuthModule, AngularFireAuth } from '@angular/fire/auth';
import { map } from 'rxjs/internal/operators/map';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly afb: AngularFireAuth
  ) { }

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authService.authState$.pipe(map(state => {
      if (state !== null) {
        return true;
      }
      this.router.navigate(['/auth/login'], {skipLocationChange: true});
      return false;
    }));
  }
}
