import { Component } from '@angular/core';
import { Settings } from '../choose-slot/settings/settings.interface';
import { ReservationsWizardService } from '../../shared/services/reservation-wizard.service';
import { ReservationInterface } from '../../shared/interfaces/reservation-interface';
import * as moment from 'moment';
import { AuthService } from '../../auth/services/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {

    slotpickerSettings: Settings;

    constructor(
        private readonly wizardService: ReservationsWizardService,
        private readonly authService: AuthService,
        private readonly router: Router
    ) { }

    slotpickerSettingsChanged(settings: Settings) {
        this.slotpickerSettings = settings;
        console.log('Model updated: LayoutComponent');
        this.wizardService.updateModel({
            //date: moment().format('YYYY-MM-DD'),
            settings: this.slotpickerSettings
        } as Partial<ReservationInterface>);
    }

    signOut() {
        this.authService.signOut();
        this.router.navigate(['/auth/login'], {skipLocationChange: true})
    }
}
