import { Entity } from '../../../helpers/vo/entity';
import { Serializable } from '../../../helpers/vo/serializable';
import { ShiftElement, ShiftType } from './interfaces';
import Step from './step';
import * as _ from 'lodash';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';

// import Slot from 'src/app/entities/slot';

export default class Shift extends Entity implements Serializable<Shift> {

    stepInHour = 3;
    stepHeight = 30;
    stepsInShifgt = 16;
    shifts: {
        type: ShiftType,
        steps: Step[]
    }[] = [];

    public deserialize(data: any) {

        const rawShift: ShiftElement[] = [];

        // console.log(JSON.parse(data))

        const steps: Step[] = [];
        defaultSlots.forEach((slot, index) => {

            const shift = (parseFloat(slot.split(':')[0]) < 15)
                ? ShiftType.MORNING
                : ShiftType.AFTERNOON;

            const res = _.find(data, (d: any) => d.startTime === slot);

            if (_.isUndefined(res)) {

                const tmp = {
                    //cutOff: '2019-03-09T23:00:00',
                    //date: '',
                    endTime: `${moment(`2019-03-14T${slot}`).add(1, 'hours').format('HH:mm:ss')}`,
                    slot: `${moment(`2019-03-14T${slot}`).format('HH:mm')}-${moment(`2019-03-14T${slot}`).add(1, 'hours').format('HH:mm')}`,
                    availability: this.lastStepAvailble(slot, data),
                    slotId: null,
                    startTime: slot
                };


                if (index !== 0) {
                    if (steps[index - 1]['availability'] !== tmp['availability']) {
                        steps[index - 1].isLast = true;
                    } else {
                        steps[index - 1].isLast = false;
                    }
                }
                steps.push(new Step().deserialize(tmp, index, shift));
            } else {

                res['availability'] = true;

                if (index !== 0) {
                    if (steps[index - 1]['availability'] !== res['availability']) {
                        steps[index - 1].isLast = true;
                    } else {
                        steps[index - 1].isLast = false;
                    }
                }
                steps.push(new Step().deserialize(res, index, shift));
            }
        });

        /*
         *  Spliting slots to two shifts.
         */
        const morningSlots = _.filter(steps, (d: any) => parseFloat(d['endTime'].split(':')[0]) <= 15);
        const afternoonSlots = _.filter(steps, (d: any) => parseFloat(d['endTime'].split(':')[0]) > 15);

        this.shifts.push({
            type: ShiftType.MORNING,
            steps: morningSlots
        });

        this.shifts.push({
            type: ShiftType.AFTERNOON,
            steps: afternoonSlots
        });

        return this;
    }

    public serialize() {
        const serialized = {};
        return serialized;
    }

    get getMorningShift() {
        const selectedShift = this.shifts.find((shift: any) => shift.type === ShiftType.MORNING);
        return selectedShift.steps;
    }

    get getAfternoonShift() {
        const selectedShift = this.shifts.find((shift: any) => shift.type === ShiftType.AFTERNOON);
        return selectedShift.steps;
    }

    getSlotHours(step: number, type: ShiftType) {
        if (type === ShiftType.MORNING) {
            return this.shifts.find((shift: any) => shift.type === ShiftType.MORNING).steps[step];
        } else if (type === ShiftType.AFTERNOON) {
            return this.shifts.find((shift: any) => shift.type === ShiftType.AFTERNOON).steps[step];
        }
    }

    private lastStepAvailble(slot, data) {
        if (slot === '13:30:00') {
            const step: Step = _.find(data, (s: Step) => s.startTime === '13:00:00');
            return !_.isUndefined(step);
        }
        if (slot === '22:30:00') {
            const step: Step = _.find(data, (s: Step) => s.startTime === '22:00:00');
            return !_.isUndefined(step);
        }

        if (environment.mode === 'DEVELOPMENT') {
            return true;
        } else {
            return false;
        }

    }
}


export const defaultSlots = [
    '06:00:00', '06:30:00',
    '07:00:00', '07:30:00',
    '08:00:00', '08:30:00',
    '09:00:00', '09:30:00',
    '10:00:00', '10:30:00',
    '11:00:00', '11:30:00',
    '12:00:00', '12:30:00',
    '13:00:00', '13:30:00',
    //'14:00:00', '14:30:00',
    '15:00:00', '15:30:00',
    '16:00:00', '16:30:00',
    '17:00:00', '17:30:00',
    '18:00:00', '18:30:00',
    '19:00:00', '19:30:00',
    '20:00:00', '20:30:00',
    '21:00:00', '21:30:00',
    '22:00:00', '22:30:00',
];