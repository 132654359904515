import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/modules/app.module';
import {environment} from './environments/environment';
import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

// window['AngularApp'] = (token: string, successCallback?: Function, failureCallback?: Function) => {
  
//   (successCallback) ? window['AngularApp']['successCallback'] = successCallback : false;
//   (failureCallback) ? window['AngularApp']['failureCallback'] = failureCallback : false;

//   window.localStorage.setItem('token', token); 

//   platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.log(err));
// }

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
