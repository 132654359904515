import { Pipe, PipeTransform } from '@angular/core';
import { AddressInterface } from '../interfaces/address-interface';

@Pipe({
  name: 'locationLabel'
})
export class LocationLabelPipe implements PipeTransform {

  transform(address: AddressInterface): string {
    return `${address.street} ${address.houseNO}, ${address.town}`;
  }

}
