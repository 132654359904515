import { Component, Input, OnInit, HostListener, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { StepElement } from "../entities/interfaces";
import Step from "../entities/step";
import * as _ from 'lodash';
import { trigger, transition, style, animate, state } from "@angular/animations";
import * as moment from 'moment';
@Component({
    selector: 'step-component',
    templateUrl: './step.component.html',
    styleUrls: ['./step.component.scss'],
    animations: [
        trigger('showState', [
            transition(':enter', [
                style({
                    opacity: 0,
                    right: '-2px'
                }),
                animate(350)
            ]),
            transition(':leave', animate(350, style({
                opacity: 0,
                right: '0px'
            })))
        ]),
        trigger('slideStepState', [
            state('show', style({
                width: '100%',
            })),
            state('hide', style({
                width: '30px'
            })),
            transition('show<=>hide', animate('2000ms')),
        ]),
    ]
})
export class StepComponent implements OnInit {

    @Input() stepData: Step;
    // @Output() selectedStep: EventEmitter<Object> = new EventEmitter<Object>();
    @Input() index: number;
    @Input() reservedSlotByUser: Step | null;

    mode = 'normal';
    isSelected = false;
    isHover = false;

    showAdditionalStep = 'hide';

    locale;

    constructor() {
       this.locale = moment.locale('pl-pl');
    }

    ngOnInit() {
        //console.log(this.stepData)
    }

    // ngOnChanges(changes: SimpleChanges) {
    //     if (!changes.first) {

    //         this.reservedSlotByUser = changes.reservedSlotByUser.currentValue;
    //         this.mode = (!_.isNull(this.reservedSlotByUser)) ? 'additional-slots' : 'normal';
    //     }
    // }

    // @HostListener('mousemove', ['$event.clientY', '$event.clientX', '$event'])
    // public mousemove(clientY?, clientX?, event?): void {
    //     this.selectedStep.emit({ step: this.stepData, 'clientY': clientY });
    //     this.isHover = true;

    //     if ((Math.round((clientY - 280) / 30) - this.stepData.index) === 2){
    //         this.isHover = false;
    //     }
    //     this.calculateHeight();
    // }

    // @HostListener('mouseleave', ['$event.clientY', '$event.clientX', '$event'])
    // public mouseleave(clientY?, clientX?, event?): void {
    //     this.isHover = false;
    // }

    // get additionalMode() {
    //     return (this.mode === 'additional-slots' && this.reservedSlotByUser.shiftType === this.stepData.shiftType) ? true : false;
    // }

    // selectedAdditionalSlot() {
    //     this.isSelected = !this.isSelected;
    //     this.showAdditionalStep = (this.isSelected) ? 'show' : 'hide';
    // }

    // calculateHeight() {
    //     const edgeOfReservedSlot = (this.reservedSlotByUser) ? parseFloat(this.reservedSlotByUser.startTime.split(':')[0]) : 0;
    //     const edgeOfCurrentStep = (this.stepData) ? parseFloat(this.stepData.startTime.split(':')[0]) : 0;

    //     return (this.isSelected || this.isHover) ? ((edgeOfReservedSlot - edgeOfCurrentStep + 1) * 60) : 60;
    // }
}
