import { Component, Input, OnInit, HostListener, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { ShiftType } from '../entities/interfaces';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AppService } from '../../../services/app.service';
import Step from '../entities/step';
import * as _ from 'lodash';
import { ShowState } from '../animations';
import { SlotsConfig } from '../slots.config';
import Slot from '../../../entities/slot';


@Component({
    selector: 'overlay-component',
    templateUrl: './overlay.component.html',
    styleUrls: ['./overlay.component.scss'],
    animations: [ShowState]
})
export class OverlayComponent implements OnInit, OnDestroy {

    TAG = '[OverlayComponent]';

    @Input() allSlots: Step[];
    @Input() extendSlot: number;
    @Input() maskPosition = -1;
    @Input() isReserved;
    @Input() showOverlay;
    @Input() selectedDate;

    private _data = new BehaviorSubject<ShiftType>(null);

    public show = true;

    private lastSelectedSlot: Slot | null = null;

    private lastStartTimeIndex = null;
    private lastEndTimeIndex = null;

    public startTimePos = 0;
    public endTimePos = 0;

    val = 0;

    private reservationData$: Subscription;


    constructor(private appService: AppService) { }


    ngOnInit() {

        this.reservationData$ = this.appService.reservationData.subscribe(
            (reservation) => {
                if (!_.isNull(reservation) && !_.isNull(reservation.slot) && reservation.slot.date === this.selectedDate) {
                    console.log(`${this.TAG} Detected changes in reservation `, reservation);
                    if (!_.isNull(this.lastSelectedSlot)) {
                        const startTimeIndex = SlotsConfig.defaultSlots.findIndex((item) => item === reservation.slot.startTime);
                        const endTimeIndex = SlotsConfig.defaultSlots.findIndex((item) => item === reservation.slot.endTime);

                        if (this.lastStartTimeIndex !== 0 && startTimeIndex !== this.lastStartTimeIndex) {
                            const diff = this.lastStartTimeIndex - startTimeIndex;
                            if (diff > 0) {
                                this.startTimePos = diff * SlotsConfig.slotHeight;
                            } else {
                                this.startTimePos = 0;
                            }
                            this.lastStartTimeIndex = startTimeIndex;
                        }

                        if (this.lastEndTimeIndex !== 0 &&endTimeIndex !== this.lastEndTimeIndex) {
                            const diff = endTimeIndex - this.lastEndTimeIndex;

                            if (diff > 0) {
                                this.endTimePos = -(diff * SlotsConfig.slotHeight);
                            } else {
                                this.endTimePos = 0;
                            }
                            this.lastEndTimeIndex = endTimeIndex;
                        }

                    } else {
                        this.lastSelectedSlot = reservation.slot;
                        this.lastStartTimeIndex = SlotsConfig.defaultSlots.findIndex((item) => item === reservation.slot.mainSlotStart);
                        this.lastEndTimeIndex = SlotsConfig.defaultSlots.findIndex((item) => item === reservation.slot.mainSlotEnd);

                        const startTimeIndex = SlotsConfig.defaultSlots.findIndex((item) => item === reservation.slot.startTime);
                        const endTimeIndex = SlotsConfig.defaultSlots.findIndex((item) => item === reservation.slot.endTime);

                        if (this.lastStartTimeIndex !== 0 && startTimeIndex !== this.lastStartTimeIndex) {
                            const diff = this.lastStartTimeIndex - startTimeIndex;
                            if (diff > 0) {
                                this.startTimePos = diff * SlotsConfig.slotHeight;
                            } else {
                                this.startTimePos = 0;
                            }
                            this.lastStartTimeIndex = startTimeIndex;
                        }

                        if (this.lastEndTimeIndex !== 0 &&endTimeIndex !== this.lastEndTimeIndex) {
                            const diff = endTimeIndex - this.lastEndTimeIndex;

                            if (diff > 0) {
                                this.endTimePos = -(diff * SlotsConfig.slotHeight);
                            } else {
                                this.endTimePos = 0;
                            }
                            this.lastEndTimeIndex = endTimeIndex;
                        }
                    }


                } else {
                    this.lastSelectedSlot = null;
                    this.lastStartTimeIndex = null;
                    this.lastEndTimeIndex = null;
                    this.startTimePos = 0;
                    this.endTimePos = 0;
                }

            },
            (err) => {
                console.log(`${this.TAG}`, err);
            }
        );

    }

    get maskPositionCalc(): string {
        return `0 ${this.maskPosition + 2}px`;

    }

    get isExtendSlotMode() {
        return !_.isUndefined(this.extendSlot) && this.extendSlot !== -1;
    }

    ngOnDestroy() {
        console.log(`${this.TAG} ngOnDestroy`);
        if (this.reservationData$) {
            this.reservationData$.unsubscribe();
        }
    }

}
