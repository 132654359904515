import { Directive, HostListener, ContentChildren, ElementRef, QueryList, AfterContentInit } from '@angular/core';
import { StepPickerUIComponent } from '../components/picker-ui/step/step.component';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { SlotSelectionService } from '../services/slot-selection.service';
import { ReservationsWizardService } from '../../shared/services/reservation-wizard.service';
import { ReservationInterface } from '../../shared/interfaces/reservation-interface';
import { WidgetMode } from '../../shared/enums/widget-mode.enum';

@Directive({
  selector: '[appSliderListener]'
})
export class SliderListenerDirective implements AfterContentInit {

  private sliderTopPosition = new Subject<number>();
  reservation: ReservationInterface;

  @ContentChildren(StepPickerUIComponent, { read: ElementRef }) contentChildren: QueryList<StepPickerUIComponent>;

  private steps: ElementRef<StepPickerUIComponent>[];

  constructor(
    private readonly slotSelectionService: SlotSelectionService,
    private readonly wizardService: ReservationsWizardService
  ) { }

  ngAfterContentInit(): void {
    this.wizardService.getModel().subscribe((reservation: ReservationInterface) => {
      this.reservation = reservation;
    });
  }



  @HostListener('mousemove', [])
  onMousemove() {
    if (this.reservation.mode === WidgetMode.ECO_SLOTS) {
      this.slotSelectionService.sliderPositionTop$.next(this.reservation.sliderCurrentPosition + 30);
    }
 }
}


